@charset "UTF-8";
/*
Seed.css cortesía de:
███    ██  ██████  ██████  ███████ ███████ ██    ██ ███████ ████████ ███████ ███    ███ ███████    ██    ██ ██    ██
████   ██ ██    ██ ██   ██ ██      ██       ██  ██  ██         ██    ██      ████  ████ ██         ██    ██  ██  ██
██ ██  ██ ██    ██ ██   ██ █████   ███████   ████   ███████    ██    █████   ██ ████ ██ ███████    ██    ██   ████
██  ██ ██ ██    ██ ██   ██ ██           ██    ██         ██    ██    ██      ██  ██  ██      ██    ██    ██    ██
██   ████  ██████  ██████  ███████ ███████    ██    ███████    ██    ███████ ██      ██ ███████ ██  ██████     ██
                                        Node Seed > Framework by NODESYSTEMS.UY - Actualizado 07 marzo 2023 para: Origen Studios.
*/

/* Experimental NODESYSTEMS 2023 */


/* Mobile menu */
:root {
  --current-color-emphasis: #ffc666;
}


.p-e-n{
  pointer-events: none;
}



.floating-effect {
 animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


.ns-b-c-e-h{
  border-color: #202020;
  transition: 0.3s ease-in-out;

}
.ns-b-c-e-h:hover{
  border-color: #ffc666;
}
.d-n-on-h{
  display: visible;
  transition: 0.3s ease-in-out;
}
.polka {
  background-image: 
    radial-gradient(rgb(36, 36, 36) 10%, transparent 10%);
  background-position: 8px 8px;
  background-size: 16px 16px;
  background-color: rgb(0, 0, 0);
}
.swiper-slide.d-n{
  display: none;
}
.tag {
  width: auto;
  height: auto;
  position: relative;
  margin: auto;
  float: left;
  border-radius: 3px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px;
  line-height: 15px;
  font-size: 15px;
  font-weight: bold;
  font-family: Gilroy;
  background: black;
  border: 1px solid rgba(100,100,100, 0.2);
}
.tag:hover{
  background: var(--current-color-emphasis);
}
.hide-checkbox {
  
  opacity: 0;
  pointer-events: none;
}

.box-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
  gap: 10px;

}
.box {
  display: flex;
  width: 200px; /* Ajusta el ancho según tus necesidades */
  height: 200px; /* Ajusta la altura según tus necesidades */
  margin: auto; /* Ajusta los márgenes según tus necesidades */
 
}



.box-inside{
  width: 200px; /* Ajusta el ancho según tus necesidades */
  height: 200px; /* Ajusta la altura según tus necesidades */
  margin: auto; /* Ajusta los márgenes según tus necesidades */

}
.box-image {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  position: relative;
  object-fit: contain;
  transition: 0.3s;
  transform: scale(0.8);
}
.box-image:hover 
{
  transform: scale(1);
  object-fit: contain;
}

.nbsc{
  height: calc((100% - 70px) / 2) ;
  box-sizing: border-box;
}
.p-a {
  position: absolute;
}
.p-r {
  position: relative;
}
.ns-fill-e {
  fill: var(--current-color-emphasis);
  color: var(--current-color-emphasis);
}

.d-n-on-h{
  display: visible;
}



/* RADIO PLAYER */
.radio-player-container {
  text-align: center;
}

.radio-player-title {
  font-size: 24px;
  margin-bottom: 16px;
}

.radio-player-video {
  max-width: 640px;
  margin: 0 auto;
}

.radio-player-controls {
  margin-top: 16px;
}

.radio-player-controls button {
  margin-right: 8px;
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.radio-player-controls button:hover {
  background-color: #0056b3;
}

.radio-player-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
/* RADIO PLAYER FINISHED */





/* IMAGE COMPONENT CSS init*/
/* Modal Desktop */

/* Estilos para el componente de imagen */
.img-component {
  display: inline-block;
  position: relative;
}

.img-component img {
  cursor: pointer;
  max-width: 100%;
  height: inherit;
}



/* Estilos para la modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  
 
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  width: 100vw;
  height: 100vh;
  z-index: 3;

  overflow: hidden;
  
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;

}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}
*:hover .modal-close-button{
  opacity: 1;

 }
.modal-close-button {
  position: fixed;
  opacity: 0;
  width: 50px;
  height:50px;
  top: 50px;
  right: 50px;
  margin: auto;
  padding: 10px 15px;
  background-color: #202020;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: Gilroy;
  font-weight: bold;
  border: none;
  cursor: pointer;
  z-index: 2;
  transition: 0.5s ease-in-out;
}

 /* Zoom controls*/
 *:hover .ZoomControls{
  opacity: 1;
 }
 .ZoomControls{
  position: fixed;
  opacity: 0;
  height:70px;
  bottom: 50px;
  right: 0px;
  left: 0px;
  margin: auto;
  padding: 5px;
  background-color: #202020;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: Gilroy;
  font-weight: bold;
  border: none;
  cursor: pointer;
  z-index: 2;
  transition: 0.5s ease-in-out;
 }
 .ZoomControls button{
  width:  auto;
  min-width: 50px;
  height:50px;
  top: 50px;
  right: 50px;
  margin: auto;
  padding: 10px 15px;
  background-color: #202020;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: Gilroy;
  font-weight: bold;
  border: none;
  cursor: pointer;
  z-index: 2;
 }


/* IMAGE COMPONENT CSS finish */

@media only screen and (max-width: 768px) {
 .m-p-f{
    position: fixed !important;
  }
  .RegHome{
    top: -20px !important; 
    font-size: 25px !important; 
  }
  .SlogHome{
    font-size: 20px !important;
  }
  
 
  .box {
    width: 160px; /* Ajusta el ancho según tus necesidades */
    height: 160px; /* Ajusta la altura según tus necesidades */
    margin: 15px;
   
   
  }
  .box-inside{
    width: 160px; /* Ajusta el ancho según tus necesidades */
    height: 160px; /* Ajusta la altura según tus necesidades */
    margin: auto; /* Ajusta los márgenes según tus necesidades */
 
  }
 }

 .bg-d-t { 
  background-image: linear-gradient(315deg, hsl(0, 0%, 0%) 0, hsl(344, 0%, 1%) 13%, hsl(344, 0%, 2%) 25%, hsl(344, 0%, 3%) 36%, hsl(344, 0%, 4%) 46%, hsl(344, 0%, 5%) 56%, hsl(344, 0%, 6%) 66%, hsl(344, 0%, 7%) 76%, hsl(344, 0%, 7%) 88%, hsl(0, 0%, 8%) 100%); 
  border: 1px solid rgba(25, 25, 25, 0.2); 
} 
 
.bg-d { 
  background-image: linear-gradient(135deg, hsl(0, 0%, 0%) 0, hsl(344, 0%, 1%) 13%, hsl(344, 0%, 2%) 25%, hsl(344, 0%, 3%) 36%, hsl(344, 0%, 4%) 46%, hsl(344, 0%, 5%) 56%, hsl(344, 0%, 6%) 66%, hsl(344, 0%, 7%) 76%, hsl(344, 0%, 7%) 88%, hsl(0, 0%, 8%) 100%); 
  border: 1px solid #141414; 
} 

.bg-g-green {background: linear-gradient(to right, rgb(21, 153, 89), rgb(13, 139, 150));}
.bg-g-orange{ background-image: linear-gradient(120deg, #ffcb56 0%, #ff6b42 100%);}
.bg-g-purple{background: linear-gradient(91.1deg, rgb(77, 39, 147) -2.3%, rgb(115, 43, 155) 44.4%, rgb(133, 26, 199) 103.4%);}
.bg-g-blue{background: radial-gradient(circle at 10% 20%, rgb(26, 89, 152) 0%, rgb(6, 63, 120) 90%);}
.bg-g-yellow{background: radial-gradient(circle at 10% 20%, rgb(252, 217, 59) 0%, rgb(255, 178, 25) 90%);}
.bg-g-pink{background: linear-gradient(143.3deg, rgb(200, 40, 149) 33.1%, rgb(237, 107, 154) 74.9%);}


.bg-g-identity {  background: linear-gradient(to right, rgb(10, 164, 133), rgb(7, 77, 68));}
.bg-g-contents{ background: linear-gradient(to right, rgb(42, 67, 113), rgb(6, 30, 61));}
.bg-g-asesoria{ background: linear-gradient(to right, rgb(185, 52, 152), rgb(99, 15, 54));}

.t-5 {
  margin-top: 5vh;
}
.t-10 {
  margin-top: 10vh;
}

div .d-n-on-h-container:hover .d-n-on-h {
  display: none;
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.d-v-on-h{
  display: none;
  transition: 0.5s ease-in-out ;
  animation: fadeIn;
}



div.d-v-on-h-container:hover .d-v-on-h:hover{
  display: block ;
  -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s; 

}
.op-v-on-h-container:hover .op-v-on-h{
  opacity: 1;
}

.op-v-on-h{
 opacity: 0;
 transition: 0.3s ease-in-out ;
}

.op-v-on-h:hover{
  opacity: 1;
 
}

.op-v-on-h-container:hover .op-10-on-h{
  opacity: 1;
  filter: grayscale( 0);
}
.op-v-on-h-container:hover .op-30-on-h{
  opacity: 1;
  filter: grayscale( 0);
}
.op-10-on-h{
 opacity: .1;
 transition: 0.3s ease-in-out ;
 filter: grayscale( 100);
}

.op-30-on-h{
  opacity: .3;
  transition: 0.3s ease-in-out ;
  filter: grayscale( 100);
 }


.op-v-on-h:hover{
  opacity: 1;
}


.d-v-on-h:hover {
  display: block ;
  
}

/* MobileMenu Btn Center */
.menu-wrapper {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1341;
  top: -18px;
  transition: top 0.4s ease-in-out 0.2s;
}

.menu-open {
  top: -28px;
  transition: top 0.4s ease-in-out;
}

.menu-wrapper label:active img {
  fill: #00ACEE !important; /* Cambia el color aquí */
}

.menu-open img[src*="MobileBtnMenu"] {
  fill: #cccccc !important; /* Cambia el color aquí */
}
/* Mobile Menu Btn Right*/
.MobileMenuBtnRight {
  clip-path: polygon(0 35px, 35px 0,100% 0,100% 100%,0 100%)
}

/* Mobile Menu Btn Left*/
.MobileMenuBtnLeft {
  clip-path: polygon(0 0,calc(100% - 35px) 0,100% 35px,100% 100%,0 100%);
}




u.UnderLine {
text-decoration-color:  #FFC666 !important;

}
.UnderLineText {
    display: inline;
    box-shadow: inset 0 -0.2em 0 #FFC666 !important;
}

.menu-container{
  position: fixed !important;
  z-index: 99342234 !important;
 
 
}


.isometric{
  transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg);
}
.isometric.shadow{
  display: block;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: .9;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
}

.rotate-scale-up-vertical{animation:rotate-scale-up-vertical 4s  ease-in-out infinite} @keyframes rotate-scale-up-vertical{0%{transform:scale(1) rotateY(0)}50%{transform:scale(1.3) rotateY(180deg)}100%{transform:scale(1) rotateY(360deg)}}

.inactive *{
  filter: grayscale(100%) blur(2px) saturate(0%) opacity(70%) !important;
  -webkit-filter: grayscale(100%) blur(2px) saturate(0%) opacity(70%) !important;
  -moz-filter: grayscale(100%) blur(2px) saturate(0%) opacity(70%) !important;
  fill: #666666 !important;
  color: #666666 !important;
}

@keyframes coinanimationsponsor{
  0% {
    opacity: 0;

    transform: scale(0.3);
 }
 100% {
    opacity: 1;

    transform: scale(1);
 }
}
@-webkit-keyframes coinanimationsponsor {
  0% {
     opacity: 0;

     -webkit-transform: scale(0.3);
  }
  100% {
     opacity: 1;

     -webkit-transform: scale(1);
  }
}


.coin-sponsor-animation{
animation: coinanimationsponsor 2s ease-in-out 1s both ;
}
/* Unique for Origen Studios */
.animated-text {
  overflow: hidden;
  border-right: .15em solid orange;
  white-space: nowrap;
  animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}



.card-h-opt{
  display: none;
  position: absolute;
  float: left;
  margin: auto;
  width: 100%;
  height: auto;
  animation: ease-in-out;
  border-radius: 3px;
  padding: 5px;
 


}
.card:hover .card-h-opt{
  display: block;
  cursor: pointer;

}

.card.o-s-e:hover{
  transform: scale(1.2);
  z-index: 90;
}
.card-mosaic{
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
  grid-auto-rows: auto;
}


  .draw {
    stroke: red;
    stroke-width: 2px;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: draw 5s linear forwards;
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }

 

.card{
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: auto;
  width: 100%;
  overflow: hidden;
  transition: all 500ms;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.card.circle{
  border-radius: 50%;
}

/*tablet*/

@media(max-width: 1200px){
  .tab-f-s-normal{
    font-size: var(--current-font-normal);
  }
  .tab-f-s-medium{
    font-size: var(--current-font-medium);
  }
  .tab-d-n{
    display: none  !important;
  }
  .tab-d-b{
    display: block !important;
  }
  .tab-w-50{
    width: 50% !important;
  }
  .tab-w-60{
    width: 60% !important;
  }
  .tab-w-70{
    width: 70% !important;
  }
  .tab-w-80{
    width: 80% !important;
  }
  .tab-w-90{
    width: 90% !important;
  }
  .tab-w-100{
    width: 100% !important;
  }
  
  .tab-w-200px{
    width: 200px !important;
  }
  .tab-w-300px{
    width: 300px !important;
  }

  }




@media(min-width: 768px){
    .card-tall{
      grid-row: span 2 / auto;
    }
    .card-wide{
      grid-column: span 2 / auto;
    }
  
  }

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #ffffff;
  cursor: pointer;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #FFFFFF;
  transform-origin: bottom right;
  transition: transform 0.15s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.notifier{
  z-index: 99999999999;
  width: auto;
  height: auto;
  position: fixed;
  left: 0px;
  right: 0px;
  margin: auto;
  bottom: 30px;

  border-radius: 50px;
 

}

.divider{
width: 72%;
height: 1px;
background: white;
position: absolute;
left: 0px;
right: 0px;
bottom:0px;
margin: auto;
margin-left: 10px;
margin-right: 10px;
}

a.h-e:hover .h-e-sub-line{
  position: absolute;
left: 0px;
right: 0px;
bottom: 0px;
margin: auto;
 width: 100%;
 height: 1px;
 background: white;
 transition: 0.1s ease-in-out;

}
@keyframes blurredbg {

  0% { filter: blur(5px) brightness(50%);
    transform: scale(1);
  }
  50% { filter: blur(0px) brightness(100%);
    transform: scale(1.5);
  }
  100% { filter: blur(5px) brightness(50%);
    transform: scale(1);
  }
  
}
.blurbganimation {
  
  animation:  ease-in-out infinite;
  filter: blur(5px) brightness(70%);

  transform: scale(1);
  animation-name: blurredbg ;
  animation-duration: 40s;

}
.blur{
  filter: blur(10px);
}
.h-e:hover .h-e-style {

  text-decoration: none;
  color: #e1a54b;


}


.h-e:active .h-e-style{
    text-decoration: none;
    outline: none;
    color: #ff0096;

}

.bg-linear-b-t {
  background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,0) 100%);
}
.bg-linear-b-t-menu{
  background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%);
filter: blur(10px);
}

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.

 */
 body {
  height: 100vh;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

html{
  height: 100vh
}
/* Avoid Chrome to see Safari hack */

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {

  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

/* Global */
*, *::after, *::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*:focus {
  outline: none;
}

/* Body */
/*
body {
  color: #7e7e7e;
  -o-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  font-size: 16px;
}*/

/* Button */
button {
  color: none;
  outline: none;
  border: none;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: "'Permanent Marker', cursive";
}

button:active {
  color: none;
  outline: none;
  border: none;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

/* Ancle */
a {
  outline: none;
  text-decoration: none;
  color: inherit;
  fill: inherit;
  margin: inherit;
  position: inherit;
  height: inherit;
  width: inherit;
}

a:link {
  outline: none;
  text-decoration: none;
  color: white;
  fill: inherit;
  margin: inherit;
  position: inherit;
  height: inherit;
  width: inherit;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #e1a54b;
}

a:active {
  text-decoration: none;
  outline: none;
  color: #ff0096;
}

/* Headers */
h1, h2, h3, h4, h5, h6 {
  font-family: "'Permanent Marker', cursive";
}

h1 {
  font-size: 2em;
  line-height: 1em;
}

h2 {
  font-size: 1.5em;
  line-height: 1em;
}

h3 {
  font-size: 1.3em;
  line-height: 1em;
}

h4 {
  font-size: 1.2em;
  line-height: 1em;
}

h5 {
  font-size: 1em;
  line-height: 1em;
}

h6 {
  font-size: 0.8em;
  line-height: 1em;
}

/* Input */
input:focus {
  outline: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



/* Select */
select:focus {
  outline: none;
}

/* Textarea */
textarea:focus {
  outline: none;
}

/* Scrollbar */
div::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

div::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

div::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border: 0;
  border-radius: 100px;
}

div::-webkit-scrollbar-thumb:hover {
  background: #dedede;
}

div::-webkit-scrollbar-thumb:active {
  background: #e1a54b;
}

div::-webkit-scrollbar-track {
  background: #2020202d;
  border: 0;
  border-radius: 70px;
}

div::-webkit-scrollbar-track:hover {
  background: #2828282f;
}

div::-webkit-scrollbar-track:active {
  background: #2020201f;
}

div::-webkit-scrollbar-corner {
  background: transparent;
}

/* Responsive */
@media only screen and (max-height: 500px) {
  body {
    font-size: 10px;
  }
}

/* Components */
/* Components */
/* Atoms */
/* Select */
.select {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select select {
  width: 100%;
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px;
  outline: 0;
  border: 0 solid inherit;
  background: inherit;
  border-radius: 11px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select select::-ms-expand {
  display: none;
}

.select select:focus,
.select select:hover {
  color: inherit;
  background: inherit;
}

.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select_arrow {
  position: absolute;
  top: 8px;
  right: 15px;
  width: 0;
  height: 0;
  border: solid inherit;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.select select:hover ~ .select_arrow {
  border-color: inherit;
}

.select select:focus ~ .select_arrow {
  top: 11px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.select select:not(:focus) ~ .select_arrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

.marquee {
  width: 100%;
  line-height: 1em;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -o-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  -webkit-animation: marquee 15s linear infinite;
          animation: marquee 15s linear infinite;
  -o-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
}

.Btn {
  width: auto;
  height: auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: auto;
  background: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-origin: inherit;
  background-image: inherit;
  font-family: var(--current-font-family-1), "Font Awesome 5 Free";
  color: auto;
  border-radius: var(--current-border-radius-1);
  -webkit-border-radius: var(--current-border-radius-1);
  -moz-border-radius: var(--current-border-radius-1);
  -ms-border-radius: var(--current-border-radius-1);
  -o-border-radius: var(--current-border-radius-1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  font-size: inherit;
}

.Btn.w-and-h-100 {
  width: 100%;
  height: 100%;
}

.Btn.h-100 {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
}

.Btn.w-100 {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
}

.Btn.w-100 i.BtnIconLeft {
  margin-left: 0px;
  text-align: center;
}

.Btn.w-100 i.BtnIconRight {
  margin-right: 0px;
}

.Btn.nano {
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: var(--current-font-nano);
}

.Btn.small {
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: var(--current-font-small);
}

.Btn.normal {
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: var(--current-font-normal);
}

.Btn.medium {
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: var(--current-font-medium);
}

.Btn.big {
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: var(--current-font-big);
}

.Btn.giant {
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: var(--current-font-giant);
}

.BtnIcon {
  width: auto;
  margin: auto;
  color: inherit;
  vertical-align: middle;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: inherit;
}

.BtnIconLeft {
  width: auto;
  height: auto;
  margin: auto;
  margin-right: 5px;
  color: inherit;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  vertical-align: middle;
}

.BtnIconLeft.fa-spin {
  margin-right: 5px;
  padding-right: 0px;
  padding-left: 0px;
}

.BtnIconRight {
  width: auto;
  margin: auto;
  margin-left: 5px;
  color: inherit;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.BtnIconRight.fa-spin {
  margin-left: 5px;
  padding-left: 0px;
  padding-right: 0px;
}

.BtnText {
  font-weight: bold;
  font-family: inherit;
  color: inherit;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: inherit;
}

/* CONTROLES */
.Btn.close::before {
  content: "\f00d";
  background-color: var(--current-color-red);
  color: white;
  font-size: inherit;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* Solid 900 - Regular 400 */
  padding: var(--current-padding-1);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: var(--current-border-radius-1);
  -webkit-border-radius: var(--current-border-radius-1);
  -moz-border-radius: var(--current-border-radius-1);
  -ms-border-radius: var(--current-border-radius-1);
  -o-border-radius: var(--current-border-radius-1);
}

.Btn.minimize::before {
  content: "\f2d1";
  background-color: var(--current-color-2);
  color: var(--current-color-5);
  font-size: inherit;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* Solid 900 - Regular 400 */
  padding: var(--current-padding-1);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: var(--current-border-radius-1);
  -webkit-border-radius: var(--current-border-radius-1);
  -moz-border-radius: var(--current-border-radius-1);
  -ms-border-radius: var(--current-border-radius-1);
  -o-border-radius: var(--current-border-radius-1);
}

.Btn.maximize::before {
  content: "\f2d0";
  background-color: var(--current-color-2);
  color: var(--current-color-5);
  font-size: inherit;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  /* Solid 900 - Regular 400 */
  padding-left: 5px;
  padding-right: 5px;
  padding: var(--current-padding-1);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: var(--current-border-radius-1);
  -webkit-border-radius: var(--current-border-radius-1);
  -moz-border-radius: var(--current-border-radius-1);
  -ms-border-radius: var(--current-border-radius-1);
  -o-border-radius: var(--current-border-radius-1);
}

/* CONTROLES MULTIMEDIA */
.Btn.play::before {
  content: "\f04b";
  background-color: var(--current-color-2);
  color: var(--current-color-5);
  font-size: inherit;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* Solid 900 - Regular 400 */
  padding: var(--current-padding-1);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: var(--current-border-radius-1);
  -webkit-border-radius: var(--current-border-radius-1);
  -moz-border-radius: var(--current-border-radius-1);
  -ms-border-radius: var(--current-border-radius-1);
  -o-border-radius: var(--current-border-radius-1);
}

.Btn.pause::before {
  content: "\f04c";
  background-color: var(--current-color-2);
  color: var(--current-color-5);
  font-size: inherit;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* Solid 900 - Regular 400 */
  padding: var(--current-padding-1);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: var(--current-border-radius-1);
  -webkit-border-radius: var(--current-border-radius-1);
  -moz-border-radius: var(--current-border-radius-1);
  -ms-border-radius: var(--current-border-radius-1);
  -o-border-radius: var(--current-border-radius-1);
}

.Btn.stop::before {
  content: "\f04d";
  background-color: var(--current-color-2);
  color: var(--current-color-5);
  font-size: inherit;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* Solid 900 - Regular 400 */
  padding: var(--current-padding-1);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: var(--current-border-radius-1);
  -webkit-border-radius: var(--current-border-radius-1);
  -moz-border-radius: var(--current-border-radius-1);
  -ms-border-radius: var(--current-border-radius-1);
  -o-border-radius: var(--current-border-radius-1);
}

.Btn.volume::before {
  content: "\f027";
  background-color: var(--current-color-2);
  color: var(--current-color-5);
  font-size: inherit;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* Solid 900 - Regular 400 */
  padding: var(--current-padding-1);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: var(--current-border-radius-1);
  -webkit-border-radius: var(--current-border-radius-1);
  -moz-border-radius: var(--current-border-radius-1);
  -ms-border-radius: var(--current-border-radius-1);
  -o-border-radius: var(--current-border-radius-1);
}

.Btn:active {
  filter: brightness(1.5);
  -webkit-filter: brightness(1.5);
  -webkit-transform-origin: center;
          transform-origin: center;
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
  -moz-transform: scale(0.98);
  -ms-transform: scale(0.98);
  -o-transform: scale(0.98);
}

.checkbox {
  font-family: arial;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  float: left;
  margin: auto;
  cursor: pointer;
  font-size: inherit;
  line-height: 1.6em;
  background: none;
}

.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* SIZES */
.checkbox.nano {
  padding: 5px;
  font-size: calc(var(--current-font-nano) + 0.3em);
}

.checkbox.small {
  padding: 3px;
  font-size: calc(var(--current-font-small) - 0.08em);
}

.checkbox.normal {
  padding: 3px;
  font-size: calc(var(--current-font-normal) - 0.2em);
}

.checkbox.medium {
  padding: 3px;
  font-size: calc(var(--current-font-medium) - 0.8em);
}

.checkbox.big {
  padding: 3px;
  font-size: calc(var(--current-font-big) - 1.5em);
}

.checkbox.giant {
  padding: 5px;
  font-size: calc(var(--current-font-giant) - 2.5em);
}

.checkbox-text {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: auto;
}

.checkbox_indicator {
  position: relative;
  float: left;
  margin: auto;
  height: 10px;
  width: 10px;
  border: 0px solid #000000;
  border-radius: var(--current-border-radius);
  -webkit-border-radius: var(--current-border-radius);
  -moz-border-radius: var(--current-border-radius);
  -ms-border-radius: var(--current-border-radius);
  -o-border-radius: var(--current-border-radius);
}

/* SIZES ADJUSTS */
.checkbox_indicator.nano {
  height: 10px;
  width: 10px;
}

.checkbox_indicator.small {
  height: 13px;
  width: 13px;
}

.checkbox_indicator.normal {
  height: 15px;
  width: 15px;
}

.checkbox_indicator.medium {
  height: 18px;
  width: 18px;
}

.checkbox_indicator.big {
  height: 22px;
  width: 22px;
}

.checkbox_indicator.giant {
  height: 25px;
  width: 25px;
}

/*      ----------------------       */
.checkbox:hover input ~ .checkbox_indicator,
.checkbox input:focus ~ .checkbox_indicator {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.checkbox input:checked ~ .checkbox_indicator {
  background: inherit;
}

.checkbox:hover input:not([disabled]):checked ~ .checkbox_indicator,
.checkbox input:checked:focus ~ .checkbox_indicator {
  background: inherit;
}

.checkbox input:disabled ~ .checkbox_indicator {
  background: var(--current-color-emphasis);
  opacity: 0.6;
  pointer-events: none;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.checkbox_indicator:after {
  -webkit-box-sizing: unset;
          box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkbox_indicator:after {
  display: block;
}

.checkbox-checkbox .checkbox_indicator:after {
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

/* FOR NORMAL DEFAULT CHECK */
.checkbox-checkbox .checkbox_indicator.default:after {
  border-color: #7e7e7e;
}

.checkbox-checkbox .checkbox_indicator.white:after {
  border-color: #c2c2c2;
}

.checkbox-checkbox .checkbox_indicator.black:after {
  border-color: #303030;
}

/* FOR GHOST CHECKS */
.checkbox-checkbox .checkbox_indicator.default.ghost:after {
  border-color: #7e7e7e;
}

.checkbox-checkbox .checkbox_indicator.alert.ghost:after {
  border-color: var(--current-color-red);
}

.checkbox-checkbox .checkbox_indicator.caution.ghost:after {
  border-color: var(--current-color-yellow);
}

.checkbox-checkbox .checkbox_indicator.info.ghost:after {
  border-color: var(--current-color-skyblue);
}

.checkbox-checkbox .checkbox_indicator.success.ghost:after {
  border-color: var(--current-color-green);
}

.checkbox-checkbox .checkbox_indicator.black.ghost:after {
  border-color: var(--current-color-black);
}

.checkbox-checkbox .checkbox_indicator.white.ghost:after {
  border-color: var(--current-color-white);
}

/*  ------ */
.checkbox-checkbox .checkbox_indicator.alert:after {
  border-color: white;
}

.checkbox-checkbox .checkbox_indicator.nano:after {
  left: 3px;
  top: 0px;
  width: 2px;
  height: 5px;
}

.checkbox-checkbox .checkbox_indicator.small:after {
  left: 4px;
  top: 1px;
  width: 3px;
  height: 7px;
}

.checkbox-checkbox .checkbox_indicator.normal:after {
  left: 5px;
  top: 1px;
  width: 3px;
  height: 8px;
}

.checkbox-checkbox .checkbox_indicator.medium:after {
  left: 6px;
  top: 2px;
  width: 3px;
  height: 8px;
}

.checkbox-checkbox .checkbox_indicator.big:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border-width: 0 3px 3px 0;
}

.checkbox-checkbox .checkbox_indicator.giant:after {
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border-width: 0 3px 3px 0;
}

.checkbox-checkbox input:disabled ~ .checkbox_indicator:after {
  border-color: #7e7e7e;
}

.checkbox-checkbox .checkbox_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 4.536rem;
  height: 4.401rem;
  margin-left: -2em;
  margin-top: -2em;
  background: var(--current-color-emphasis);
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  -webkit-transform: scale(0);
          transform: scale(0);
}

/*
@keyframes s-ripple {
  0% {
      transform: scale(0);
  }
  20% {
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
}
} */
/*
@keyframes s-ripple-dup {
 0% {
     transform: scale(0);
  }
 30% {
      transform: scale(1);
  }
  60% {
      transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -ms-transform: scale(1.5);
      -o-transform: scale(1.5);
}
  100% {
      opacity: 0;
      transform: scale(2);
      -webkit-transform: scale(2);
      -moz-transform: scale(2);
      -ms-transform: scale(2);
      -o-transform: scale(2);
}
}*/
.checkbox-checkbox input + .checkbox_indicator::before {
  animation: s-ripple 300ms ease-out;
  -webkit-animation: s-ripple 300ms ease-out;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.checkbox-checkbox input:checked + .checkbox_indicator::before {
  -webkit-animation-name: s-ripple-dup;
          animation-name: s-ripple-dup;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.contact-btn {
  width: inherit;
  height: inherit;
  margin: 0;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  background: auto;
}

.contact-btn:hover .sidebar-text {
  margin-left: 5px;
}

.contact-btn:active {
  filter: brightness(1.5);
  -webkit-filter: brightness(1.5);
  -webkit-transform-origin: center;
          transform-origin: center;
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
  -moz-transform: scale(0.98);
  -ms-transform: scale(0.98);
  -o-transform: scale(0.98);
}

.DropdownChevron::before {
  content: '\f0dd';
  color: inherit;
  font-size: 13px;
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
}

.DropdownChevron:active {
  -webkit-transform-origin: center;
          transform-origin: center;
  animation: dropdownanim 0.3s forwards;
  -webkit-animation: dropdownanim 0.3s forwards;
}

@-webkit-keyframes dropdownanim {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
}

@keyframes dropdownanim {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
}

img.img-b-none{
  border: 0 !important;
  border-style: none !important;
  outline: none !important;

}




.image img {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  outline: none;
}

.image:empty::before {
  display: visible;
}

/*
.image:empty::before{
    width: 50px !important;
    height: 50px !important;
    background: red !important;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: '\f2bd' !important;
    font-size: calc(4vw - 0.5vh)  !important;
    text-align: center;
    position: relative;
    margin: auto;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}*/
.img-contain {
  background-size: contain;
  -o-object-fit: contain;
     object-fit: contain;
     outline: none;
     border: none;
}
.img-cover {
  background-size: cover;
  -o-object-fit: cover;
     object-fit: cover;
     outline: none;
     border: none;
}

/* VALORES SQUARE */
.image .square-16x16 {
  height: 1.000em;
  width: 1.000em;
}

.image .square-24x24 {
  height: 1.500em;
  width: 1.500em;
}

.image .square-32x32 {
  height: 2.000em;
  width: 2.000em;
}

.image .square-64x64 {
  height: 4.000em;
  width: 4.000em;
}

.image .square-96x96 {
  height: 6.000em;
  width: 6.000em;
}

.image .square-128x128 {
  height: 8.000em;
  width: 8.000em;
}

.image .square-256x256 {
  height: 16.000em;
  width: 16.000em;
}

.image .square-480x480 {
  height: 30.000em;
  width: 30.000em;
}

.image .square-500x500 {
  height: 31.250em;
  width: 31.250em;
}

/* ----------------------- */
.image .square-500x500 {
  height: 31.250em;
  width: 31.250em;
}

.InputContainer.left {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.InputContainer.right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.InputContainer.center {
  border-radius: 0px;
}

input:focus {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.InputContainer.nano {
  font-size: var(--current-font-nano);
}

.InputContainer.small {
  font-size: var(--current-font-small);
}

.InputContainer.normal {
  font-size: var(--current-font-normal);
}

.InputContainer.medium {
  font-size: var(--current-font-medium);
}

.InputContainer.big {
  font-size: var(--current-font-big);
}

.InputContainer.giant {
  font-size: var(--current-font-giant);
}

.InputContainer {
  height: auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  color: auto;
}


select {
  width:100%;
  height: 45px;
  border-radius: 10px;
  background: #121212;
  color: #dedede;
  padding-left: 10px;
  padding-right: 10px;
  bordeR: none; 
  font-family: Gilroy;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: hidden;
}
textarea {
  width:100%;
  height: 150px;
  border-radius: 10px;
  background: #121212;
  color: #dedede;
  padding: 10px;
  bordeR: none; 
  font-family: Gilroy;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: hidden;
}

.Input {
  width:100%;
  height: 45px;
  border-radius: 10px;
  background: #121212;
  color: #dedede;
  padding-left: 10px;
  padding-right: 10px;
  bordeR: none; 
  font-family: Gilroy;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: hidden;
}

.Input input {
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  outline: 0;
  border: none;
  color: auto;
  font-family: var(--current-font-family-1);
  padding: var(--current-padding-1);
  overflow: hidden;
}

.Input input::-webkit-input-placeholder {
  color: auto;
  opacity: 0.5;
}

.Input input:-ms-input-placeholder {
  color: auto;
  opacity: 0.5;
}

.Input input::-ms-input-placeholder {
  color: auto;
  opacity: 0.5;
}

.Input input::placeholder {
  color: auto;
  opacity: 0.5;
}

/*
  label[type="email"] .email .InputIconLeft i {
    content: "\f885" !important;

  }
  label[type="email"] .email .InputIconRight i {
  content: "\f885" !important;

  } */
input.success {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

input.info {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

input.default {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

input.alert {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

input.caution {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

input.black {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

input.white {
  filter: brightness(-0.8);
  -webkit-filter: brightness(-0.8);
}

input[type="date"] {
  position: relative;
  font-size: auto;
  color: auto;
  background: inherit;
  line-height: inherit;
  padding: auto;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="date"]:before {
  display: block;
  font-family: 'Font Awesome 5 Free';
  content: '\f073';
  width: 25px;
  height: auto;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 5px;
  padding: auto;
  margin: auto;
  line-height: inherit;
}

.Inp.nano input[type="date"] {
  height: 23px;
}

.Inp.small input[type="date"] {
  height: 26px;
}

.Inp.normal input[type="date"] {
  height: 28px;
}

.Inp.big input[type="date"] {
  height: 53px;
}

.Inp.giant input[type="date"] {
  height: 75px;
}

label.InputIconLeft {
  background: inherit;
  /* * BORRAR */
}

.InputIconLeft {
  background: inherit;
  /* * BORRAR */
  max-width: 50px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: inherit;
  height: 100%;
}

.InputIconLeft.nano {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 25px;
  max-width: 25px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

.InputIconLeft.small {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 33px;
  max-width: 35px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

.InputIconLeft.normal {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 40px;
  max-width: 40px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

.InputIconLeft.medium {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 45px;
  max-width: 45px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

.InputIconLeft.big {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 50px;
  max-width: 50px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

.InputIconLeft.giant {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 60px;
  max-width: 80px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

/*  --------------  */
.InputIconRight {
  width: auto;
  max-width: 50px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: inherit;
  height: 100%;
  overflow: hidden;
}

.InputIconRight.nano {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 25px;
  max-width: 25px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

.InputIconRight.small {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 33px;
  max-width: 35px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

.InputIconRight.normal {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 40px;
  max-width: 40px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

.InputIconRight.medium {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 45px;
  max-width: 45px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

.InputIconRight.big {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 50px;
  max-width: 50px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

.InputIconRight.giant {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 60px;
  max-width: 80px;
  font-size: inherit;
  text-align: center;
  overflow: hidden;
}

/* RANGE */
input[type=range].info {
  width: 100%;
  margin: 6px 0;
  background-color: transparent;
  -webkit-appearance: none;
}

input[type=range].info:focus {
  outline: none;
}

input[type=range].info::-webkit-slider-runnable-track {
  background: #0096FF;
  border: 0;
  border-radius: 25px;
  width: 100%;
  height: 4px;
  cursor: pointer;
}

input[type=range].info::-webkit-slider-thumb {
  margin-top: -6px;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type=range].info:focus::-webkit-slider-runnable-track {
  background: #0096FF;
}

input[type=range].info::-moz-range-track {
  background: #0096FF;
  border: 0;
  border-radius: 25px;
  width: 100%;
  height: 4px;
  cursor: pointer;
}

input[type=range].info::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
}

input[type=range].info::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 6.1px 0;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
}

input[type=range].info::-ms-fill-lower {
  background: #0096FF;
  border: 0;
  border-radius: 50px;
}

input[type=range].info::-ms-fill-upper {
  background: #0096FF;
  border: 0;
  border-radius: 50px;
}

input[type=range].info::-ms-thumb {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 0px;
}

input[type=range].info:focus::-ms-fill-lower {
  background: #0096FF;
}

input[type=range].info:focus::-ms-fill-upper {
  background: #0096FF;
}

@supports (-ms-ime-align: auto) {
  input[type=range].info {
    margin: 0;
  }
}

/*----------------------------------------------------*/
.Pag {
  min-width: 30px;
  text-align: center;
  background: inherit;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  overflow-wrap: break-word;
  margin-left: 1px;
  margin-right: 1px;
}

.Pag i {
  line-height: inherit;
  text-align: center;
  background: inherit;
  color: inherit;
  font-size: inherit;
}

.ProgBar .ProgLoad {
  width: 75%;
  background: auto;
  animation: progLoad 4s infinite ease-out;
  -webkit-animation: progLoad 4s infinite ease-out;
}

@-webkit-keyframes progLoad {
  0% {
    background: #ed293e;
    width: 0%;
  }
  25% {
    background: #ff9500;;
    width: 25%;
  }
  50% {
    background: #ffcc00;
    width: 50%;
  }
  100% {
    background: #02e473;
    width: 100%;
  }
}

@keyframes progLoad {
  0% {
    background: #ed293e;
    width: 0%;
  }
  25% {
    background: #ff9500;;
    width: 25%;
  }
  50% {
    background: #ffcc00;
    width: 50%;
  }
  100% {
    background: #02e473;
    width: 100%;
  }
}

.Sct.nano {
  height: 23px;
}

.Sct.small {
  height: 26px;
}

.Sct.normal {
  height: 31px;
}

.Sct.big {
  height: 53px;
}

.Sct.giant {
  height: 75px;
}

.Input select {
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  outline: 0;
  border: none;
  color: auto;
  font-family: var(--current-font-family-1);
  padding: var(--current-padding-1);
  overflow: hidden;
  outline: none;
  cursor: pointer;
}

.sidebar-btn {
  height: inherit;
  margin: 0;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  background: auto;
}

.sidebar-btn:hover .sidebar-text {
  margin-left: 5px;
}

.sidebar-btn:active {
  filter: brightness(1.5);
  -webkit-filter: brightness(1.5);
  -webkit-transform-origin: center;
          transform-origin: center;
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
  -moz-transform: scale(0.98);
  -ms-transform: scale(0.98);
  -o-transform: scale(0.98);
}

.Text {
  word-break: break-all;
  line-height: 1em;
  color: auto;
}

.t-r-o-l {
  text-rendering: optimizeLegibility;
}

.t-r-o-s {
  text-rendering: optimizeSpeed;
}

.t-r-g-p {
  text-rendering: geometricPrecision;
}

/* Molecules */
.NotifyBox {
  position: absolute;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.NotifyBox.left {
  position: absolute;
  margin: auto;
  left: 0;
}

.NotifyBox.right {
  position: absolute;
  margin: auto;
  right: 0;
}

.NotifyBox.top {
  position: absolute;
  margin: auto;
  top: 0;
}

.NotifyBox.bottom {
  position: absolute;
  margin: auto;
  bottom: 0;
}

/* DATALIST */
.datalist input {
  width: 100%;
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px;
  outline: 0;
  border: 0 solid #000000;
  border-radius: 11px;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.datalist datalist::-ms-expand {
  display: none;
}

.datalist datalist:focus,
.datalist datalist:hover {
  color: #000000;
  background: #cccccc;
}

.datalist datalist:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.datalist_arrow {
  position: absolute;
  top: 8px;
  right: 15px;
  width: 0;
  height: 0;
  border: solid #7b7b7b;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.datalist datalist:hover ~ .datalist_arrow {
  border-color: inherit;
}

.datalist datalist:focus ~ .datalist_arrow {
  top: 11px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.datalist datalist:not(:focus) ~ .datalist_arrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.datalist datalist:disabled ~ .datalist_arrow {
  border-top-color: #cccccc;
}

/* DATALIST */
.datalist input {
  width: 100%;
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px;
  outline: 0;
  border: 0 solid #000000;
  border-radius: 11px;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.datalist datalist::-ms-expand {
  display: none;
}

.datalist datalist:focus,
.datalist datalist:hover {
  color: #000000;
  background: #cccccc;
}

.datalist datalist:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.datalist_arrow {
  position: absolute;
  top: 8px;
  right: 15px;
  width: 0;
  height: 0;
  border: solid #7b7b7b;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.datalist datalist:hover ~ .datalist_arrow {
  border-color: inherit;
}

.datalist datalist:focus ~ .datalist_arrow {
  top: 11px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.datalist datalist:not(:focus) ~ .datalist_arrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.datalist datalist:disabled ~ .datalist_arrow {
  border-top-color: #cccccc;
}



.MenuBtnBackground {
  content: "";
  width: 350px;
  height: 250px;
  z-index: 9999;
  position: fixed;
  bottom: 0px;
  right: 0px;
  display: fixed;
  margin: auto;
  text-align: center;
  background: #020024;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.7) 0%, rgba(255, 0, 159, 0) 60%);
}

.MenuBtnMobile {
  width: 60px;
  height: 60px;
  z-index: 9999;
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: fixed;
  margin: auto;
  text-align: center;
}

.MenuBtnOptionList {
  width: 200px;
  height: auto;
  z-index: 9999;
  position: fixed;
  bottom: 15px;
  right: 120px;
  display: fixed;
  margin: auto;
  text-align: center;
}

/* Layout */



/*

███████ ██      ███████ ██   ██ ██████   ██████  ██   ██
██      ██      ██       ██ ██  ██   ██ ██    ██  ██ ██
█████   ██      █████     ███   ██████  ██    ██   ███
██      ██      ██       ██ ██  ██   ██ ██    ██  ██ ██
██      ███████ ███████ ██   ██ ██████   ██████  ██   ██

*/
.flexbox {
  overflow: hidden;
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

/* =================-------| FLEX - DIRECTION |-------================= */
.f-d-c {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.f-d-c-r {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.f-d-r {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.f-d-r-r {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.f-d-inh {
  -webkit-box-orient: inherit;
  -webkit-box-direction: inherit;
      -ms-flex-direction: inherit;
          flex-direction: inherit;
}

.f-d-ini {
  -webkit-box-orient: initial;
  -webkit-box-direction: initial;
      -ms-flex-direction: initial;
          flex-direction: initial;
}

/* =================-------| FLEX - WRAP |-------================= */
.f-w-w {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.f-w-n-w {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.f-w-w-r {
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}

.f-w-inh {
  -ms-flex-wrap: inherit;
      flex-wrap: inherit;
}

/* =================-------| FLEX - BASIS |-------================= */
.f-b-f-a {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

.f-b-max-c {
  flex-basis: -webkit-max-content;
  flex-basis: -moz-max-content;
  -ms-flex-preferred-size: max-content;
      flex-basis: max-content;
}

.f-b-min-c {
  flex-basis: -webkit-min-content;
  flex-basis: -moz-min-content;
  -ms-flex-preferred-size: min-content;
      flex-basis: min-content;
}

.f-b-fill {
  flex-basis: -webkit-fill-available;
  flex-basis: -moz-available;
  -ms-flex-preferred-size: fill;
      flex-basis: fill;
}

.f-b-f-c {
  flex-basis: -webkit-fit-content;
  flex-basis: -moz-fit-content;
  -ms-flex-preferred-size: fit-content;
      flex-basis: fit-content;
}

.f-b-c {
  -ms-flex-preferred-size: content;
      flex-basis: content;
}

.f-b-inh {
  -ms-flex-preferred-size: inherit;
      flex-basis: inherit;
}

.f-b-ini {
  -ms-flex-preferred-size: initial;
      flex-basis: initial;
}

.f-b-uns {
  -ms-flex-preferred-size: unset;
      flex-basis: unset;
}

/*

     ██ ██    ██ ███████ ████████ ██ ███████ ██    ██
     ██ ██    ██ ██         ██    ██ ██       ██  ██
     ██ ██    ██ ███████    ██    ██ █████     ████
██   ██ ██    ██      ██    ██    ██ ██         ██
 █████   ██████  ███████    ██    ██ ██         ██

*/
/* =================-------| JUSTIFY - CONTENT |-------================= */
.j-c-s-b {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.j-c-s-a {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.j-c-f-s {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.j-c-f-e {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.j-c-c {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.j-c-s {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.j-c-e {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

/* =================-------| JUSTIFY - ITEMS |-------================= */
.j-i-f-e {
  justify-items: flex-end;
}

/*

 █████  ██      ██  ██████  ███    ██
██   ██ ██      ██ ██       ████   ██
███████ ██      ██ ██   ███ ██ ██  ██
██   ██ ██      ██ ██    ██ ██  ██ ██
██   ██ ███████ ██  ██████  ██   ████

*/
/* =================-------| ALIGN GLOBAL |-------================= */
.centered {
  position: relative;
  margin: auto;
  float: left;
  top: 50%;
  left: 50%;
  /*bringyourownprefixes*/
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.f-l {
  float: left;
}

.f-r {
  float: right;
}

/* =================-------| ALIGN RELATIVE |-------================= */
.r-h-c {
  position: relative;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.r-v-c {
  position: relative;
  float: left;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.r-v-b {
  position: relative;
  float: left;
  margin: auto;
  top: 100%;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

/* =================-------| ALIGN ABSOLUTE |-------================= */
.a-h-c {
  position: absolute;
  left: 0;
  right: 0;
}

.a-v-c {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}

/* =================-------| ALIGN - ITEMS |-------================= */
.a-i-inh {
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
}

.a-i-ini {
  -webkit-box-align: initial;
      -ms-flex-align: initial;
          align-items: initial;
}

.a-i-b {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.a-i-c {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.a-i-f-s {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.a-i-f-e {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.a-i-s {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.a-i-u {
  -webkit-box-align: unset;
      -ms-flex-align: unset;
          align-items: unset;
}

/* =================-------| ALIGN - SELF |-------================= */
.a-s-a {
  -ms-flex-item-align: auto;
      -ms-grid-row-align: auto;
      align-self: auto;
}

.a-s-inh {
  -ms-flex-item-align: inherit;
      -ms-grid-row-align: inherit;
      align-self: inherit;
}

.a-s-ini {
  -ms-flex-item-align: initial;
      -ms-grid-row-align: initial;
      align-self: initial;
}

.a-s-b {
  -ms-flex-item-align: baseline;
      align-self: baseline;
}

.a-s-c {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.a-s-f-s {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.a-s-f-e {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.a-s-s {
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
}

.a-s-u {
  -ms-flex-item-align: unset;
      -ms-grid-row-align: unset;
      align-self: unset;
}

/* =================-------| ALIGN - CONTENT |-------================= */
.a-c-a {
  -ms-flex-line-pack: auto;
      align-content: auto;
}

.a-c-inh {
  -ms-flex-line-pack: inherit;
      align-content: inherit;
}

.a-c-ini {
  -ms-flex-line-pack: initial;
      align-content: initial;
}

.a-c-b {
  -ms-flex-line-pack: baseline;
      align-content: baseline;
}

.a-c-c {
  -ms-flex-line-pack: center;
      align-content: center;
}

.a-c-f-s {
  -ms-flex-line-pack: start;
      align-content: flex-start;
}

.a-c-f-e {
  -ms-flex-line-pack: end;
      align-content: flex-end;
}

.a-c-s {
  -ms-flex-line-pack: stretch;
      align-content: stretch;
}

.a-c-u {
  -ms-flex-line-pack: unset;
      align-content: unset;
}

/* =================-------| ALIGN - TEXT |-------================= */
/* TEXT ALIGN START */
.t-a-s {
  text-align: start;
}

/* TEXT ALIGN CENTER */
.t-a-c {
  text-align: center ;
}

/* TEXT ALIGN LAST CENTER */
.t-a-l-c {
  text-align-last: center;
}

/* TEXT ALIGN LEFT */
.t-a-l {
  text-align: left;
}

/* TEXT ALIGN RIGHT*/
.t-a-r {
  text-align: right;
}

/* =================-------| VERTICAL ALIGN |-------================= */
.v-a-b {
  vertical-align: bottom;
}

.v-a-m {
  vertical-align: middle;
}

.v-a-t {
  vertical-align: top;
}



/* Helpers */
/* Helpers */
/* flip */
.flip-h {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
}

.flip-v {
  transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  -o-transform: scaleY(-1);
}

/* OBJECT FIT */
.obj-fit-con {
  -o-object-fit: content;
     object-fit: content;
}

.obj-fit-cov {
  -o-object-fit: cover;
     object-fit: cover;
}

.obj-fit-a {
  -o-object-fit: auto;
     object-fit: auto;
}

/* EFFECTS - Fx FX fx fX */



.h-e:hover {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);

  
}

.h-e-color{
  filter: grayscale(100%);
  transition: 1s;
  
}
.h-e-color:hover{
  filter: grayscale(0%);
}

.h-e-scale{
  transform: scale(1) ;
  transition: 0.3s ;
}

.h-e-scale:hover{
  transform: scale(1.1) ;
  z-index: 99 ;
}




.ripple {
  background-position: center;
  -webkit-transition: 0.8s background;
  transition: 0.8s background;
  -webkit-transform-origin: center;
          transform-origin: center;
  z-index: 99999;
}

.ripple:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}

.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  -webkit-transition: background 0s;
  transition: background 0s;
  -webkit-transform-origin: center;
          transform-origin: center;
  z-index: 99999;
}

.fx-reflect {
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}

.text-effect-perspective {
  transform: perspective(100px) rotateY(33deg);
  -webkit-transform: perspective(100px) rotateY(33deg);
  -moz-transform: perspective(100px) rotateY(33deg);
  -ms-transform: perspective(100px) rotateY(33deg);
  -o-transform: perspective(100px) rotateY(33deg);
}

.text-effect-shadow {
  text-shadow: rgba(0, 0, 0, 0.05) 0 0 0px, rgba(0, 0, 0, 0.05) 1px 1px 1px, rgba(0, 0, 0, 0.045) 2px 2px 1px, rgba(0, 0, 0, 0.045) 3px 3px 1px, rgba(0, 0, 0, 0.045) 4px 4px 1px, rgba(0, 0, 0, 0.045) 5px 5px 1px;
}

/* Aplicar escala de grises al 100% */
.img-filter-gray {
  filter: grayscale(100%);
}

/* Aplicar un efecto de desenfoque */
.img-filter-blur {
  filter: blur(5px); /* Ajusta el valor según la cantidad de desenfoque que desees */
}

/* Aplicar un efecto de sepia */
.img-filter-sepia {
  filter: sepia(100%);
}

/* Aplicar un efecto de saturación */
.img-filter-saturate {
  filter: saturate(200%); /* Ajusta el valor según la saturación deseada */
}

/* Aplicar un efecto de contraste */
.img-filter-contrast {
  filter: contrast(200%); /* Ajusta el valor según el contraste deseado */
}

/* Aplicar un efecto de brillo */
.img-filter-brightness {
  filter: brightness(150%); /* Ajusta el valor según el brillo deseado */
}

/* Aplicar un efecto de invertir colores */
.img-filter-invert {
  filter: invert(100%);
}

/*

███    ███  █████  ██   ██     ██      ██ ███    ██ ███████ ███████
████  ████ ██   ██  ██ ██      ██      ██ ████   ██ ██      ██
██ ████ ██ ███████   ███       ██      ██ ██ ██  ██ █████   ███████
██  ██  ██ ██   ██  ██ ██      ██      ██ ██  ██ ██ ██           ██
██      ██ ██   ██ ██   ██     ███████ ██ ██   ████ ███████ ███████

*/
/* =================-------| MAX LINES |-------================= */
.max-lines-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.max-lines-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.max-lines-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.max-lines-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.max-lines-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.max-lines-6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.max-lines-7 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
}

.max-lines-8 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}

.max-lines-9 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
}

.max-lines-10 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}

/*

███    ███  █████  ██   ██     ██      ███████ ███    ██  ██████  ██   ██ ████████
████  ████ ██   ██  ██ ██      ██      ██      ████   ██ ██       ██   ██    ██
██ ████ ██ ███████   ███       ██      █████   ██ ██  ██ ██   ███ ███████    ██
██  ██  ██ ██   ██  ██ ██      ██      ██      ██  ██ ██ ██    ██ ██   ██    ██
██      ██ ██   ██ ██   ██     ███████ ███████ ██   ████  ██████  ██   ██    ██

*/
/* =================-------| MAX LENGHT |-------================= */
.max-l-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.max-l-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.max-l-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.max-l-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.max-l-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.max-l-6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.max-l-7 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
}

.max-l-8 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}

.max-l-9 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
}

.max-l-10 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}

/*

 █████  ███████ ██████  ███████  ██████ ████████     ██████   █████  ████████ ██  ██████
██   ██ ██      ██   ██ ██      ██         ██        ██   ██ ██   ██    ██    ██ ██    ██
███████ ███████ ██████  █████   ██         ██        ██████  ███████    ██    ██ ██    ██
██   ██      ██ ██      ██      ██         ██        ██   ██ ██   ██    ██    ██ ██    ██
██   ██ ███████ ██      ███████  ██████    ██        ██   ██ ██   ██    ██    ██  ██████

*/
/* =================-------| ASPECT RATIO |-------================= */
.b-0px {
  bottom: 0;
}

.a-r-1-1 {
  width: 100%;
  content: '';
  display: inline-table;
  padding-top: 100%;
}

.a-r-3-2 {
  width: 100%;
  content: '';
  display: inline-table;
  padding-top: 66.66%;
}

.a-r-4-3 {
  width: 100%;
  content: '';
  display: inline-table;
  padding-top: 75%;
}

.a-r-8-3 {
  width: 100%;
  content: '';
  display: inline-table;
  padding-top: 62.5%;
}

.a-r-16-9 {
  width: 100%;
  content: '';
  display: inline-table;
  padding-top: 56.25%;
}

/* NUEVOS AÑADIDOS 20 JUNIO 2021 */
.a-r {
  height: 0;
  overflow: hidden;
  position: relative;
}

.a-r-content {
  display: table;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.a-r-content > .a-r-center {
  display: table-cell;
  vertical-align: middle;
}

.a-r-2-1 {
  padding-top: 50%;
}

.a-r-1-2 {
  padding-top: 200%;
}

.a-r-3-2 {
  padding-top: 66.66666667%;
}

.a-r-2-3 {
  padding-top: 150%;
}

.a-r-4-3 {
  padding-top: 75%;
}

.a-r-3-4 {
  padding-top: 133.33333333%;
}

.a-r-5-3 {
  padding-top: 60%;
}

.a-r-3-5 {
  padding-top: 166.66666667%;
}

.a-r-5-4 {
  padding-top: 80%;
}

.a-r-4-5 {
  padding-top: 125%;
}

.a-r-16-9 {
  padding-top: 56.25%;
}

.a-r-9-16 {
  padding-top: 177.77777778%;
}

.a-r-16-10 {
  padding-top: 62.5%;
}

.a-r-10-16 {
  padding-top: 160%;
}

.a-r-21-9 {
  padding-top: 42.85714286%;
}

.a-r-9-21 {
  padding-top: 233.33333333%;
}

/* Special ratios */
.a-r-1-1,
.a-r-square {
  padding-top: 100%;
}

.a-r-golden {
  padding-top: 61.80469716%;
}

.a-r-golden-v {
  padding-top: 161.8%;
}

/* textarea */
.txt-a-r-n {
  resize: none;
}

/*

████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██

*/
/* =================-------| TEXT - RENDERING |-------================= */
.t-r-o-l {
  text-rendering: optimizeLegibility;
}

.t-r-o-s {
  text-rendering: optimizeSpeed;
}

.t-r-g-p {
  text-rendering: geometricPrecision;
}

/* =================-------| TEXT - OVERFLOW |-------================= */
.t-of-e {
  display: block;
  display: -webkit-box;
  height: 0 auto;
  width: 0 auto;
  margin: 0 auto;
  line-height: 1.5;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-break: break-all;
}

.t-of-c {
  text-overflow: clip;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}

/*

███████ ████████ ██    ██ ██      ███████ ███████
██         ██     ██  ██  ██      ██      ██
███████    ██      ████   ██      █████   ███████
     ██    ██       ██    ██      ██           ██
███████    ██       ██    ███████ ███████ ███████

*/
i.default {
  background: none;
  color: #7e7e7e;
}

i.white {
  background: none;
  color: #fefefe;
}

i.black {
  background: none;
  color: #121212;
}

i.alert {
  background: none;
  color: #ed293e;
}

i.info {
  background: none;
  color: #0096ff;
}

i.caution {
  background: none;
  color: #fccb18;
}

i.success {
  background: none;
  color: #02e473;
}

.transparent {
  background: transparent;
  background-image: transparent;
  background-color: transparent;
}

.default {
  background-color: rgba(18, 18, 18, 0.7);
  background: rgba(18, 18, 18, 0.7);
  color: #ffffff;
}

.white {
  background: #fefefe;
  color: #7e7e7e;
}

.black {
  background: #121212;
  color: #7e7e7e;
}

.alert {
  background: #ed293e;
  color: white;
}

.info {
  background: #0096ff;
  color: white;
}

.caution {
  background: #fccb18;
  color: white;
}

.success {
  background: #02e473;
  color: white;
}

/* MORE COLORS */
.pink {
  background: #fd528e;
  color: white;
}

.skyblue {
  background: #0096ff;
  color: white;
}

.fuchsia {
  background: #ff0096;
  color: white;
}

/* GHOST */
.ghost {
  background: none;
  color: inherit;
}
.h-e-ghost-inh:hover {
    background: none;
    color: inherit;
    -webkit-box-shadow: 0 0 0 2px inset inherit;
            box-shadow: 0 0 0 2px inset inherit;

}
.h-e-ghost-e:hover {
  background: none;
  color: #e1a54b;
  -webkit-box-shadow: 0 0 0 2px inset #e1a54b;
          box-shadow: 0 0 0 2px inset #e1a54b;

}
.ghost.info {
  color: #0096ff;
  -webkit-box-shadow: 0 0 0 2px inset #0096ff;
          box-shadow: 0 0 0 2px inset #0096ff;
}

.ghost.enfasis {

  -webkit-box-shadow: 0 0 0 2px inset var(--current-color-emphasis);
          box-shadow: 0 0 0 2px inset var(--current-color-emphasis);
}

.ghost.caution {
  color: #fccb18;
  -webkit-box-shadow: 0 0 0 2px inset #fccb18;
          box-shadow: 0 0 0 2px inset #fccb18;
}

.ghost.success {
  color: #02e473;
  -webkit-box-shadow: 0 0 0 2px inset #02e473;
          box-shadow: 0 0 0 2px inset #02e473;
}

.ghost.alert {
  color: #ed293e;
  -webkit-box-shadow: 0 0 0 2px inset #ed293e;
          box-shadow: 0 0 0 2px inset #ed293e;
}

.ghost.default {
  color: #dedede;
  -webkit-box-shadow: 0 0 0 2px inset #dedede;
          box-shadow: 0 0 0 2px inset #dedede;
}

.ghost.black {
  color: #121212;
  -webkit-box-shadow: 0 0 0 2px inset #121212;
          box-shadow: 0 0 0 2px inset #121212;
}

.ghost.white {
  color: #fefefe;
  -webkit-box-shadow: 0 0 0 2px inset #fefefe;
          box-shadow: 0 0 0 2px inset #fefefe;
}

.ghost.pink {
  color: #fd528e;
  -webkit-box-shadow: 0 0 0 2px inset #fd528e;
          box-shadow: 0 0 0 2px inset #fd528e;
}

.ghost.skyblue {
  color: #0096ff;
  -webkit-box-shadow: 0 0 0 2px inset #0096ff;
          box-shadow: 0 0 0 2px inset #0096ff;
}

.ghost.fuchsia {
  color: #ff0096;
  -webkit-box-shadow: 0 0 0 2px inset #ff0096;
          box-shadow: 0 0 0 2px inset #ff0096;
}

/* LINES Left */
.line-l {
  background: transparent;
  color: inherit;
  -webkit-box-shadow: 0 0 0 2px inset inherit;
          box-shadow: 0 0 0 2px inset inherit;
}

.line-l.info {
  color: #0096ff;
  -webkit-box-shadow: 2px 0 0 0 inset #0096ff;
          box-shadow: 2px 0 0 0 inset #0096ff;
}

.line-l.caution {
  color: #fccb18;
  -webkit-box-shadow: 2px 0 0 0 inset #fccb18;
          box-shadow: 2px 0 0 0 inset #fccb18;
}

.line-l.success {
  color: #02e473;
  -webkit-box-shadow: 2px 0 0 0 inset #02e473;
          box-shadow: 2px 0 0 0 inset #02e473;
}

.line-l.alert {
  color: #ed293e;
  -webkit-box-shadow: 2px 0 0 0 inset #ed293e;
          box-shadow: 2px 0 0 0 inset #ed293e;
}

.line-l.default {
  color: #7e7e7e;
  -webkit-box-shadow: 2px 0 0 0 inset #dedede;
          box-shadow: 2px 0 0 0 inset #dedede;
}

.line-l.black {
  color: #121212;
  -webkit-box-shadow: 2px 0 0 0 inset #121212;
          box-shadow: 2px 0 0 0 inset #121212;
}

.line-l.white {
  color: inherit;
  -webkit-box-shadow: 2px 0 0 0 inset #fefefe;
          box-shadow: 2px 0 0 0 inset #fefefe;
}

.line-l.pink {
  color: #fd528e;
  -webkit-box-shadow: 2px 0 0 0 inset #fd528e;
          box-shadow: 2px 0 0 0 inset #fd528e;
}

.line-l.skyblue {
  color: #0096ff;
  -webkit-box-shadow: 2px 0 0 0 inset #0096ff;
          box-shadow: 2px 0 0 0 inset #0096ff;
}

.line-l.fuchsia {
  color: #ff0096;
  -webkit-box-shadow: 2px 0 0 0 inset #ff0096;
          box-shadow: 2px 0 0 0 inset #ff0096;
}

/* LINES Right */
.line-r {
  background: transparent;
  color: inherit;
  -webkit-box-shadow: 0 0 0 2px inset inherit;
          box-shadow: 0 0 0 2px inset inherit;
}

.line-r.info {
  color: #0096ff;
  -webkit-box-shadow: -2px 0 0 0 inset #0096ff;
          box-shadow: -2px 0 0 0 inset #0096ff;
}

.line-r.caution {
  color: #fccb18;
  -webkit-box-shadow: -2px 0 0 0 inset #fccb18;
          box-shadow: -2px 0 0 0 inset #fccb18;
}

.line-r.success {
  color: #02e473;
  -webkit-box-shadow: -2px 0 0 0 inset #02e473;
          box-shadow: -2px 0 0 0 inset #02e473;
}

.line-r.alert {
  color: #ed293e;
  -webkit-box-shadow: -2px 0 0 0 inset #ed293e;
          box-shadow: -2px 0 0 0 inset #ed293e;
}

.line-r.default {
  color: #7e7e7e;
  -webkit-box-shadow: -2px 0 0 0 inset #dedede;
          box-shadow: -2px 0 0 0 inset #dedede;
}

.line-r.black {
  color: #121212;
  -webkit-box-shadow: -2px 0 0 0 inset #121212;
          box-shadow: -2px 0 0 0 inset #121212;
}

.line-r.white {
  color: inherit;
  -webkit-box-shadow: -2px 0 0 0 inset #fefefe;
          box-shadow: -2px 0 0 0 inset #fefefe;
}

.line-r.pink {
  color: #fd528e;
  -webkit-box-shadow: -2px 0 0 0 inset #fd528e;
          box-shadow: -2px 0 0 0 inset #fd528e;
}

.line-r.skyblue {
  color: #0096ff;
  -webkit-box-shadow: -2px 0 0 0 inset #0096ff;
          box-shadow: -2px 0 0 0 inset #0096ff;
}

.line-r.fuchsia {
  color: #ff0096;
  -webkit-box-shadow: -2px 0 0 0 inset #ff0096;
          box-shadow: -2px 0 0 0 inset #ff0096;
}

/* LINES Top */
.line-t {
  background: transparent;
  color: inherit;
  -webkit-box-shadow: 0 2px 0 0 inset inherit;
          box-shadow: 0 2px 0 0 inset inherit;
}

.line-t.info {
  color: #0096ff;
  -webkit-box-shadow: 0 2px 0 0 inset #0096ff;
          box-shadow: 0 2px 0 0 inset #0096ff;
}

.line-t.caution {
  color: #fccb18;
  -webkit-box-shadow: 0 2px 0 0 inset #fccb18;
          box-shadow: 0 2px 0 0 inset #fccb18;
}

.line-t.success {
  color: #02e473;
  -webkit-box-shadow: 0 2px 0 0 inset #02e473;
          box-shadow: 0 2px 0 0 inset #02e473;
}

.line-t.alert {
  color: #ed293e;
  -webkit-box-shadow: 0 2px 0 0 inset #ed293e;
          box-shadow: 0 2px 0 0 inset #ed293e;
}

.line-t.default {
  color: #7e7e7e;
  -webkit-box-shadow: 0 2px 0 0 inset #dedede;
          box-shadow: 0 2px 0 0 inset #dedede;
}

.line-t.black {
  color: #121212;
  -webkit-box-shadow: 2px 0 0 0 inset #121212;
          box-shadow: 2px 0 0 0 inset #121212;
}

.line-t.white {
  color: inherit;
  -webkit-box-shadow: 2px 0 0 0 inset #fefefe;
          box-shadow: 2px 0 0 0 inset #fefefe;
}

.line-t.pink {
  color: #fd528e;
  -webkit-box-shadow: 2px 0 0 0 inset #fd528e;
          box-shadow: 2px 0 0 0 inset #fd528e;
}

.line-t.skyblue {
  color: #0096ff;
  -webkit-box-shadow: 2px 0 0 0 inset #0096ff;
          box-shadow: 2px 0 0 0 inset #0096ff;
}

.line-t.fuchsia {
  color: #ff0096;
  -webkit-box-shadow: 2px 0 0 0 inset #fd528e;
          box-shadow: 2px 0 0 0 inset #fd528e;
}

/* LINES Bottom */
.line-b {
  background: transparent;
  color: inherit;
  -webkit-box-shadow: 0 -2px 0 0 inset inherit;
          box-shadow: 0 -2px 0 0 inset inherit;
}

.line-b.info {
  color: #0096ff;
  -webkit-box-shadow: 0 -2px 0 0 inset #0096ff;
          box-shadow: 0 -2px 0 0 inset #0096ff;
}

.line-b.caution {
  color: #fccb18;
  -webkit-box-shadow: 0 -2px 0 0 inset #fccb18;
          box-shadow: 0 -2px 0 0 inset #fccb18;
}

.line-b.success {
  color: #02e473;
  -webkit-box-shadow: 0 -2px 0 0 inset #02e473;
          box-shadow: 0 -2px 0 0 inset #02e473;
}

.line-b.alert {
  color: #ed293e;
  -webkit-box-shadow: 0 -2px 0 0 inset #ed293e;
          box-shadow: 0 -2px 0 0 inset #ed293e;
}

.line-b.default {
  color: #7e7e7e;
  -webkit-box-shadow: 0 -2px 0 0 inset #dedede;
          box-shadow: 0 -2px 0 0 inset #dedede;
}

.line-b.black {
  color: #121212;
  -webkit-box-shadow: 0 -2px 0 0 inset #121212;
          box-shadow: 0 -2px 0 0 inset #121212;
}

.line-b.white {
  color: inherit;
  -webkit-box-shadow: 0 -2px 0 0 inset #fefefe;
          box-shadow: 0 -2px 0 0 inset #fefefe;
}

.line-b.pink {
  color: #fd528e;
  -webkit-box-shadow: 0 -2px 0 0 inset #fd528e;
          box-shadow: 0 -2px 0 0 inset #fd528e;
}

.line-b.skyblue {
  color: #0096ff;
  -webkit-box-shadow: 0 -2px 0 0 inset #0096ff;
          box-shadow: 0 -2px 0 0 inset #0096ff;
}

.line-b.fuchsia {
  color: #ff0096;
  -webkit-box-shadow: 0 -2px 0 0 inset #ff0096;
          box-shadow: 0 -2px 0 0 inset #ff0096;
}

/*

███████ ██    ██ ███████ ███    ██ ████████ ███████
██      ██    ██ ██      ████   ██    ██    ██
█████   ██    ██ █████   ██ ██  ██    ██    ███████
██       ██  ██  ██      ██  ██ ██    ██         ██
███████   ████   ███████ ██   ████    ██    ███████

*/
.e-f-rotate-180:focus {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.e-f-rotate-180:after(:focus) {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

/*

█████  ███    ██ ██ ███    ███  █████  ████████ ██  ██████  ███    ██ ███████
██   ██ ████   ██ ██ ████  ████ ██   ██    ██    ██ ██    ██ ████   ██ ██
███████ ██ ██  ██ ██ ██ ████ ██ ███████    ██    ██ ██    ██ ██ ██  ██ ███████
██   ██ ██  ██ ██ ██ ██  ██  ██ ██   ██    ██    ██ ██    ██ ██  ██ ██      ██
██   ██ ██   ████ ██ ██      ██ ██   ██    ██    ██  ██████  ██   ████ ███████

*/
/* =================-------| ANIMATIONS |-------================= */
.a-inf {
  animation-duration: infinite;
  -webkit-animation-duration: infinite;
}

/* =================-------| ANIMATIONS - DELAY |-------================= */
.a-d-01s {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.a-d-02s {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.a-d-03s {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.a-d-04s {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.a-d-05s {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.a-d-06s {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.a-d-07s {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.a-d-08s {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.a-d-09s {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.a-d-1s {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

@-webkit-keyframes flip {
  from {
    transform: initial;
    -webkit-transform: initial;
    -moz-transform: initial;
    -ms-transform: initial;
    -o-transform: initial;
  }
  to {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
}

@keyframes flip {
  from {
    transform: initial;
    -webkit-transform: initial;
    -moz-transform: initial;
    -ms-transform: initial;
    -o-transform: initial;
  }
  to {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
}

/*

████████ ██████   █████  ███    ██ ███████ ██ ████████ ██  ██████  ███    ██ ███████
   ██    ██   ██ ██   ██ ████   ██ ██      ██    ██    ██ ██    ██ ████   ██ ██
 ██    ██████  ███████ ██ ██  ██ ███████ ██    ██    ██ ██    ██ ██ ██  ██ ███████
 ██    ██   ██ ██   ██ ██  ██ ██      ██ ██    ██    ██ ██    ██ ██  ██ ██      ██
 ██    ██   ██ ██   ██ ██   ████ ███████ ██    ██    ██  ██████  ██   ████ ███████

*/
/* =================-------| TRANSITIONS - MILISEGUNDOS |-------================= */
.t-01s {
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  -ms-transition: 0.1s;
  -o-transition: 0.1s;
}

.t-02s {
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.t-03s {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.t-04s {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}

.t-05s {
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

/* =================-------| TRANSITIONS - SEGUNDOS |-------================= */
.t-1s {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

.t-2s {
  transition: 2s;
  -webkit-transition: 2s;
  -moz-transition: 2s;
  -ms-transition: 2s;
  -o-transition: 2s;
}

.t-3s {
  transition: 3s;
  -webkit-transition: 3s;
  -moz-transition: 3s;
  -ms-transition: 3s;
  -o-transition: 3s;
}

.t-4s {
  transition: 4s;
  -webkit-transition: 4s;
  -moz-transition: 4s;
  -ms-transition: 4s;
  -o-transition: 4s;
}

.t-5s {
  transition: 5s;
  -webkit-transition: 5s;
  -moz-transition: 5s;
  -ms-transition: 5s;
  -o-transition: 5s;
}

/*

██   ██  █████  ██████  ██ ████████ ███████
██   ██ ██   ██ ██   ██ ██    ██    ██
███████ ███████ ██████  ██    ██    ███████
██   ██ ██   ██ ██   ██ ██    ██         ██
██   ██ ██   ██ ██████  ██    ██    ███████

*/
/* = Position Relative - Float Left - Margin Auto - MARGIN AUTO */
.h-pr-fl-ma {
  position: relative;
  float: left;
  margin: auto;
}

/* = Position Relative - Float Right - Margin Auto - MARGIN AUTO */
.h-pr-fr-ma {
  position: relative;
  float: right;
  margin: auto;
}

/*

██     ██ ██ ██████  ████████ ██   ██
██     ██ ██ ██   ██    ██    ██   ██
██  █  ██ ██ ██   ██    ██    ███████
██ ███ ██ ██ ██   ██    ██    ██   ██
 ███ ███  ██ ██████     ██    ██   ██

*/
/* Sintax and specials width */
.w-inh {
  width: inherit;
}
.w-a {
  width: auto;
}
.w-auto {
  width: auto;
}

.width-auto {
  width: auto;
}

.width-inherit {
  width: inherit;
}

/* Width px - pt - % - vw - vh */
.w-1px {
  width: 1px;
}

.w-2px {
  width: 2px;
}

.w-3px {
  width: 3px;
}

.w-4px {
  width: 4px;
}

.w-5px {
  width: 5px;
}

.w-10px {
  width: 10px;
}

.w-15px {
  width: 15px;
}

.w-20px {
  width: 20px;
}

.w-25px {
  width: 25px;
}

.w-30px {
  width: 30px;
}

.w-35px {
  width: 35px;
}

.w-40px {
  width: 40px;
}

.w-45px {
  width: 45px;
}

.w-50px {
  width: 50px;
}

.w-55px {
  width: 55px;
}

.w-60px {
  width: 60px;
}

.w-65px {
  width: 65px;
}

.w-70px {
  width: 70px;
}

.w-75px {
  width: 75px;
}

.w-80px {
  width: 80px;
}

.w-85px {
  width: 85px;
}

.w-90px {
  width: 90px;
}

.w-95px {
  width: 95px;
}

.w-100px {
  width: 100px;
}

.w-125px {
  width: 125px;
}

.w-150px {
  width: 150px;
}

.w-175px {
  width: 175px;
}

.w-200px {
  width: 200px;
}

.w-225px {
  width: 225px;
}

.w-250px {
  width: 250px;
}

.w-275px {
  width: 275px;
}

.w-300px {
  width: 300px;
}

.w-325px {
  width: 325px;
}

.w-350px {
  width: 350px;
}

.w-375px {
  width: 375px;
}

.w-400px {
  width: 400px;
}

.w-425px {
  width: 425px;
}

.w-450px {
  width: 450px;
}

.w-475px {
  width: 475px;
}

.w-500px {
  width: 500px;
}

.w-1000px {
  width: 1000px;
}

.w-1pt {
  width: 1pt;
}

.w-2pt {
  width: 2pt;
}

.w-3pt {
  width: 3pt;
}

.w-4pt {
  width: 4pt;
}

.w-5pt {
  width: 5pt;
}

.w-10pt {
  width: 10pt;
}

.w-15pt {
  width: 15pt;
}

.w-20pt {
  width: 20pt;
}

.w-25pt {
  width: 25pt;
}

.w-30pt {
  width: 30pt;
}

.w-35pt {
  width: 35pt;
}

.w-40pt {
  width: 40pt;
}

.w-45pt {
  width: 45pt;
}

.w-50pt {
  width: 50pt;
}

.w-55pt {
  width: 55pt;
}

.w-60pt {
  width: 60pt;
}

.w-65pt {
  width: 65pt;
}

.w-70pt {
  width: 70pt;
}

.w-75pt {
  width: 75pt;
}

.w-80pt {
  width: 80pt;
}

.w-85pt {
  width: 85pt;
}

.w-90pt {
  width: 90pt;
}

.w-95pt {
  width: 95pt;
}

.w-100pt {
  width: 100pt;
}

.w-125pt {
  width: 125pt;
}

.w-150pt {
  width: 150pt;
}

.w-175pt {
  width: 175pt;
}

.w-200pt {
  width: 200pt;
}

.w-225pt {
  width: 225pt;
}

.w-250pt {
  width: 250pt;
}

.w-275pt {
  width: 275pt;
}

.w-300pt {
  width: 300pt;
}

.w-325pt {
  width: 325pt;
}

.w-350pt {
  width: 350pt;
}

.w-375pt {
  width: 375pt;
}

.w-400pt {
  width: 400pt;
}

.w-425pt {
  width: 425pt;
}

.w-450pt {
  width: 450pt;
}

.w-475pt {
  width: 475pt;
}

.w-500pt {
  width: 500pt;
}

.w-1000pt {
  width: 1000pt;
}

.w-1 {
  width: 1%;
}

.w-2 {
  width: 2%;
}

.w-3 {
  width: 3%;
}

.w-4 {
  width: 4%;
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33.33333%;
}


.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.w-1vw {
  width: 1vw;
}

.w-2vw {
  width: 2vw;
}

.w-3vw {
  width: 3vw;
}

.w-4vw {
  width: 4vw;
}

.w-5vw {
  width: 5vw;
}

.w-10vw {
  width: 10vw;
}

.w-15vw {
  width: 15vw;
}

.w-20vw {
  width: 20vw;
}

.w-25vw {
  width: 25vw;
}

.w-30vw {
  width: 30vw;
}

.w-35vw {
  width: 35vw;
}

.w-40vw {
  width: 40vw;
}

.w-45vw {
  width: 45vw;
}

.w-50vw {
  width: 50vw;
}

.w-55vw {
  width: 55vw;
}

.w-60vw {
  width: 60vw;
}

.w-65vw {
  width: 65vw;
}

.w-70vw {
  width: 70vw;
}

.w-75vw {
  width: 75vw;
}

.w-80vw {
  width: 80vw;
}

.w-85vw {
  width: 85vw;
}

.w-90vw {
  width: 90vw;
}

.w-95vw {
  width: 95vw;
}

.w-100vw {
  width: 100vw;
}

/* Min Width px - pt - % - vw - vh */
.min-w-1px {
  min-width: 1px;
}

.min-w-2px {
  min-width: 2px;
}

.min-w-3px {
  min-width: 3px;
}

.min-w-4px {
  min-width: 4px;
}

.min-w-5px {
  min-width: 5px;
}

.min-w-10px {
  min-width: 10px;
}

.min-w-15px {
  min-width: 15px;
}

.min-w-20px {
  min-width: 20px;
}

.min-w-25px {
  min-width: 25px;
}

.min-w-30px {
  min-width: 30px;
}

.min-w-35px {
  min-width: 35px;
}

.min-w-40px {
  min-width: 40px;
}

.min-w-45px {
  min-width: 45px;
}

.min-w-50px {
  min-width: 50px;
}

.min-w-55px {
  min-width: 55px;
}

.min-w-60px {
  min-width: 60px;
}

.min-w-65px {
  min-width: 65px;
}

.min-w-70px {
  min-width: 70px;
}

.min-w-75px {
  min-width: 75px;
}

.min-w-80px {
  min-width: 80px;
}

.min-w-85px {
  min-width: 85px;
}

.min-w-90px {
  min-width: 90px;
}

.min-w-95px {
  min-width: 95px;
}

.min-w-100px {
  min-width: 100px;
}

.min-w-125px {
  min-width: 125px;
}

.min-w-150px {
  min-width: 150px;
}

.min-w-175px {
  min-width: 175px;
}

.min-w-200px {
  min-width: 200px;
}

.min-w-225px {
  min-width: 225px;
}

.min-w-250px {
  min-width: 250px;
}

.min-w-275px {
  min-width: 275px;
}

.min-w-300px {
  min-width: 300px;
}

.min-w-325px {
  min-width: 325px;
}

.min-w-350px {
  min-width: 350px;
}

.min-w-375px {
  min-width: 375px;
}

.min-w-400px {
  min-width: 400px;
}

.min-w-425px {
  min-width: 425px;
}

.min-w-450px {
  min-width: 450px;
}

.min-w-475px {
  min-width: 475px;
}

.min-w-500px {
  min-width: 500px;
}

.min-w-1000px {
  min-width: 1000px;
}

.min-w-1pt {
  min-width: 1pt;
}

.min-w-2pt {
  min-width: 2pt;
}

.min-w-3pt {
  min-width: 3pt;
}

.min-w-4pt {
  min-width: 4pt;
}

.min-w-5pt {
  min-width: 5pt;
}

.min-w-10pt {
  min-width: 10pt;
}

.min-w-15pt {
  min-width: 15pt;
}

.min-w-20pt {
  min-width: 20pt;
}

.min-w-25pt {
  min-width: 25pt;
}

.min-w-30pt {
  min-width: 30pt;
}

.min-w-35pt {
  min-width: 35pt;
}

.min-w-40pt {
  min-width: 40pt;
}

.min-w-45pt {
  min-width: 45pt;
}

.min-w-50pt {
  min-width: 50pt;
}

.min-w-55pt {
  min-width: 55pt;
}

.min-w-60pt {
  min-width: 60pt;
}

.min-w-65pt {
  min-width: 65pt;
}

.min-w-70pt {
  min-width: 70pt;
}

.min-w-75pt {
  min-width: 75pt;
}

.min-w-80pt {
  min-width: 80pt;
}

.min-w-85pt {
  min-width: 85pt;
}

.min-w-90pt {
  min-width: 90pt;
}

.min-w-95pt {
  min-width: 95pt;
}

.min-w-100pt {
  min-width: 100pt;
}

.min-w-125pt {
  min-width: 125pt;
}

.min-w-150pt {
  min-width: 150pt;
}

.min-w-175pt {
  min-width: 175pt;
}

.min-w-200pt {
  min-width: 200pt;
}

.min-w-225pt {
  min-width: 225pt;
}

.min-w-250pt {
  min-width: 250pt;
}

.min-w-275pt {
  min-width: 275pt;
}

.min-w-300pt {
  min-width: 300pt;
}

.min-w-325pt {
  min-width: 325pt;
}

.min-w-350pt {
  min-width: 350pt;
}

.min-w-375pt {
  min-width: 375pt;
}

.min-w-400pt {
  min-width: 400pt;
}

.min-w-425pt {
  min-width: 425pt;
}

.min-w-450pt {
  min-width: 450pt;
}

.min-w-475pt {
  min-width: 475pt;
}

.min-w-500pt {
  min-width: 500pt;
}

.min-w-1000pt {
  min-width: 1000pt;
}

.min-w-1 {
  min-width: 1%;
}

.min-w-2 {
  min-width: 2%;
}

.min-w-3 {
  min-width: 3%;
}

.min-w-4 {
  min-width: 4%;
}

.min-w-5 {
  min-width: 5%;
}

.min-w-10 {
  min-width: 10%;
}

.min-w-15 {
  min-width: 15%;
}

.min-w-20 {
  min-width: 20%;
}

.min-w-25 {
  min-width: 25%;
}

.min-w-30 {
  min-width: 30%;
}

.min-w-35 {
  min-width: 35%;
}

.min-w-40 {
  min-width: 40%;
}

.min-w-45 {
  min-width: 45%;
}

.min-w-50 {
  min-width: 50%;
}

.min-w-55 {
  min-width: 55%;
}

.min-w-60 {
  min-width: 60%;
}

.min-w-65 {
  min-width: 65%;
}

.min-w-70 {
  min-width: 70%;
}

.min-w-75 {
  min-width: 75%;
}

.min-w-80 {
  min-width: 80%;
}

.min-w-85 {
  min-width: 85%;
}

.min-w-90 {
  min-width: 90%;
}

.min-w-95 {
  min-width: 95%;
}

.min-w-100 {
  min-width: 100%;
}

.min-w-1vw {
  min-width: 1vw;
}

.min-w-2vw {
  min-width: 2vw;
}

.min-w-3vw {
  min-width: 3vw;
}

.min-w-4vw {
  min-width: 4vw;
}

.min-w-5vw {
  min-width: 5vw;
}

.min-w-10vw {
  min-width: 10vw;
}

.min-w-15vw {
  min-width: 15vw;
}

.min-w-20vw {
  min-width: 20vw;
}

.min-w-25vw {
  min-width: 25vw;
}

.min-w-30vw {
  min-width: 30vw;
}

.min-w-35vw {
  min-width: 35vw;
}

.min-w-40vw {
  min-width: 40vw;
}

.min-w-45vw {
  min-width: 45vw;
}

.min-w-50vw {
  min-width: 50vw;
}

.min-w-55vw {
  min-width: 55vw;
}

.min-w-60vw {
  min-width: 60vw;
}

.min-w-65vw {
  min-width: 65vw;
}

.min-w-70vw {
  min-width: 70vw;
}

.min-w-75vw {
  min-width: 75vw;
}

.min-w-80vw {
  min-width: 80vw;
}

.min-w-85vw {
  min-width: 85vw;
}

.min-w-90vw {
  min-width: 90vw;
}

.min-w-95vw {
  min-width: 95vw;
}

.min-w-100vw {
  min-width: 100vw;
}

/* Max Width px - pt - % - vw - vh */
.max-w-1px {
  max-width: 1px;
}

.max-w-2px {
  max-width: 2px;
}

.max-w-3px {
  max-width: 3px;
}

.max-w-4px {
  max-width: 4px;
}

.max-w-5px {
  max-width: 5px;
}

.max-w-10px {
  max-width: 10px;
}

.max-w-15px {
  max-width: 15px;
}

.max-w-20px {
  max-width: 20px;
}

.max-w-25px {
  max-width: 25px;
}

.max-w-30px {
  max-width: 30px;
}

.max-w-35px {
  max-width: 35px;
}

.max-w-40px {
  max-width: 40px;
}

.max-w-45px {
  max-width: 45px;
}

.max-w-50px {
  max-width: 50px;
}

.max-w-55px {
  max-width: 55px;
}

.max-w-60px {
  max-width: 60px;
}

.max-w-65px {
  max-width: 65px;
}

.max-w-70px {
  max-width: 70px;
}

.max-w-75px {
  max-width: 75px;
}

.max-w-80px {
  max-width: 80px;
}

.max-w-85px {
  max-width: 85px;
}

.max-w-90px {
  max-width: 90px;
}

.max-w-95px {
  max-width: 95px;
}

.max-w-100px {
  max-width: 100px;
}

.max-w-125px {
  max-width: 125px;
}

.max-w-150px {
  max-width: 150px;
}

.max-w-175px {
  max-width: 175px;
}

.max-w-200px {
  max-width: 200px;
}

.max-w-225px {
  max-width: 225px;
}

.max-w-250px {
  max-width: 250px;
}

.max-w-275px {
  max-width: 275px;
}

.max-w-300px {
  max-width: 300px;
}

.max-w-325px {
  max-width: 325px;
}

.max-w-350px {
  max-width: 350px;
}

.max-w-375px {
  max-width: 375px;
}

.max-w-400px {
  max-width: 400px;
}

.max-w-425px {
  max-width: 425px;
}

.max-w-450px {
  max-width: 450px;
}

.max-w-475px {
  max-width: 475px;
}

.max-w-500px {
  max-width: 500px;
}

.max-w-1000px {
  max-width: 1000px;
}

.max-w-1pt {
  max-width: 1pt;
}

.max-w-2pt {
  max-width: 2pt;
}

.max-w-3pt {
  max-width: 3pt;
}

.max-w-4pt {
  max-width: 4pt;
}

.max-w-5pt {
  max-width: 5pt;
}

.max-w-10pt {
  max-width: 10pt;
}

.max-w-15pt {
  max-width: 15pt;
}

.max-w-20pt {
  max-width: 20pt;
}

.max-w-25pt {
  max-width: 25pt;
}

.max-w-30pt {
  max-width: 30pt;
}

.max-w-35pt {
  max-width: 35pt;
}

.max-w-40pt {
  max-width: 40pt;
}

.max-w-45pt {
  max-width: 45pt;
}

.max-w-50pt {
  max-width: 50pt;
}

.max-w-55pt {
  max-width: 55pt;
}

.max-w-60pt {
  max-width: 60pt;
}

.max-w-65pt {
  max-width: 65pt;
}

.max-w-70pt {
  max-width: 70pt;
}

.max-w-75pt {
  max-width: 75pt;
}

.max-w-80pt {
  max-width: 80pt;
}

.max-w-85pt {
  max-width: 85pt;
}

.max-w-90pt {
  max-width: 90pt;
}

.max-w-95pt {
  max-width: 95pt;
}

.max-w-100pt {
  max-width: 100pt;
}

.max-w-125pt {
  max-width: 125pt;
}

.max-w-150pt {
  max-width: 150pt;
}

.max-w-175pt {
  max-width: 175pt;
}

.max-w-200pt {
  max-width: 200pt;
}

.max-w-225pt {
  max-width: 225pt;
}

.max-w-250pt {
  max-width: 250pt;
}

.max-w-275pt {
  max-width: 275pt;
}

.max-w-300pt {
  max-width: 300pt;
}

.max-w-325pt {
  max-width: 325pt;
}

.max-w-350pt {
  max-width: 350pt;
}

.max-w-375pt {
  max-width: 375pt;
}

.max-w-400pt {
  max-width: 400pt;
}

.max-w-425pt {
  max-width: 425pt;
}

.max-w-450pt {
  max-width: 450pt;
}

.max-w-475pt {
  max-width: 475pt;
}

.max-w-500pt {
  max-width: 500pt;
}

.max-w-1000pt {
  max-width: 1000pt;
}

.max-w-1 {
  max-width: 1%;
}

.max-w-2 {
  max-width: 2%;
}

.max-w-3 {
  max-width: 3%;
}

.max-w-4 {
  max-width: 4%;
}

.max-w-5 {
  max-width: 5%;
}

.max-w-10 {
  max-width: 10%;
}

.max-w-15 {
  max-width: 15%;
}

.max-w-20 {
  max-width: 20%;
}

.max-w-25 {
  max-width: 25%;
}

.max-w-30 {
  max-width: 30%;
}

.max-w-35 {
  max-width: 35%;
}

.max-w-40 {
  max-width: 40%;
}

.max-w-45 {
  max-width: 45%;
}

.max-w-50 {
  max-width: 50%;
}

.max-w-55 {
  max-width: 55%;
}

.max-w-60 {
  max-width: 60%;
}

.max-w-65 {
  max-width: 65%;
}

.max-w-70 {
  max-width: 70%;
}

.max-w-75 {
  max-width: 75%;
}

.max-w-80 {
  max-width: 80%;
}

.max-w-85 {
  max-width: 85%;
}

.max-w-90 {
  max-width: 90%;
}

.max-w-95 {
  max-width: 95%;
}

.max-w-100 {
  max-width: 100%;
}

.max-w-1vw {
  max-width: 1vw;
}

.max-w-2vw {
  max-width: 2vw;
}

.max-w-3vw {
  max-width: 3vw;
}

.max-w-4vw {
  max-width: 4vw;
}

.max-w-5vw {
  max-width: 5vw;
}

.max-w-10vw {
  max-width: 10vw;
}

.max-w-15vw {
  max-width: 15vw;
}

.max-w-20vw {
  max-width: 20vw;
}

.max-w-25vw {
  max-width: 25vw;
}

.max-w-30vw {
  max-width: 30vw;
}

.max-w-35vw {
  max-width: 35vw;
}

.max-w-40vw {
  max-width: 40vw;
}

.max-w-45vw {
  max-width: 45vw;
}

.max-w-50vw {
  max-width: 50vw;
}

.max-w-55vw {
  max-width: 55vw;
}

.max-w-60vw {
  max-width: 60vw;
}

.max-w-65vw {
  max-width: 65vw;
}

.max-w-70vw {
  max-width: 70vw;
}

.max-w-75vw {
  max-width: 75vw;
}

.max-w-80vw {
  max-width: 80vw;
}

.max-w-85vw {
  max-width: 85vw;
}

.max-w-90vw {
  max-width: 90vw;
}

.max-w-95vw {
  max-width: 95vw;
}

.max-w-100vw {
  max-width: 100vw;
}

/*

██   ██ ███████ ██  ██████  ██   ██ ████████
██   ██ ██      ██ ██       ██   ██    ██
███████ █████   ██ ██   ███ ███████    ██
██   ██ ██      ██ ██    ██ ██   ██    ██
██   ██ ███████ ██  ██████  ██   ██    ██

*/
/* Sintax and specials height */
.h-inh {
  height: inherit;
}
.h-a {
  height: auto;
}
.h-auto {
  height: auto;
}

.height-auto {
  height: auto;
}

.height-inherit {
  height: inherit;
}

.h-12-5 {
  height: 12.5%;
  /* 100 / 8 = 12.5 */
}

/* Height px - pt - % - vw - vh */
.h-1px {
  height: 1px;
}

.h-2px {
  height: 2px;
}

.h-3px {
  height: 3px;
}

.h-4px {
  height: 4px;
}

.h-5px {
  height: 5px;
}

.h-10px {
  height: 10px;
}

.h-15px {
  height: 15px;
}

.h-20px {
  height: 20px;
}

.h-25px {
  height: 25px;
}

.h-30px {
  height: 30px;
}

.h-35px {
  height: 35px;
}

.h-40px {
  height: 40px;
}

.h-45px {
  height: 45px;
}

.h-50px {
  height: 50px;
}

.h-55px {
  height: 55px;
}

.h-60px {
  height: 60px;
}

.h-65px {
  height: 65px;
}

.h-70px {
  height: 70px;
}

.h-75px {
  height: 75px;
}

.h-80px {
  height: 80px;
}

.h-85px {
  height: 85px;
}

.h-90px {
  height: 90px;
}

.h-95px {
  height: 95px;
}

.h-100px {
  height: 100px;
}

.h-125px {
  height: 125px;
}

.h-150px {
  height: 150px;
}

.h-175px {
  height: 175px;
}

.h-200px {
  height: 200px;
}

.h-225px {
  height: 225px;
}

.h-250px {
  height: 250px;
}

.h-275px {
  height: 275px;
}

.h-300px {
  height: 300px;
}

.h-325px {
  height: 325px;
}

.h-350px {
  height: 350px;
}

.h-375px {
  height: 375px;
}

.h-400px {
  height: 400px;
}

.h-425px {
  height: 425px;
}

.h-450px {
  height: 450px;
}

.h-475px {
  height: 475px;
}

.h-500px {
  height: 500px;
}
.h-600px {
  height: 600px;
}
.h-700px {
  height: 700px;
}
.h-800px {
  height: 800px;
}
.h-900px {
  height: 900px;
}
.h-1000px {
  height: 1000px;
}

.h-1pt {
  height: 1pt;
}

.h-2pt {
  height: 2pt;
}

.h-3pt {
  height: 3pt;
}

.h-4pt {
  height: 4pt;
}

.h-5pt {
  height: 5pt;
}

.h-10pt {
  height: 10pt;
}

.h-15pt {
  height: 15pt;
}

.h-20pt {
  height: 20pt;
}

.h-25pt {
  height: 25pt;
}

.h-30pt {
  height: 30pt;
}

.h-35pt {
  height: 35pt;
}

.h-40pt {
  height: 40pt;
}

.h-45pt {
  height: 45pt;
}

.h-50pt {
  height: 50pt;
}

.h-55pt {
  height: 55pt;
}

.h-60pt {
  height: 60pt;
}

.h-65pt {
  height: 65pt;
}

.h-70pt {
  height: 70pt;
}

.h-75pt {
  height: 75pt;
}

.h-80pt {
  height: 80pt;
}

.h-85pt {
  height: 85pt;
}

.h-90pt {
  height: 90pt;
}

.h-95pt {
  height: 95pt;
}

.h-100pt {
  height: 100pt;
}

.h-125pt {
  height: 125pt;
}

.h-150pt {
  height: 150pt;
}

.h-175pt {
  height: 175pt;
}

.h-200pt {
  height: 200pt;
}

.h-225pt {
  height: 225pt;
}

.h-250pt {
  height: 250pt;
}

.h-275pt {
  height: 275pt;
}

.h-300pt {
  height: 300pt;
}

.h-325pt {
  height: 325pt;
}

.h-350pt {
  height: 350pt;
}

.h-375pt {
  height: 375pt;
}

.h-400pt {
  height: 400pt;
}

.h-425pt {
  height: 425pt;
}

.h-450pt {
  height: 450pt;
}

.h-475pt {
  height: 475pt;
}

.h-500pt {
  height: 500pt;
}

.h-1000pt {
  height: 1000pt;
}

.h-1 {
  height: 1%;
}

.h-2 {
  height: 2%;
}

.h-3 {
  height: 3%;
}

.h-4 {
  height: 4%;
}

.h-5 {
  height: 5%;
}

.h-10 {
  height: 10%;
}

.h-15 {
  height: 15%;
}

.h-20 {
  height: 20%;
}

.h-25 {
  height: 25%;
}

.h-30 {
  height: 30%;
}

.h-35 {
  height: 35%;
}

.h-40 {
  height: 40%;
}

.h-45 {
  height: 45%;
}

.h-50 {
  height: 50%;
}

.h-55 {
  height: 55%;
}

.h-60 {
  height: 60%;
}

.h-65 {
  height: 65%;
}

.h-70 {
  height: 70%;
}

.h-75 {
  height: 75%;
}

.h-80 {
  height: 80%;
}

.h-85 {
  height: 85%;
}

.h-90 {
  height: 90%;
}

.h-95 {
  height: 95%;
}

.h-100 {
  height: 100%;
}

.h-1vh {
  height: 1vh;
}

.h-2vh {
  height: 2vh;
}

.h-3vh {
  height: 3vh;
}

.h-4vh {
  height: 4vh;
}

.h-5vh {
  height: 5vh;
}

.h-10vh {
  height: 10vh;
}

.h-15vh {
  height: 15vh;
}

.h-20vh {
  height: 20vh;
}

.h-25vh {
  height: 25vh;
}

.h-30vh {
  height: 30vh;
}

.h-35vh {
  height: 35vh;
}

.h-40vh {
  height: 40vh;
}

.h-45vh {
  height: 45vh;
}

.h-50vh {
  height: 50vh;
}

.h-55vh {
  height: 55vh;
}

.h-60vh {
  height: 60vh;
}

.h-65vh {
  height: 65vh;
}

.h-70vh {
  height: 70vh;
}

.h-75vh {
  height: 75vh;
}

.h-80vh {
  height: 80vh;
}

.h-85vh {
  height: 85vh;
}

.h-90vh {
  height: 90vh;
}

.h-95vh {
  height: 95vh;
}

.h-100vh {
  height: 100Svh;
}
.m-t{
  margin-top:115px;
}

/* Max Height px - pt - % - vw - vh */
.max-h-1px {
  max-height: 1px;
}

.max-h-2px {
  max-height: 2px;
}

.max-h-3px {
  max-height: 3px;
}

.max-h-4px {
  max-height: 4px;
}

.max-h-5px {
  max-height: 5px;
}

.max-h-10px {
  max-height: 10px;
}

.max-h-15px {
  max-height: 15px;
}

.max-h-20px {
  max-height: 20px;
}

.max-h-25px {
  max-height: 25px;
}

.max-h-30px {
  max-height: 30px;
}

.max-h-35px {
  max-height: 35px;
}

.max-h-40px {
  max-height: 40px;
}

.max-h-45px {
  max-height: 45px;
}

.max-h-50px {
  max-height: 50px;
}

.max-h-55px {
  max-height: 55px;
}

.max-h-60px {
  max-height: 60px;
}

.max-h-65px {
  max-height: 65px;
}

.max-h-70px {
  max-height: 70px;
}

.max-h-75px {
  max-height: 75px;
}

.max-h-80px {
  max-height: 80px;
}

.max-h-85px {
  max-height: 85px;
}

.max-h-90px {
  max-height: 90px;
}

.max-h-95px {
  max-height: 95px;
}

.max-h-100px {
  max-height: 100px;
}

.max-h-125px {
  max-height: 125px;
}

.max-h-150px {
  max-height: 150px;
}

.max-h-175px {
  max-height: 175px;
}

.max-h-200px {
  max-height: 200px;
}

.max-h-225px {
  max-height: 225px;
}

.max-h-250px {
  max-height: 250px;
}

.max-h-275px {
  max-height: 275px;
}

.max-h-300px {
  max-height: 300px;
}

.max-h-325px {
  max-height: 325px;
}

.max-h-350px {
  max-height: 350px;
}

.max-h-375px {
  max-height: 375px;
}

.max-h-400px {
  max-height: 400px;
}

.max-h-425px {
  max-height: 425px;
}

.max-h-450px {
  max-height: 450px;
}

.max-h-475px {
  max-height: 475px;
}

.max-h-500px {
  max-height: 500px;
}

.max-h-1000px {
  max-height: 1000px;
}

.max-h-1pt {
  max-height: 1pt;
}

.max-h-2pt {
  max-height: 2pt;
}

.max-h-3pt {
  max-height: 3pt;
}

.max-h-4pt {
  max-height: 4pt;
}

.max-h-5pt {
  max-height: 5pt;
}

.max-h-10pt {
  max-height: 10pt;
}

.max-h-15pt {
  max-height: 15pt;
}

.max-h-20pt {
  max-height: 20pt;
}

.max-h-25pt {
  max-height: 25pt;
}

.max-h-30pt {
  max-height: 30pt;
}

.max-h-35pt {
  max-height: 35pt;
}

.max-h-40pt {
  max-height: 40pt;
}

.max-h-45pt {
  max-height: 45pt;
}

.max-h-50pt {
  max-height: 50pt;
}

.max-h-55pt {
  max-height: 55pt;
}

.max-h-60pt {
  max-height: 60pt;
}

.max-h-65pt {
  max-height: 65pt;
}

.max-h-70pt {
  max-height: 70pt;
}

.max-h-75pt {
  max-height: 75pt;
}

.max-h-80pt {
  max-height: 80pt;
}

.max-h-85pt {
  max-height: 85pt;
}

.max-h-90pt {
  max-height: 90pt;
}

.max-h-95pt {
  max-height: 95pt;
}

.max-h-100pt {
  max-height: 100pt;
}

.max-h-125pt {
  max-height: 125pt;
}

.max-h-150pt {
  max-height: 150pt;
}

.max-h-175pt {
  max-height: 175pt;
}

.max-h-200pt {
  max-height: 200pt;
}

.max-h-225pt {
  max-height: 225pt;
}

.max-h-250pt {
  max-height: 250pt;
}

.max-h-275pt {
  max-height: 275pt;
}

.max-h-300pt {
  max-height: 300pt;
}

.max-h-325pt {
  max-height: 325pt;
}

.max-h-350pt {
  max-height: 350pt;
}

.max-h-375pt {
  max-height: 375pt;
}

.max-h-400pt {
  max-height: 400pt;
}

.max-h-425pt {
  max-height: 425pt;
}

.max-h-450pt {
  max-height: 450pt;
}

.max-h-475pt {
  max-height: 475pt;
}

.max-h-500pt {
  max-height: 500pt;
}

.max-h-1000pt {
  max-height: 1000pt;
}

.max-h-1 {
  max-height: 1%;
}

.max-h-2 {
  max-height: 2%;
}

.max-h-3 {
  max-height: 3%;
}

.max-h-4 {
  max-height: 4%;
}

.max-h-5 {
  max-height: 5%;
}

.max-h-10 {
  max-height: 10%;
}

.max-h-15 {
  max-height: 15%;
}

.max-h-20 {
  max-height: 20%;
}

.max-h-25 {
  max-height: 25%;
}

.max-h-30 {
  max-height: 30%;
}

.max-h-35 {
  max-height: 35%;
}

.max-h-40 {
  max-height: 40%;
}

.max-h-45 {
  max-height: 45%;
}

.max-h-50 {
  max-height: 50%;
}

.max-h-55 {
  max-height: 55%;
}

.max-h-60 {
  max-height: 60%;
}

.max-h-65 {
  max-height: 65%;
}

.max-h-70 {
  max-height: 70%;
}

.max-h-75 {
  max-height: 75%;
}

.max-h-80 {
  max-height: 80%;
}

.max-h-85 {
  max-height: 85%;
}

.max-h-90 {
  max-height: 90%;
}

.max-h-95 {
  max-height: 95%;
}

.max-h-100 {
  max-height: 100%;
}

.max-h-1vh {
  max-height: 1vh;
}

.max-h-2vh {
  max-height: 2vh;
}

.max-h-3vh {
  max-height: 3vh;
}

.max-h-4vh {
  max-height: 4vh;
}

.max-h-5vh {
  max-height: 5vh;
}

.max-h-10vh {
  max-height: 10vh;
}

.max-h-15vh {
  max-height: 15vh;
}

.max-h-20vh {
  max-height: 20vh;
}

.max-h-25vh {
  max-height: 25vh;
}

.max-h-30vh {
  max-height: 30vh;
}

.max-h-35vh {
  max-height: 35vh;
}

.max-h-40vh {
  max-height: 40vh;
}

.max-h-45vh {
  max-height: 45vh;
}

.max-h-50vh {
  max-height: 50vh;
}

.max-h-55vh {
  max-height: 55vh;
}

.max-h-60vh {
  max-height: 60vh;
}

.max-h-65vh {
  max-height: 65vh;
}

.max-h-70vh {
  max-height: 70vh;
}

.max-h-75vh {
  max-height: 75vh;
}

.max-h-80vh {
  max-height: 80vh;
}

.max-h-85vh {
  max-height: 85vh;
}

.max-h-90vh {
  max-height: 90vh;
}

.max-h-95vh {
  max-height: 95vh;
}

.max-h-100vh {
  max-height: 100vh;
}

/* Min Height px - pt - % - vw - vh */
.min-h-1px {
  min-height: 1px;
}

.min-h-2px {
  min-height: 2px;
}

.min-h-3px {
  min-height: 3px;
}

.min-h-4px {
  min-height: 4px;
}

.min-h-5px {
  min-height: 5px;
}

.min-h-10px {
  min-height: 10px;
}

.min-h-15px {
  min-height: 15px;
}

.min-h-20px {
  min-height: 20px;
}

.min-h-25px {
  min-height: 25px;
}

.min-h-30px {
  min-height: 30px;
}

.min-h-35px {
  min-height: 35px;
}

.min-h-40px {
  min-height: 40px;
}

.min-h-45px {
  min-height: 45px;
}

.min-h-50px {
  min-height: 50px;
}

.min-h-55px {
  min-height: 55px;
}

.min-h-60px {
  min-height: 60px;
}

.min-h-65px {
  min-height: 65px;
}

.min-h-70px {
  min-height: 70px;
}

.min-h-75px {
  min-height: 75px;
}

.min-h-80px {
  min-height: 80px;
}

.min-h-85px {
  min-height: 85px;
}

.min-h-90px {
  min-height: 90px;
}

.min-h-95px {
  min-height: 95px;
}

.min-h-100px {
  min-height: 100px;
}

.min-h-125px {
  min-height: 125px;
}

.min-h-150px {
  min-height: 150px;
}

.min-h-175px {
  min-height: 175px;
}

.min-h-200px {
  min-height: 200px;
}

.min-h-225px {
  min-height: 225px;
}

.min-h-250px {
  min-height: 250px;
}

.min-h-275px {
  min-height: 275px;
}

.min-h-300px {
  min-height: 300px;
}

.min-h-325px {
  min-height: 325px;
}

.min-h-350px {
  min-height: 350px;
}

.min-h-375px {
  min-height: 375px;
}

.min-h-400px {
  min-height: 400px;
}

.min-h-425px {
  min-height: 425px;
}

.min-h-450px {
  min-height: 450px;
}

.min-h-475px {
  min-height: 475px;
}

.min-h-500px {
  min-height: 500px;
}

.min-h-1000px {
  min-height: 1000px;
}

.min-h-1pt {
  min-height: 1pt;
}

.min-h-2pt {
  min-height: 2pt;
}

.min-h-3pt {
  min-height: 3pt;
}

.min-h-4pt {
  min-height: 4pt;
}

.min-h-5pt {
  min-height: 5pt;
}

.min-h-10pt {
  min-height: 10pt;
}

.min-h-15pt {
  min-height: 15pt;
}

.min-h-20pt {
  min-height: 20pt;
}

.min-h-25pt {
  min-height: 25pt;
}

.min-h-30pt {
  min-height: 30pt;
}

.min-h-35pt {
  min-height: 35pt;
}

.min-h-40pt {
  min-height: 40pt;
}

.min-h-45pt {
  min-height: 45pt;
}

.min-h-50pt {
  min-height: 50pt;
}

.min-h-55pt {
  min-height: 55pt;
}

.min-h-60pt {
  min-height: 60pt;
}

.min-h-65pt {
  min-height: 65pt;
}

.min-h-70pt {
  min-height: 70pt;
}

.min-h-75pt {
  min-height: 75pt;
}

.min-h-80pt {
  min-height: 80pt;
}

.min-h-85pt {
  min-height: 85pt;
}

.min-h-90pt {
  min-height: 90pt;
}

.min-h-95pt {
  min-height: 95pt;
}

.min-h-100pt {
  min-height: 100pt;
}

.min-h-125pt {
  min-height: 125pt;
}

.min-h-150pt {
  min-height: 150pt;
}

.min-h-175pt {
  min-height: 175pt;
}

.min-h-200pt {
  min-height: 200pt;
}

.min-h-225pt {
  min-height: 225pt;
}

.min-h-250pt {
  min-height: 250pt;
}

.min-h-275pt {
  min-height: 275pt;
}

.min-h-300pt {
  min-height: 300pt;
}

.min-h-325pt {
  min-height: 325pt;
}

.min-h-350pt {
  min-height: 350pt;
}

.min-h-375pt {
  min-height: 375pt;
}

.min-h-400pt {
  min-height: 400pt;
}

.min-h-425pt {
  min-height: 425pt;
}

.min-h-450pt {
  min-height: 450pt;
}

.min-h-475pt {
  min-height: 475pt;
}

.min-h-500pt {
  min-height: 500pt;
}

.min-h-1000pt {
  min-height: 1000pt;
}

.min-h-1 {
  min-height: 1%;
}

.min-h-2 {
  min-height: 2%;
}

.min-h-3 {
  min-height: 3%;
}

.min-h-4 {
  min-height: 4%;
}

.min-h-5 {
  min-height: 5%;
}

.min-h-10 {
  min-height: 10%;
}

.min-h-15 {
  min-height: 15%;
}

.min-h-20 {
  min-height: 20%;
}

.min-h-25 {
  min-height: 25%;
}

.min-h-30 {
  min-height: 30%;
}

.min-h-35 {
  min-height: 35%;
}

.min-h-40 {
  min-height: 40%;
}

.min-h-45 {
  min-height: 45%;
}

.min-h-50 {
  min-height: 50%;
}

.min-h-55 {
  min-height: 55%;
}

.min-h-60 {
  min-height: 60%;
}

.min-h-65 {
  min-height: 65%;
}

.min-h-70 {
  min-height: 70%;
}

.min-h-75 {
  min-height: 75%;
}

.min-h-80 {
  min-height: 80%;
}

.min-h-85 {
  min-height: 85%;
}

.min-h-90 {
  min-height: 90%;
}

.min-h-95 {
  min-height: 95%;
}

.min-h-100 {
  min-height: 100%;
}

.min-h-1vh {
  min-height: 1vh;
}

.min-h-2vh {
  min-height: 2vh;
}

.min-h-3vh {
  min-height: 3vh;
}

.min-h-4vh {
  min-height: 4vh;
}

.min-h-5vh {
  min-height: 5vh;
}

.min-h-10vh {
  min-height: 10vh;
}

.min-h-15vh {
  min-height: 15vh;
}

.min-h-20vh {
  min-height: 20vh;
}

.min-h-25vh {
  min-height: 25vh;
}

.min-h-30vh {
  min-height: 30vh;
}

.min-h-35vh {
  min-height: 35vh;
}

.min-h-40vh {
  min-height: 40vh;
}

.min-h-45vh {
  min-height: 45vh;
}

.min-h-50vh {
  min-height: 50vh;
}

.min-h-55vh {
  min-height: 55vh;
}

.min-h-60vh {
  min-height: 60vh;
}

.min-h-65vh {
  min-height: 65vh;
}

.min-h-70vh {
  min-height: 70vh;
}

.min-h-75vh {
  min-height: 75vh;
}

.min-h-80vh {
  min-height: 80vh;
}

.min-h-85vh {
  min-height: 85vh;
}

.min-h-90vh {
  min-height: 90vh;
}

.min-h-95vh {
  min-height: 95vh;
}

.min-h-100vh {
  min-height: 100vh;
}

/*

██     ██ ██ ██████  ████████ ██   ██      █████  ███    ██ ██████      ██   ██ ███████ ██  ██████  ██   ██ ████████
██     ██ ██ ██   ██    ██    ██   ██     ██   ██ ████   ██ ██   ██     ██   ██ ██      ██ ██       ██   ██    ██
██  █  ██ ██ ██   ██    ██    ███████     ███████ ██ ██  ██ ██   ██     ███████ █████   ██ ██   ███ ███████    ██
██ ███ ██ ██ ██   ██    ██    ██   ██     ██   ██ ██  ██ ██ ██   ██     ██   ██ ██      ██ ██    ██ ██   ██    ██
 ███ ███  ██ ██████     ██    ██   ██     ██   ██ ██   ████ ██████      ██   ██ ███████ ██  ██████  ██   ██    ██

*/
/* Sintax and specials width and height */
.w-and-h-inh {
  width: inherit;
  height: inherit;
}

.w-and-h-a {
  width: auto;
  height: auto;
}

.w-and-h-auto {
  width: auto;
  height: auto;
}

.w-and-h-inherit {
  width: inherit;
  height: inherit;
}

/* Width and Height px */
.w-and-h-1px {
  width: 1px;
  height: 1px;
}

.w-and-h-2px {
  width: 2px;
  height: 2px;
}

.w-and-h-3px {
  width: 3px;
  height: 3px;
}

.w-and-h-4px {
  width: 4px;
  height: 4px;
}

.w-and-h-5px {
  width: 5px;
  height: 5px;
}

.w-and-h-10px {
  width: 10px;
  height: 10px;
}

.w-and-h-15px {
  width: 15px;
  height: 15px;
}

.w-and-h-20px {
  width: 20px;
  height: 20px;
}

.w-and-h-25px {
  width: 25px;
  height: 25px;
}

.w-and-h-30px {
  width: 30px;
  height: 30px;
}

.w-and-h-35px {
  width: 35px;
  height: 35px;
}

.w-and-h-40px {
  width: 40px;
  height: 40px;
}

.w-and-h-45px {
  width: 45px;
  height: 45px;
}

.w-and-h-50px {
  width: 50px;
  height: 50px;
}

.w-and-h-55px {
  width: 55px;
  height: 55px;
}

.w-and-h-60px {
  width: 60px;
  height: 60px;
}

.w-and-h-65px {
  width: 65px;
  height: 65px;
}

.w-and-h-70px {
  width: 70px;
  height: 70px;
}

.w-and-h-75px {
  width: 75px;
  height: 75px;
}

.w-and-h-80px {
  width: 80px;
  height: 80px;
}

.w-and-h-85px {
  width: 85px;
  height: 85px;
}

.w-and-h-90px {
  width: 90px;
  height: 90px;
}

.w-and-h-95px {
  width: 95px;
  height: 95px;
}

.w-and-h-100px {
  width: 100px;
  height: 100px;
}

.w-and-h-125px {
  width: 125px;
  height: 125px;
}

.w-and-h-150px {
  width: 150px;
  height: 150px;
}

.w-and-h-175px {
  width: 175px;
  height: 175px;
}

.w-and-h-200px {
  width: 200px;
  height: 200px;
}

.w-and-h-225px {
  width: 225px;
  height: 225px;
}

.w-and-h-250px {
  width: 250px;
  height: 250px;
}

.w-and-h-275px {
  width: 275px;
  height: 275px;
}

.w-and-h-300px {
  width: 300px;
  height: 300px;
}

.w-and-h-325px {
  width: 325px;
  height: 325px;
}

.w-and-h-350px {
  width: 350px;
  height: 350px;
}

.w-and-h-375px {
  width: 375px;
  height: 375px;
}

.w-and-h-400px {
  width: 400px;
  height: 400px;
}

.w-and-h-425px {
  width: 425px;
  height: 425px;
}

.w-and-h-450px {
  width: 450px;
  height: 450px;
}

.w-and-h-475px {
  width: 475px;
  height: 475px;
}

.w-and-h-500px {
  width: 500px;
  height: 500px;
}

.w-and-h-1000px {
  width: 1000px;
  height: 1000px;
}

/* Width and Height % */
.w-and-h-1 {
  width: 1%;
  height: 1%;
}

.w-and-h-2 {
  width: 2%;
  height: 2%;
}

.w-and-h-3 {
  width: 3%;
  height: 3%;
}

.w-and-h-4 {
  width: 4%;
  height: 4%;
}

.w-and-h-5 {
  width: 5%;
  height: 5%;
}

.w-and-h-10 {
  width: 10%;
  height: 10%;
}

.w-and-h-15 {
  width: 15%;
  height: 15%;
}

.w-and-h-20 {
  width: 20%;
  height: 20%;
}

.w-and-h-25 {
  width: 25%;
  height: 25%;
}

.w-and-h-30 {
  width: 30%;
  height: 30%;
}

.w-and-h-35 {
  width: 35%;
  height: 35%;
}

.w-and-h-40 {
  width: 40%;
  height: 40%;
}

.w-and-h-45 {
  width: 45%;
  height: 45%;
}

.w-and-h-50 {
  width: 50%;
  height: 50%;
}

.w-and-h-55 {
  width: 55%;
  height: 55%;
}

.w-and-h-60 {
  width: 60%;
  height: 60%;
}

.w-and-h-65 {
  width: 65%;
  height: 65%;
}

.w-and-h-70 {
  width: 70%;
  height: 70%;
}

.w-and-h-75 {
  width: 75%;
  height: 75%;
}

.w-and-h-80 {
  width: 80%;
  height: 80%;
}

.w-and-h-85 {
  width: 85%;
  height: 85%;
}

.w-and-h-90 {
  width: 90%;
  height: 90%;
}

.w-and-h-95 {
  width: 95%;
  height: 95%;
}

.w-and-h-100 {
  width: 100%;
  height: 100%;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .notifier{
    width: 100%;
    bottom: 70px;
    left: 0px;
    right: 0px;
    margin: auto;
  }

/* Specials */
.m-w-and-h-300px {
  width: 300px;
  height: 300px;
}

  /*
  ▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
  ██ ███ █▄ ▄██ ▄▄▀█▄▄ ▄▄██ ██ ██
  ██ █ █ ██ ███ ██ ███ ████ ▄▄ ██
  ██▄▀▄▀▄█▀ ▀██ ▀▀ ███ ████ ██ ██
  ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  */
  /* Sintax and specials mobile width */
  .m-w-inh {
    width: inherit;
  }
  .m-w-auto {
    width: auto;
  }
  .m-width-auto {
    width: auto;
  }
  .m-width-inherit {
    width: inherit;
  }
  /* Width px - pt - % - vw - vh */
  .m-w-1px {
    width: 1px;
  }
  .m-w-2px {
    width: 2px;
  }
  .m-w-3px {
    width: 3px;
  }
  .m-w-4px {
    width: 4px;
  }
  .m-w-5px {
    width: 5px;
  }
  .m-w-10px {
    width: 10px;
  }
  .m-w-15px {
    width: 15px;
  }
  .m-w-20px {
    width: 20px;
  }
  .m-w-25px {
    width: 25px;
  }
  .m-w-30px {
    width: 30px;
  }
  .m-w-35px {
    width: 35px;
  }
  .m-w-40px {
    width: 40px;
  }
  .m-w-45px {
    width: 45px;
  }
  .m-w-50px {
    width: 50px;
  }
  .m-w-55px {
    width: 55px;
  }
  .m-w-60px {
    width: 60px;
  }
  .m-w-65px {
    width: 65px;
  }
  .m-w-70px {
    width: 70px;
  }
  .m-w-75px {
    width: 75px;
  }
  .m-w-80px {
    width: 80px ;
  }
  .m-w-85px {
    width: 85px;
  }
  .m-w-90px {
    width: 90px;
  }
  .m-w-95px {
    width: 95px;
  }
  .m-w-100px {
    width: 100px;
  }
  .m-w-125px {
    width: 125px;
  }
  .m-w-150px {
    width: 150px;
  }
  .m-w-175px {
    width: 175px;
  }
  .m-w-200px {
    width: 200px;
  }
  .m-w-225px {
    width: 225px;
  }
  .m-w-250px {
    width: 250px;
  }
  .m-w-275px {
    width: 275px;
  }
  .m-w-300px {
    width: 300px;
  }
  .m-w-325px {
    width: 325px;
  }
  .m-w-350px {
    width: 350px;
  }
  .m-w-375px {
    width: 375px;
  }
  .m-w-400px {
    width: 400px;
  }
  .m-w-425px {
    width: 425px;
  }
  .m-w-450px {
    width: 450px;
  }
  .m-w-475px {
    width: 475px;
  }
  .m-w-500px {
    width: 500px;
  }
  .m-w-1000px {
    width: 1000px;
  }
  .m-w-1pt {
    width: 1pt;
  }
  .m-w-2pt {
    width: 2pt;
  }
  .m-w-3pt {
    width: 3pt;
  }
  .m-w-4pt {
    width: 4pt;
  }
  .m-w-5pt {
    width: 5pt;
  }
  .m-w-10pt {
    width: 10pt;
  }
  .m-w-15pt {
    width: 15pt;
  }
  .m-w-20pt {
    width: 20pt;
  }
  .m-w-25pt {
    width: 25pt;
  }
  .m-w-30pt {
    width: 30pt;
  }
  .m-w-35pt {
    width: 35pt;
  }
  .m-w-40pt {
    width: 40pt;
  }
  .m-w-45pt {
    width: 45pt;
  }
  .m-w-50pt {
    width: 50pt;
  }
  .m-w-55pt {
    width: 55pt;
  }
  .m-w-60pt {
    width: 60pt;
  }
  .m-w-65pt {
    width: 65pt;
  }
  .m-w-70pt {
    width: 70pt;
  }
  .m-w-75pt {
    width: 75pt;
  }
  .m-w-80pt {
    width: 80pt;
  }
  .m-w-85pt {
    width: 85pt;
  }
  .m-w-90pt {
    width: 90pt;
  }
  .m-w-95pt {
    width: 95pt;
  }
  .m-w-100pt {
    width: 100pt;
  }
  .m-w-125pt {
    width: 125pt;
  }
  .m-w-150pt {
    width: 150pt;
  }
  .m-w-175pt {
    width: 175pt;
  }
  .m-w-200pt {
    width: 200pt;
  }
  .m-w-225pt {
    width: 225pt;
  }
  .m-w-250pt {
    width: 250pt;
  }
  .m-w-275pt {
    width: 275pt;
  }
  .m-w-300pt {
    width: 300pt;
  }
  .m-w-325pt {
    width: 325pt;
  }
  .m-w-350pt {
    width: 350pt;
  }
  .m-w-375pt {
    width: 375pt;
  }
  .m-w-400pt {
    width: 400pt;
  }
  .m-w-425pt {
    width: 425pt;
  }
  .m-w-450pt {
    width: 450pt;
  }
  .m-w-475pt {
    width: 475pt;
  }
  .m-w-500pt {
    width: 500pt;
  }
  .m-w-1000pt {
    width: 1000pt;
  }
  .m-w-1 {
    width: 1%;
  }
  .m-w-2 {
    width: 2%;
  }
  .m-w-3 {
    width: 3%;
  }
  .m-w-4 {
    width: 4%;
  }
  .m-w-5 {
    width: 5%;
  }
  .m-w-10 {
    width: 10%;
  }
  .m-w-15 {
    width: 15%;
  }
  .m-w-20 {
    width: 20%;
  }
  .m-w-25 {
    width: 25%;
  }
  .m-w-30 {
    width: 30%;
  }
  .m-w-33 {
    width: 33.3333% !important;
  }
  .m-w-35 {
    width: 35%;
  }
  .m-w-40 {
    width: 40% !important;
  }
  .m-w-45 {
    width: 45%;
  }
  .m-w-50 {
    width: 50%;
  }
  .m-w-55 {
    width: 55%;
  }
  .m-w-60 {
    width: 60%;
  }
  .m-w-65 {
    width: 65%;
  }
  .m-w-70 {
    width: 70%;
  }
  .m-w-75 {
    width: 75%;
  }
  .m-w-80 {
    width: 80%;
  }
  .m-w-85 {
    width: 85%;
  }
  .m-w-90 {
    width: 90%;
  }
  .m-w-95 {
    width: 95%;
  }
  .m-w-100 {
    width: 100% !important;
  }
  .m-w-1vw {
    width: 1vw;
  }
  .m-w-2vw {
    width: 2vw;
  }
  .m-w-3vw {
    width: 3vw;
  }
  .m-w-4vw {
    width: 4vw;
  }
  .m-w-5vw {
    width: 5vw;
  }
  .m-w-10vw {
    width: 10vw;
  }
  .m-w-15vw {
    width: 15vw;
  }
  .m-w-20vw {
    width: 20vw;
  }
  .m-w-25vw {
    width: 25vw;
  }
  .m-w-30vw {
    width: 30vw;
  }
  .m-w-35vw {
    width: 35vw;
  }
  .m-w-40vw {
    width: 40vw;
  }
  .m-w-45vw {
    width: 45vw;
  }
  .m-w-50vw {
    width: 50vw;
  }
  .m-w-55vw {
    width: 55vw;
  }
  .m-w-60vw {
    width: 60vw;
  }
  .m-w-65vw {
    width: 65vw;
  }
  .m-w-70vw {
    width: 70vw;
  }
  .m-w-75vw {
    width: 75vw;
  }
  .m-w-80vw {
    width: 80vw;
  }
  .m-w-85vw {
    width: 85vw;
  }
  .m-w-90vw {
    width: 90vw;
  }
  .m-w-95vw {
    width: 95vw;
  }
  .m-w-100vw {
    width: 100vw;
  }
  /* Min Width px - pt - % - vw - vh */
  .m-min-w-1px {
    min-width: 1px;
  }
  .m-min-w-2px {
    min-width: 2px;
  }
  .m-min-w-3px {
    min-width: 3px;
  }
  .m-min-w-4px {
    min-width: 4px;
  }
  .m-min-w-5px {
    min-width: 5px;
  }
  .m-min-w-10px {
    min-width: 10px;
  }
  .m-min-w-15px {
    min-width: 15px;
  }
  .m-min-w-20px {
    min-width: 20px;
  }
  .m-min-w-25px {
    min-width: 25px;
  }
  .m-min-w-30px {
    min-width: 30px;
  }
  .m-min-w-35px {
    min-width: 35px;
  }
  .m-min-w-40px {
    min-width: 40px;
  }
  .m-min-w-45px {
    min-width: 45px;
  }
  .m-min-w-50px {
    min-width: 50px;
  }
  .m-min-w-55px {
    min-width: 55px;
  }
  .m-min-w-60px {
    min-width: 60px;
  }
  .m-min-w-65px {
    min-width: 65px;
  }
  .m-min-w-70px {
    min-width: 70px;
  }
  .m-min-w-75px {
    min-width: 75px;
  }
  .m-min-w-80px {
    min-width: 80px;
  }
  .m-min-w-85px {
    min-width: 85px;
  }
  .m-min-w-90px {
    min-width: 90px;
  }
  .m-min-w-95px {
    min-width: 95px;
  }
  .m-min-w-100px {
    min-width: 100px;
  }
  .m-min-w-125px {
    min-width: 125px;
  }
  .m-min-w-150px {
    min-width: 150px;
  }
  .m-min-w-175px {
    min-width: 175px;
  }
  .m-min-w-200px {
    min-width: 200px;
  }
  .m-min-w-225px {
    min-width: 225px;
  }
  .m-min-w-250px {
    min-width: 250px;
  }
  .m-min-w-275px {
    min-width: 275px;
  }
  .m-min-w-300px {
    min-width: 300px;
  }
  .m-min-w-325px {
    min-width: 325px;
  }
  .m-min-w-350px {
    min-width: 350px;
  }
  .m-min-w-375px {
    min-width: 375px;
  }
  .m-min-w-400px {
    min-width: 400px;
  }
  .m-min-w-425px {
    min-width: 425px;
  }
  .m-min-w-450px {
    min-width: 450px;
  }
  .m-min-w-475px {
    min-width: 475px;
  }
  .m-min-w-500px {
    min-width: 500px;
  }
  .m-min-w-1000px {
    min-width: 1000px;
  }
  .m-min-w-1pt {
    min-width: 1pt;
  }
  .m-min-w-2pt {
    min-width: 2pt;
  }
  .m-min-w-3pt {
    min-width: 3pt;
  }
  .m-min-w-4pt {
    min-width: 4pt;
  }
  .m-min-w-5pt {
    min-width: 5pt;
  }
  .m-min-w-10pt {
    min-width: 10pt;
  }
  .m-min-w-15pt {
    min-width: 15pt;
  }
  .m-min-w-20pt {
    min-width: 20pt;
  }
  .m-min-w-25pt {
    min-width: 25pt;
  }
  .m-min-w-30pt {
    min-width: 30pt;
  }
  .m-min-w-35pt {
    min-width: 35pt;
  }
  .m-min-w-40pt {
    min-width: 40pt;
  }
  .m-min-w-45pt {
    min-width: 45pt;
  }
  .m-min-w-50pt {
    min-width: 50pt;
  }
  .m-min-w-55pt {
    min-width: 55pt;
  }
  .m-min-w-60pt {
    min-width: 60pt;
  }
  .m-min-w-65pt {
    min-width: 65pt;
  }
  .m-min-w-70pt {
    min-width: 70pt;
  }
  .m-min-w-75pt {
    min-width: 75pt;
  }
  .m-min-w-80pt {
    min-width: 80pt;
  }
  .m-min-w-85pt {
    min-width: 85pt;
  }
  .m-min-w-90pt {
    min-width: 90pt;
  }
  .m-min-w-95pt {
    min-width: 95pt;
  }
  .m-min-w-100pt {
    min-width: 100pt;
  }
  .m-min-w-125pt {
    min-width: 125pt;
  }
  .m-min-w-150pt {
    min-width: 150pt;
  }
  .m-min-w-175pt {
    min-width: 175pt;
  }
  .m-min-w-200pt {
    min-width: 200pt;
  }
  .m-min-w-225pt {
    min-width: 225pt;
  }
  .m-min-w-250pt {
    min-width: 250pt;
  }
  .m-min-w-275pt {
    min-width: 275pt;
  }
  .m-min-w-300pt {
    min-width: 300pt;
  }
  .m-min-w-325pt {
    min-width: 325pt;
  }
  .m-min-w-350pt {
    min-width: 350pt;
  }
  .m-min-w-375pt {
    min-width: 375pt;
  }
  .m-min-w-400pt {
    min-width: 400pt;
  }
  .m-min-w-425pt {
    min-width: 425pt;
  }
  .m-min-w-450pt {
    min-width: 450pt;
  }
  .m-min-w-475pt {
    min-width: 475pt;
  }
  .m-min-w-500pt {
    min-width: 500pt;
  }
  .m-min-w-1000pt {
    min-width: 1000pt;
  }
  .m-min-w-1 {
    min-width: 1%;
  }
  .m-min-w-2 {
    min-width: 2%;
  }
  .m-min-w-3 {
    min-width: 3%;
  }
  .m-min-w-4 {
    min-width: 4%;
  }
  .m-min-w-5 {
    min-width: 5%;
  }
  .m-min-w-10 {
    min-width: 10%;
  }
  .m-min-w-15 {
    min-width: 15%;
  }
  .m-min-w-20 {
    min-width: 20%;
  }
  .m-min-w-25 {
    min-width: 25%;
  }
  .m-min-w-30 {
    min-width: 30%;
  }
  .m-min-w-35 {
    min-width: 35%;
  }
  .m-min-w-40 {
    min-width: 40%;
  }
  .m-min-w-45 {
    min-width: 45%;
  }
  .m-min-w-50 {
    min-width: 50%;
  }
  .m-min-w-55 {
    min-width: 55%;
  }
  .m-min-w-60 {
    min-width: 60%;
  }
  .m-min-w-65 {
    min-width: 65%;
  }
  .m-min-w-70 {
    min-width: 70%;
  }
  .m-min-w-75 {
    min-width: 75%;
  }
  .m-min-w-80 {
    min-width: 80%;
  }
  .m-min-w-85 {
    min-width: 85%;
  }
  .m-min-w-90 {
    min-width: 90%;
  }
  .m-min-w-95 {
    min-width: 95%;
  }
  .m-min-w-100 {
    min-width: 100%;
  }
  .m-min-w-1vw {
    min-width: 1vw;
  }
  .m-min-w-2vw {
    min-width: 2vw;
  }
  .m-min-w-3vw {
    min-width: 3vw;
  }
  .m-min-w-4vw {
    min-width: 4vw;
  }
  .m-min-w-5vw {
    min-width: 5vw;
  }
  .m-min-w-10vw {
    min-width: 10vw;
  }
  .m-min-w-15vw {
    min-width: 15vw;
  }
  .m-min-w-20vw {
    min-width: 20vw;
  }
  .m-min-w-25vw {
    min-width: 25vw;
  }
  .m-min-w-30vw {
    min-width: 30vw;
  }
  .m-min-w-35vw {
    min-width: 35vw;
  }
  .m-min-w-40vw {
    min-width: 40vw;
  }
  .m-min-w-45vw {
    min-width: 45vw;
  }
  .m-min-w-50vw {
    min-width: 50vw;
  }
  .m-min-w-55vw {
    min-width: 55vw;
  }
  .m-min-w-60vw {
    min-width: 60vw;
  }
  .m-min-w-65vw {
    min-width: 65vw;
  }
  .m-min-w-70vw {
    min-width: 70vw;
  }
  .m-min-w-75vw {
    min-width: 75vw;
  }
  .m-min-w-80vw {
    min-width: 80vw;
  }
  .m-min-w-85vw {
    min-width: 85vw;
  }
  .m-min-w-90vw {
    min-width: 90vw;
  }
  .m-min-w-95vw {
    min-width: 95vw;
  }
  .m-min-w-100vw {
    min-width: 100vw;
  }
  /* Max Width px - pt - % - vw - vh */
  .m-max-w-1px {
    max-width: 1px;
  }
  .m-max-w-2px {
    max-width: 2px;
  }
  .m-max-w-3px {
    max-width: 3px;
  }
  .m-max-w-4px {
    max-width: 4px;
  }
  .m-max-w-5px {
    max-width: 5px;
  }
  .m-max-w-10px {
    max-width: 10px;
  }
  .m-max-w-15px {
    max-width: 15px;
  }
  .m-max-w-20px {
    max-width: 20px;
  }
  .m-max-w-25px {
    max-width: 25px;
  }
  .m-max-w-30px {
    max-width: 30px;
  }
  .m-max-w-35px {
    max-width: 35px;
  }
  .m-max-w-40px {
    max-width: 40px;
  }
  .m-max-w-45px {
    max-width: 45px;
  }
  .m-max-w-50px {
    max-width: 50px;
  }
  .m-max-w-55px {
    max-width: 55px;
  }
  .m-max-w-60px {
    max-width: 60px;
  }
  .m-max-w-65px {
    max-width: 65px;
  }
  .m-max-w-70px {
    max-width: 70px;
  }
  .m-max-w-75px {
    max-width: 75px;
  }
  .m-max-w-80px {
    max-width: 80px;
  }
  .m-max-w-85px {
    max-width: 85px;
  }
  .m-max-w-90px {
    max-width: 90px;
  }
  .m-max-w-95px {
    max-width: 95px;
  }
  .m-max-w-100px {
    max-width: 100px;
  }
  .m-max-w-125px {
    max-width: 125px;
  }
  .m-max-w-150px {
    max-width: 150px;
  }
  .m-max-w-175px {
    max-width: 175px;
  }
  .m-max-w-200px {
    max-width: 200px;
  }
  .m-max-w-225px {
    max-width: 225px;
  }
  .m-max-w-250px {
    max-width: 250px;
  }
  .m-max-w-275px {
    max-width: 275px;
  }
  .m-max-w-300px {
    max-width: 300px;
  }
  .m-max-w-325px {
    max-width: 325px;
  }
  .m-max-w-350px {
    max-width: 350px;
  }
  .m-max-w-375px {
    max-width: 375px;
  }
  .m-max-w-400px {
    max-width: 400px;
  }
  .m-max-w-425px {
    max-width: 425px;
  }
  .m-max-w-450px {
    max-width: 450px;
  }
  .m-max-w-475px {
    max-width: 475px;
  }
  .m-max-w-500px {
    max-width: 500px;
  }
  .m-max-w-1000px {
    max-width: 1000px;
  }
  .m-max-w-1pt {
    max-width: 1pt;
  }
  .m-max-w-2pt {
    max-width: 2pt;
  }
  .m-max-w-3pt {
    max-width: 3pt;
  }
  .m-max-w-4pt {
    max-width: 4pt;
  }
  .m-max-w-5pt {
    max-width: 5pt;
  }
  .m-max-w-10pt {
    max-width: 10pt;
  }
  .m-max-w-15pt {
    max-width: 15pt;
  }
  .m-max-w-20pt {
    max-width: 20pt;
  }
  .m-max-w-25pt {
    max-width: 25pt;
  }
  .m-max-w-30pt {
    max-width: 30pt;
  }
  .m-max-w-35pt {
    max-width: 35pt;
  }
  .m-max-w-40pt {
    max-width: 40pt;
  }
  .m-max-w-45pt {
    max-width: 45pt;
  }
  .m-max-w-50pt {
    max-width: 50pt;
  }
  .m-max-w-55pt {
    max-width: 55pt;
  }
  .m-max-w-60pt {
    max-width: 60pt;
  }
  .m-max-w-65pt {
    max-width: 65pt;
  }
  .m-max-w-70pt {
    max-width: 70pt;
  }
  .m-max-w-75pt {
    max-width: 75pt;
  }
  .m-max-w-80pt {
    max-width: 80pt;
  }
  .m-max-w-85pt {
    max-width: 85pt;
  }
  .m-max-w-90pt {
    max-width: 90pt;
  }
  .m-max-w-95pt {
    max-width: 95pt;
  }
  .m-max-w-100pt {
    max-width: 100pt;
  }
  .m-max-w-125pt {
    max-width: 125pt;
  }
  .m-max-w-150pt {
    max-width: 150pt;
  }
  .m-max-w-175pt {
    max-width: 175pt;
  }
  .m-max-w-200pt {
    max-width: 200pt;
  }
  .m-max-w-225pt {
    max-width: 225pt;
  }
  .m-max-w-250pt {
    max-width: 250pt;
  }
  .m-max-w-275pt {
    max-width: 275pt;
  }
  .m-max-w-300pt {
    max-width: 300pt;
  }
  .m-max-w-325pt {
    max-width: 325pt;
  }
  .m-max-w-350pt {
    max-width: 350pt;
  }
  .m-max-w-375pt {
    max-width: 375pt;
  }
  .m-max-w-400pt {
    max-width: 400pt;
  }
  .m-max-w-425pt {
    max-width: 425pt;
  }
  .m-max-w-450pt {
    max-width: 450pt;
  }
  .m-max-w-475pt {
    max-width: 475pt;
  }
  .m-max-w-500pt {
    max-width: 500pt;
  }
  .m-max-w-1000pt {
    max-width: 1000pt;
  }
  .m-max-w-1 {
    max-width: 1%;
  }
  .m-max-w-2 {
    max-width: 2%;
  }
  .m-max-w-3 {
    max-width: 3%;
  }
  .m-max-w-4 {
    max-width: 4%;
  }
  .m-max-w-5 {
    max-width: 5%;
  }
  .m-max-w-10 {
    max-width: 10%;
  }
  .m-max-w-15 {
    max-width: 15%;
  }
  .m-max-w-20 {
    max-width: 20%;
  }
  .m-max-w-25 {
    max-width: 25%;
  }
  .m-max-w-30 {
    max-width: 30%;
  }
  .m-max-w-35 {
    max-width: 35%;
  }
  .m-max-w-40 {
    max-width: 40%;
  }
  .m-max-w-45 {
    max-width: 45%;
  }
  .m-max-w-50 {
    max-width: 50%;
  }
  .m-max-w-55 {
    max-width: 55%;
  }
  .m-max-w-60 {
    max-width: 60%;
  }
  .m-max-w-65 {
    max-width: 65%;
  }
  .m-max-w-70 {
    max-width: 70%;
  }
  .m-max-w-75 {
    max-width: 75%;
  }
  .m-max-w-80 {
    max-width: 80%;
  }
  .m-max-w-85 {
    max-width: 85%;
  }
  .m-max-w-90 {
    max-width: 90%;
  }
  .m-max-w-95 {
    max-width: 95%;
  }
  .m-max-w-100 {
    max-width: 100%;
  }
  .m-max-w-1vw {
    max-width: 1vw;
  }
  .m-max-w-2vw {
    max-width: 2vw;
  }
  .m-max-w-3vw {
    max-width: 3vw;
  }
  .m-max-w-4vw {
    max-width: 4vw;
  }
  .m-max-w-5vw {
    max-width: 5vw;
  }
  .m-max-w-10vw {
    max-width: 10vw;
  }
  .m-max-w-15vw {
    max-width: 15vw;
  }
  .m-max-w-20vw {
    max-width: 20vw;
  }
  .m-max-w-25vw {
    max-width: 25vw;
  }
  .m-max-w-30vw {
    max-width: 30vw;
  }
  .m-max-w-35vw {
    max-width: 35vw;
  }
  .m-max-w-40vw {
    max-width: 40vw;
  }
  .m-max-w-45vw {
    max-width: 45vw;
  }
  .m-max-w-50vw {
    max-width: 50vw;
  }
  .m-max-w-55vw {
    max-width: 55vw;
  }
  .m-max-w-60vw {
    max-width: 60vw;
  }
  .m-max-w-65vw {
    max-width: 65vw;
  }
  .m-max-w-70vw {
    max-width: 70vw;
  }
  .m-max-w-75vw {
    max-width: 75vw;
  }
  .m-max-w-80vw {
    max-width: 80vw;
  }
  .m-max-w-85vw {
    max-width: 85vw;
  }
  .m-max-w-90vw {
    max-width: 90vw;
  }
  .m-max-w-95vw {
    max-width: 95vw;
  }
  .m-max-w-100vw {
    max-width: 100vw;
  }
  /*
  ▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
  ██ ██ ██ ▄▄▄█▄ ▄██ ▄▄ ██ ██ █▄▄ ▄▄██
  ██ ▄▄ ██ ▄▄▄██ ███ █▀▀██ ▄▄ ███ ████
  ██ ██ ██ ▀▀▀█▀ ▀██ ▀▀▄██ ██ ███ ████
  ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  */
  /* Sintax and specials height */
  .m-h-inh {
    height: inherit;
  }

  .m-h-auto {
    height: auto;
  }
  .m-height-auto {
    height: auto;
  }
  .m-height-inherit {
    height: inherit;
  }
  .m-h-12-5 {
    height: 12.5%;
    /* 100 / 8 = 12.5 */
  }
  /* Height px - pt - % - vw - vh */
  .m-h-1px {
    height: 1px;
  }
  .m-h-2px {
    height: 2px;
  }
  .m-h-3px {
    height: 3px;
  }
  .m-h-4px {
    height: 4px;
  }
  .m-h-5px {
    height: 5px;
  }
  .m-h-10px {
    height: 10px;
  }
  .m-h-15px {
    height: 15px;
  }
  .m-h-20px {
    height: 20px;
  }
  .m-h-25px {
    height: 25px;
  }
  .m-h-30px {
    height: 30px;
  }
  .m-h-35px {
    height: 35px;
  }
  .m-h-40px {
    height: 40px;
  }
  .m-h-45px {
    height: 45px;
  }
  .m-h-50px {
    height: 50px;
  }
  .m-h-55px {
    height: 55px;
  }
  .m-h-60px {
    height: 60px;
  }
  .m-h-65px {
    height: 65px;
  }
  .m-h-70px {
    height: 70px;
  }
  .m-h-75px {
    height: 75px;
  }
  .m-h-80px {
    height: 80px;
  }
  .m-h-85px {
    height: 85px;
  }
  .m-h-90px {
    height: 90px;
  }
  .m-h-95px {
    height: 95px;
  }
  .m-h-100px {
    height: 100px;
  }
  .m-h-125px {
    height: 125px;
  }
  .m-h-150px {
    height: 150px;
  }
  .m-h-175px {
    height: 175px;
  }
  .m-h-200px {
    height: 200px;
  }
  .m-h-225px {
    height: 225px;
  }
  .m-h-250px {
    height: 250px;
  }
  .m-h-275px {
    height: 275px;
  }
  .m-h-300px {
    height: 300px;
  }
  .m-h-325px {
    height: 325px;
  }
  .m-h-350px {
    height: 350px;
  }
  .m-h-375px {
    height: 375px;
  }
  .m-h-400px {
    height: 400px;
  }
  .m-h-425px {
    height: 425px;
  }
  .m-h-450px {
    height: 450px;
  }
  .m-h-475px {
    height: 475px;
  }
  .m-h-500px {
    height: 500px;
  }
  .m-h-1000px {
    height: 1000px;
  }
  .m-h-1pt {
    height: 1pt;
  }
  .m-h-2pt {
    height: 2pt;
  }
  .m-h-3pt {
    height: 3pt;
  }
  .m-h-4pt {
    height: 4pt;
  }
  .m-h-5pt {
    height: 5pt;
  }
  .m-h-10pt {
    height: 10pt;
  }
  .m-h-15pt {
    height: 15pt;
  }
  .m-h-20pt {
    height: 20pt;
  }
  .m-h-25pt {
    height: 25pt;
  }
  .m-h-30pt {
    height: 30pt;
  }
  .m-h-35pt {
    height: 35pt;
  }
  .m-h-40pt {
    height: 40pt;
  }
  .m-h-45pt {
    height: 45pt;
  }
  .m-h-50pt {
    height: 50pt;
  }
  .m-h-55pt {
    height: 55pt;
  }
  .m-h-60pt {
    height: 60pt;
  }
  .m-h-65pt {
    height: 65pt;
  }
  .m-h-70pt {
    height: 70pt;
  }
  .m-h-75pt {
    height: 75pt;
  }
  .m-h-80pt {
    height: 80pt;
  }
  .m-h-85pt {
    height: 85pt;
  }
  .m-h-90pt {
    height: 90pt;
  }
  .m-h-95pt {
    height: 95pt;
  }
  .m-h-100pt {
    height: 100pt;
  }
  .m-h-125pt {
    height: 125pt;
  }
  .m-h-150pt {
    height: 150pt;
  }
  .m-h-175pt {
    height: 175pt;
  }
  .m-h-200pt {
    height: 200pt;
  }
  .m-h-225pt {
    height: 225pt;
  }
  .m-h-250pt {
    height: 250pt;
  }
  .m-h-275pt {
    height: 275pt;
  }
  .m-h-300pt {
    height: 300pt;
  }
  .m-h-325pt {
    height: 325pt;
  }
  .m-h-350pt {
    height: 350pt;
  }
  .m-h-375pt {
    height: 375pt;
  }
  .m-h-400pt {
    height: 400pt;
  }
  .m-h-425pt {
    height: 425pt;
  }
  .m-h-450pt {
    height: 450pt;
  }
  .m-h-475pt {
    height: 475pt;
  }
  .m-h-500pt {
    height: 500pt;
  }
  .m-h-1000pt {
    height: 1000pt;
  }
  .m-h-1 {
    height: 1%;
  }
  .m-h-2 {
    height: 2%;
  }
  .m-h-3 {
    height: 3%;
  }
  .m-h-4 {
    height: 4%;
  }
  .m-h-5 {
    height: 5%;
  }
  .m-h-10 {
    height: 10%;
  }
  .m-h-15 {
    height: 15%;
  }
  .m-h-20 {
    height: 20%;
  }
  .m-h-25 {
    height: 25%;
  }
  .m-h-30 {
    height: 30%;
  }
  .m-h-35 {
    height: 35%;
  }
  .m-h-40 {
    height: 40%;
  }
  .m-h-45 {
    height: 45%;
  }
  .m-h-50 {
    height: 50%;
  }
  .m-h-55 {
    height: 55%;
  }
  .m-h-60 {
    height: 60%;
  }
  .m-h-65 {
    height: 65%;
  }
  .m-h-70 {
    height: 70%;
  }
  .m-h-75 {
    height: 75%;
  }
  .m-h-80 {
    height: 80% !important;
  }
  .m-h-85 {
    height: 85%;
  }
  .m-h-90 {
    height: 90%;
  }
  .m-h-95 {
    height: 95%;
  }
  .m-h-100 {
    height: 100%;
  }
  .m-h-1vh {
    height: 1vh;
  }
  .m-h-2vh {
    height: 2vh;
  }
  .m-h-3vh {
    height: 3vh;
  }
  .m-h-4vh {
    height: 4vh;
  }
  .m-h-5vh {
    height: 5vh;
  }
  .m-h-10vh {
    height: 10vh;
  }
  .m-h-15vh {
    height: 15vh;
  }
  .m-h-20vh {
    height: 20vh;
  }
  .m-h-25vh {
    height: 25vh;
  }
  .m-h-30vh {
    height: 30vh;
  }
  .m-h-35vh {
    height: 35vh;
  }
  .m-h-40vh {
    height: 40vh;
  }
  .m-h-45vh {
    height: 45vh;
  }
  .m-h-50vh {
    height: 50vh;
  }
  .m-h-55vh {
    height: 55vh;
  }
  .m-h-60vh {
    height: 60vh;
  }
  .m-h-65vh {
    height: 65vh;
  }
  .m-h-70vh {
    height: 70vh;
  }
  .m-h-75vh {
    height: 75vh;
  }
  .m-h-80vh {
    height: 80vh;
  }
  .m-h-85vh {
    height: 85vh;
  }
  .m-h-90vh {
    height: 90vh;
  }
  .m-h-95vh {
    height: 95vh;
  }
  .m-h-100vh {
    height: 100vh;
  }
  /* Max Height px - pt - % - vw - vh */
  .m-max-h-1px {
    max-height: 1px;
  }
  .m-max-h-2px {
    max-height: 2px;
  }
  .m-max-h-3px {
    max-height: 3px;
  }
  .m-max-h-4px {
    max-height: 4px;
  }
  .m-max-h-5px {
    max-height: 5px;
  }
  .m-max-h-10px {
    max-height: 10px;
  }
  .m-max-h-15px {
    max-height: 15px;
  }
  .m-max-h-20px {
    max-height: 20px;
  }
  .m-max-h-25px {
    max-height: 25px;
  }
  .m-max-h-30px {
    max-height: 30px;
  }
  .m-max-h-35px {
    max-height: 35px;
  }
  .m-max-h-40px {
    max-height: 40px;
  }
  .m-max-h-45px {
    max-height: 45px;
  }
  .m-max-h-50px {
    max-height: 50px;
  }
  .m-max-h-55px {
    max-height: 55px;
  }
  .m-max-h-60px {
    max-height: 60px;
  }
  .m-max-h-65px {
    max-height: 65px;
  }
  .m-max-h-70px {
    max-height: 70px;
  }
  .m-max-h-75px {
    max-height: 75px;
  }
  .m-max-h-80px {
    max-height: 80px;
  }
  .m-max-h-85px {
    max-height: 85px;
  }
  .m-max-h-90px {
    max-height: 90px;
  }
  .m-max-h-95px {
    max-height: 95px;
  }
  .m-max-h-100px {
    max-height: 100px;
  }
  .m-max-h-125px {
    max-height: 125px;
  }
  .m-max-h-150px {
    max-height: 150px;
  }
  .m-max-h-175px {
    max-height: 175px;
  }
  .m-max-h-200px {
    max-height: 200px;
  }
  .m-max-h-225px {
    max-height: 225px;
  }
  .m-max-h-250px {
    max-height: 250px;
  }
  .m-max-h-275px {
    max-height: 275px;
  }
  .m-max-h-300px {
    max-height: 300px;
  }
  .m-max-h-325px {
    max-height: 325px;
  }
  .m-max-h-350px {
    max-height: 350px;
  }
  .m-max-h-375px {
    max-height: 375px;
  }
  .m-max-h-400px {
    max-height: 400px;
  }
  .m-max-h-425px {
    max-height: 425px;
  }
  .m-max-h-450px {
    max-height: 450px;
  }
  .m-max-h-475px {
    max-height: 475px;
  }
  .m-max-h-500px {
    max-height: 500px;
  }
  .m-max-h-1000px {
    max-height: 1000px;
  }
  .m-max-h-1pt {
    max-height: 1pt;
  }
  .m-max-h-2pt {
    max-height: 2pt;
  }
  .m-max-h-3pt {
    max-height: 3pt;
  }
  .m-max-h-4pt {
    max-height: 4pt;
  }
  .m-max-h-5pt {
    max-height: 5pt;
  }
  .m-max-h-10pt {
    max-height: 10pt;
  }
  .m-max-h-15pt {
    max-height: 15pt;
  }
  .m-max-h-20pt {
    max-height: 20pt;
  }
  .m-max-h-25pt {
    max-height: 25pt;
  }
  .m-max-h-30pt {
    max-height: 30pt;
  }
  .m-max-h-35pt {
    max-height: 35pt;
  }
  .m-max-h-40pt {
    max-height: 40pt;
  }
  .m-max-h-45pt {
    max-height: 45pt;
  }
  .m-max-h-50pt {
    max-height: 50pt;
  }
  .m-max-h-55pt {
    max-height: 55pt;
  }
  .m-max-h-60pt {
    max-height: 60pt;
  }
  .m-max-h-65pt {
    max-height: 65pt;
  }
  .m-max-h-70pt {
    max-height: 70pt;
  }
  .m-max-h-75pt {
    max-height: 75pt;
  }
  .m-max-h-80pt {
    max-height: 80pt;
  }
  .m-max-h-85pt {
    max-height: 85pt;
  }
  .m-max-h-90pt {
    max-height: 90pt;
  }
  .m-max-h-95pt {
    max-height: 95pt;
  }
  .m-max-h-100pt {
    max-height: 100pt;
  }
  .m-max-h-125pt {
    max-height: 125pt;
  }
  .m-max-h-150pt {
    max-height: 150pt;
  }
  .m-max-h-175pt {
    max-height: 175pt;
  }
  .m-max-h-200pt {
    max-height: 200pt;
  }
  .m-max-h-225pt {
    max-height: 225pt;
  }
  .m-max-h-250pt {
    max-height: 250pt;
  }
  .m-max-h-275pt {
    max-height: 275pt;
  }
  .m-max-h-300pt {
    max-height: 300pt;
  }
  .m-max-h-325pt {
    max-height: 325pt;
  }
  .m-max-h-350pt {
    max-height: 350pt;
  }
  .m-max-h-375pt {
    max-height: 375pt;
  }
  .m-max-h-400pt {
    max-height: 400pt;
  }
  .m-max-h-425pt {
    max-height: 425pt;
  }
  .m-max-h-450pt {
    max-height: 450pt;
  }
  .m-max-h-475pt {
    max-height: 475pt;
  }
  .m-max-h-500pt {
    max-height: 500pt;
  }
  .m-max-h-1000pt {
    max-height: 1000pt;
  }
  .m-max-h-1 {
    max-height: 1%;
  }
  .m-max-h-2 {
    max-height: 2%;
  }
  .m-max-h-3 {
    max-height: 3%;
  }
  .m-max-h-4 {
    max-height: 4%;
  }
  .m-max-h-5 {
    max-height: 5%;
  }
  .m-max-h-10 {
    max-height: 10%;
  }
  .m-max-h-15 {
    max-height: 15%;
  }
  .m-max-h-20 {
    max-height: 20%;
  }
  .m-max-h-25 {
    max-height: 25%;
  }
  .m-max-h-30 {
    max-height: 30%;
  }
  .m-max-h-35 {
    max-height: 35%;
  }
  .m-max-h-40 {
    max-height: 40%;
  }
  .m-max-h-45 {
    max-height: 45%;
  }
  .m-max-h-50 {
    max-height: 50%;
  }
  .m-max-h-55 {
    max-height: 55%;
  }
  .m-max-h-60 {
    max-height: 60%;
  }
  .m-max-h-65 {
    max-height: 65%;
  }
  .m-max-h-70 {
    max-height: 70%;
  }
  .m-max-h-75 {
    max-height: 75%;
  }
  .m-max-h-80 {
    max-height: 80%;
  }
  .m-max-h-85 {
    max-height: 85%;
  }
  .m-max-h-90 {
    max-height: 90%;
  }
  .m-max-h-95 {
    max-height: 95%;
  }
  .m-max-h-100 {
    max-height: 100%;
  }
  .m-max-h-1vh {
    max-height: 1vh;
  }
  .m-max-h-2vh {
    max-height: 2vh;
  }
  .m-max-h-3vh {
    max-height: 3vh;
  }
  .m-max-h-4vh {
    max-height: 4vh;
  }
  .m-max-h-5vh {
    max-height: 5vh;
  }
  .m-max-h-10vh {
    max-height: 10vh;
  }
  .m-max-h-15vh {
    max-height: 15vh;
  }
  .m-max-h-20vh {
    max-height: 20vh;
  }
  .m-max-h-25vh {
    max-height: 25vh;
  }
  .m-max-h-30vh {
    max-height: 30vh;
  }
  .m-max-h-35vh {
    max-height: 35vh;
  }
  .m-max-h-40vh {
    max-height: 40vh;
  }
  .m-max-h-45vh {
    max-height: 45vh;
  }
  .m-max-h-50vh {
    max-height: 50vh;
  }
  .m-max-h-55vh {
    max-height: 55vh;
  }
  .m-max-h-60vh {
    max-height: 60vh;
  }
  .m-max-h-65vh {
    max-height: 65vh;
  }
  .m-max-h-70vh {
    max-height: 70vh;
  }
  .m-max-h-75vh {
    max-height: 75vh;
  }
  .m-max-h-80vh {
    max-height: 80vh;
  }
  .m-max-h-85vh {
    max-height: 85vh;
  }
  .m-max-h-90vh {
    max-height: 90vh;
  }
  .m-max-h-95vh {
    max-height: 95vh;
  }
  .m-max-h-100vh {
    max-height: 100vh;
  }
  /* Min Height px - pt - % - vw - vh */
  .m-min-h-1px {
    min-height: 1px;
  }
  .m-min-h-2px {
    min-height: 2px;
  }
  .m-min-h-3px {
    min-height: 3px;
  }
  .m-min-h-4px {
    min-height: 4px;
  }
  .m-min-h-5px {
    min-height: 5px;
  }
  .m-min-h-10px {
    min-height: 10px;
  }
  .m-min-h-15px {
    min-height: 15px;
  }
  .m-min-h-20px {
    min-height: 20px;
  }
  .m-min-h-25px {
    min-height: 25px;
  }
  .m-min-h-30px {
    min-height: 30px;
  }
  .m-min-h-35px {
    min-height: 35px;
  }
  .m-min-h-40px {
    min-height: 40px;
  }
  .m-min-h-45px {
    min-height: 45px;
  }
  .m-min-h-50px {
    min-height: 50px;
  }
  .m-min-h-55px {
    min-height: 55px;
  }
  .m-min-h-60px {
    min-height: 60px;
  }
  .m-min-h-65px {
    min-height: 65px;
  }
  .m-min-h-70px {
    min-height: 70px;
  }
  .m-min-h-75px {
    min-height: 75px;
  }
  .m-min-h-80px {
    min-height: 80px;
  }
  .m-min-h-85px {
    min-height: 85px;
  }
  .m-min-h-90px {
    min-height: 90px;
  }
  .m-min-h-95px {
    min-height: 95px;
  }
  .m-min-h-100px {
    min-height: 100px;
  }
  .m-min-h-125px {
    min-height: 125px;
  }
  .m-min-h-150px {
    min-height: 150px;
  }
  .m-min-h-175px {
    min-height: 175px;
  }
  .m-min-h-200px {
    min-height: 200px;
  }
  .m-min-h-225px {
    min-height: 225px;
  }
  .m-min-h-250px {
    min-height: 250px;
  }
  .m-min-h-275px {
    min-height: 275px;
  }
  .m-min-h-300px {
    min-height: 300px;
  }
  .m-min-h-325px {
    min-height: 325px;
  }
  .m-min-h-350px {
    min-height: 350px;
  }
  .m-min-h-375px {
    min-height: 375px;
  }
  .m-min-h-400px {
    min-height: 400px;
  }
  .m-min-h-425px {
    min-height: 425px;
  }
  .m-min-h-450px {
    min-height: 450px;
  }
  .m-min-h-475px {
    min-height: 475px;
  }
  .m-min-h-500px {
    min-height: 500px;
  }
  .m-min-h-1000px {
    min-height: 1000px;
  }
  .m-min-h-1pt {
    min-height: 1pt;
  }
  .m-min-h-2pt {
    min-height: 2pt;
  }
  .m-min-h-3pt {
    min-height: 3pt;
  }
  .m-min-h-4pt {
    min-height: 4pt;
  }
  .m-min-h-5pt {
    min-height: 5pt;
  }
  .m-min-h-10pt {
    min-height: 10pt;
  }
  .m-min-h-15pt {
    min-height: 15pt;
  }
  .m-min-h-20pt {
    min-height: 20pt;
  }
  .m-min-h-25pt {
    min-height: 25pt;
  }
  .m-min-h-30pt {
    min-height: 30pt;
  }
  .m-min-h-35pt {
    min-height: 35pt;
  }
  .m-min-h-40pt {
    min-height: 40pt;
  }
  .m-min-h-45pt {
    min-height: 45pt;
  }
  .m-min-h-50pt {
    min-height: 50pt;
  }
  .m-min-h-55pt {
    min-height: 55pt;
  }
  .m-min-h-60pt {
    min-height: 60pt;
  }
  .m-min-h-65pt {
    min-height: 65pt;
  }
  .m-min-h-70pt {
    min-height: 70pt;
  }
  .m-min-h-75pt {
    min-height: 75pt;
  }
  .m-min-h-80pt {
    min-height: 80pt;
  }
  .m-min-h-85pt {
    min-height: 85pt;
  }
  .m-min-h-90pt {
    min-height: 90pt;
  }
  .m-min-h-95pt {
    min-height: 95pt;
  }
  .m-min-h-100pt {
    min-height: 100pt;
  }
  .m-min-h-125pt {
    min-height: 125pt;
  }
  .m-min-h-150pt {
    min-height: 150pt;
  }
  .m-min-h-175pt {
    min-height: 175pt;
  }
  .m-min-h-200pt {
    min-height: 200pt;
  }
  .m-min-h-225pt {
    min-height: 225pt;
  }
  .m-min-h-250pt {
    min-height: 250pt;
  }
  .m-min-h-275pt {
    min-height: 275pt;
  }
  .m-min-h-300pt {
    min-height: 300pt;
  }
  .m-min-h-325pt {
    min-height: 325pt;
  }
  .m-min-h-350pt {
    min-height: 350pt;
  }
  .m-min-h-375pt {
    min-height: 375pt;
  }
  .m-min-h-400pt {
    min-height: 400pt;
  }
  .m-min-h-425pt {
    min-height: 425pt;
  }
  .m-min-h-450pt {
    min-height: 450pt;
  }
  .m-min-h-475pt {
    min-height: 475pt;
  }
  .m-min-h-500pt {
    min-height: 500pt;
  }
  .m-min-h-1000pt {
    min-height: 1000pt;
  }
  .m-min-h-1 {
    min-height: 1%;
  }
  .m-min-h-2 {
    min-height: 2%;
  }
  .m-min-h-3 {
    min-height: 3%;
  }
  .m-min-h-4 {
    min-height: 4%;
  }
  .m-min-h-5 {
    min-height: 5%;
  }
  .m-min-h-10 {
    min-height: 10%;
  }
  .m-min-h-15 {
    min-height: 15%;
  }
  .m-min-h-20 {
    min-height: 20%;
  }
  .m-min-h-25 {
    min-height: 25%;
  }
  .m-min-h-30 {
    min-height: 30%;
  }
  .m-min-h-35 {
    min-height: 35%;
  }
  .m-min-h-40 {
    min-height: 40%;
  }
  .m-min-h-45 {
    min-height: 45%;
  }
  .m-min-h-50 {
    min-height: 50%;
  }
  .m-min-h-55 {
    min-height: 55%;
  }
  .m-min-h-60 {
    min-height: 60%;
  }
  .m-min-h-65 {
    min-height: 65%;
  }
  .m-min-h-70 {
    min-height: 70%;
  }
  .m-min-h-75 {
    min-height: 75%;
  }
  .m-min-h-80 {
    min-height: 80%;
  }
  .m-min-h-85 {
    min-height: 85%;
  }
  .m-min-h-90 {
    min-height: 90%;
  }
  .m-min-h-95 {
    min-height: 95%;
  }
  .m-min-h-100 {
    min-height: 100%;
  }
  .m-min-h-1vh {
    min-height: 1vh;
  }
  .m-min-h-2vh {
    min-height: 2vh;
  }
  .m-min-h-3vh {
    min-height: 3vh;
  }
  .m-min-h-4vh {
    min-height: 4vh;
  }
  .m-min-h-5vh {
    min-height: 5vh;
  }
  .m-min-h-10vh {
    min-height: 10vh;
  }
  .m-min-h-15vh {
    min-height: 15vh;
  }
  .m-min-h-20vh {
    min-height: 20vh;
  }
  .m-min-h-25vh {
    min-height: 25vh;
  }
  .m-min-h-30vh {
    min-height: 30vh;
  }
  .m-min-h-35vh {
    min-height: 35vh;
  }
  .m-min-h-40vh {
    min-height: 40vh;
  }
  .m-min-h-45vh {
    min-height: 45vh;
  }
  .m-min-h-50vh {
    min-height: 50vh;
  }
  .m-min-h-55vh {
    min-height: 55vh;
  }
  .m-min-h-60vh {
    min-height: 60vh;
  }
  .m-min-h-65vh {
    min-height: 65vh;
  }
  .m-min-h-70vh {
    min-height: 70vh;
  }
  .m-min-h-75vh {
    min-height: 75vh;
  }
  .m-min-h-80vh {
    min-height: 80vh;
  }
  .m-min-h-85vh {
    min-height: 85vh;
  }
  .m-min-h-90vh {
    min-height: 90vh;
  }
  .m-min-h-95vh {
    min-height: 95vh;
  }
  .m-min-h-100vh {
    min-height: 100vh;
  }
}

/*

███    ███  █████  ██████   ██████  ██ ███    ██
████  ████ ██   ██ ██   ██ ██       ██ ████   ██
██ ████ ██ ███████ ██████  ██   ███ ██ ██ ██  ██
██  ██  ██ ██   ██ ██   ██ ██    ██ ██ ██  ██ ██
██      ██ ██   ██ ██   ██  ██████  ██ ██   ████

*/
/* Sintax and specials margin */
.m-a {
  margin: auto;
}

.m-auto {
  margin: auto;
}

.margin-auto {
  margin: auto;
}

/* Margin , Margin-Top , Margin-Right , Margin-Bottom , Margin-Left px */
.m-1px {
  margin: 1px;
}

.m-2px {
  margin: 2px;
}

.m-3px {
  margin: 3px;
}

.m-4px {
  margin: 4px;
}

.m-5px {
  margin: 5px;
}

.m-6px {
  margin: 6px;
}

.m-7px {
  margin: 7px;
}

.m-8px {
  margin: 8px;
}

.m-9px {
  margin: 9px;
}

.m-10px {
  margin: 10px;
}

.m-20px {
  margin: 20px;
}

.m-30px {
  margin: 30px;
}

.m-40px {
  margin: 40px;
}

.m-50px {
  margin: 50px;
}

.m-60px {
  margin: 60px;
}

.m-70px {
  margin: 70px;
}

.m-80px {
  margin: 80px;
}

.m-90px {
  margin: 90px;
}

.m-100px {
  margin: 100px;
}

.m-t-1px {
  margin-top: 1px;
}

.m-t-2px {
  margin-top: 2px;
}

.m-t-3px {
  margin-top: 3px;
}

.m-t-4px {
  margin-top: 4px;
}

.m-t-5px {
  margin-top: 5px;
}

.m-t-6px {
  margin-top: 6px;
}

.m-t-7px {
  margin-top: 7px;
}

.m-t-8px {
  margin-top: 8px;
}

.m-t-9px {
  margin-top: 9px;
}

.m-t-10px {
  margin-top: 10px;
}

.m-t-20px {
  margin-top: 20px;
}

.m-t-30px {
  margin-top: 30px;
}

.m-t-40px {
  margin-top: 40px;
}

.m-t-50px {
  margin-top: 50px;
}

.m-t-60px {
  margin-top: 60px;
}

.m-t-70px {
  margin-top: 70px;
}

.m-t-80px {
  margin-top: 80px;
}

.m-t-90px {
  margin-top: 90px;
}

.m-t-100px {
  margin-top: 100px;
}

.m-r-1px {
  margin-right: 1px;
}

.m-r-2px {
  margin-right: 2px;
}

.m-r-3px {
  margin-right: 3px;
}

.m-r-4px {
  margin-right: 4px;
}

.m-r-5px {
  margin-right: 5px;
}

.m-r-6px {
  margin-right: 6px;
}

.m-r-7px {
  margin-right: 7px;
}

.m-r-8px {
  margin-right: 8px;
}

.m-r-9px {
  margin-right: 9px;
}

.m-r-10px {
  margin-right: 10px;
}

.m-r-20px {
  margin-right: 20px;
}

.m-r-30px {
  margin-right: 30px;
}

.m-r-40px {
  margin-right: 40px;
}

.m-r-50px {
  margin-right: 50px;
}

.m-r-60px {
  margin-right: 60px;
}

.m-r-70px {
  margin-right: 70px;
}

.m-r-80px {
  margin-right: 80px;
}

.m-r-90px {
  margin-right: 90px;
}

.m-r-100px {
  margin-right: 100px;
}

.m-b-1px {
  margin-bottom: 1px;
}

.m-b-2px {
  margin-bottom: 2px;
}

.m-b-3px {
  margin-bottom: 3px;
}

.m-b-4px {
  margin-bottom: 4px;
}

.m-b-5px {
  margin-bottom: 5px;
}

.m-b-6px {
  margin-bottom: 6px;
}

.m-b-7px {
  margin-bottom: 7px;
}

.m-b-8px {
  margin-bottom: 8px;
}

.m-b-9px {
  margin-bottom: 9px;
}

.m-b-10px {
  margin-bottom: 10px;
}

.m-b-20px {
  margin-bottom: 20px;
}

.m-b-30px {
  margin-bottom: 30px;
}

.m-b-40px {
  margin-bottom: 40px;
}

.m-b-50px {
  margin-bottom: 50px;
}

.m-b-60px {
  margin-bottom: 60px;
}

.m-b-70px {
  margin-bottom: 70px;
}

.m-b-80px {
  margin-bottom: 80px;
}

.m-b-90px {
  margin-bottom: 90px;
}

.m-b-100px {
  margin-bottom: 100px;
}

.m-l-1px {
  margin-left: 1px;
}

.m-l-2px {
  margin-left: 2px;
}

.m-l-3px {
  margin-left: 3px;
}

.m-l-4px {
  margin-left: 4px;
}

.m-l-5px {
  margin-left: 5px;
}

.m-l-6px {
  margin-left: 6px;
}

.m-l-7px {
  margin-left: 7px;
}

.m-l-8px {
  margin-left: 8px;
}

.m-l-9px {
  margin-left: 9px;
}

.m-l-10px {
  margin-left: 10px;
}

.m-l-20px {
  margin-left: 20px;
}

.m-l-30px {
  margin-left: 30px;
}

.m-l-40px {
  margin-left: 40px;
}

.m-l-50px {
  margin-left: 50px;
}

.m-l-60px {
  margin-left: 60px;
}

.m-l-70px {
  margin-left: 70px;
}

.m-l-80px {
  margin-left: 80px;
}

.m-l-90px {
  margin-left: 90px;
}

.m-l-100px {
  margin-left: 100px;
}



/* --------------------------------------- */



/* POSITIONS LEFT - RIGHT - TOP - BOTTOM */



/* --------------------------------------- */

    


/* --------------- TOP --------------------*/

.t-a {
  top: auto;
}


.t-0px {
  top: 0px;
}

.t-1px {
  top: 1px;
}

 .t-2px {
  top: 2px;
}

 .t-3px {
  top: 3px;
}

 .t-4px {
  top: 4px;
}

 .t-5px {
  top: 5px;
}

 .t-6px {
  top: 6px;
}

 .t-7px {
  top: 7px;
}

 .t-8px {
  top: 8px;
}

 .t-9px {
  top: 9px;
}

 .t-10px {
  top: 10px;
}

 .t-20px {
  top: 20px;
}

 .t-30px {
  top: 30px;
}

 .t-40px {
  top: 40px;
}

 .t-50px {
  top: 50px;
}

 .t-60px {
  top: 60px;
}

 .t-70px {
  top: 70px;
}

 .t-80px {
  top: 80px;
}

 .t-90px {
  top: 90px;
}

 .t-100px {
  top: 100px;
}


/* --------------- RIGHT --------------------*/
.r-a {
  right: auto;
}


.r-0px {
  right: 0px;
}
 .r-1px {
  right: 1px;
}

 .r-2px {
  right: 2px;
}

 .r-3px {
  right: 3px;
}

 .r-4px {
  right: 4px;
}

 .r-5px {
  right: 5px;
}

 .r-6px {
  right: 6px;
}

 .r-7px {
  right: 7px;
}

 .r-8px {
  right: 8px;
}

 .r-9px {
  right: 9px;
}

 .r-10px {
  right: 10px;
}

 .r-20px {
  right: 20px;
}

 .r-30px {
  right: 30px;
}

 .r-40px {
  right: 40px;
}

 .r-50px {
  right: 50px;
}

 .r-60px {
  right: 60px;
}

 .r-70px {
  right: 70px;
}

 .r-80px {
  right: 80px;
}

 .r-90px {
  right: 90px;
}

 .r-100px {
  right: 100px;
}

/* ---------------- LEFT --------------------*/
.l-a {
  left: auto;
}

.l-0px {
  left: 0px;
}
.l-1px {
   left: 1px;
}

.l-2px {
   left: 2px;
}

.l-3px {
   left: 3px;
}

.l-4px {
   left: 4px;
}

.l-5px {
   left: 5px;
}

.l-6px {
   left: 6px;
}

.l-7px {
   left: 7px;
}

.l-8px {
   left: 8px;
}

.l-9px {
   left: 9px;
}

.l-10px {
   left: 10px;
}

.l-20px {
   left: 20px;
}

.l-30px {
   left: 30px;
}

.l-40px {
   left: 40px;
}

.l-50px {
   left: 50px;
}

.l-60px {
   left: 60px;
}

.l-70px {
   left: 70px;
}

.l-80px {
   left: 80px;
}

.l-90px {
   left: 90px;
}

.l-100px {
   left: 100px;
}



/* --------------- BOTTOM --------------------*/
.b-a {
  bottom: auto;
}
.b-0px {
  bottom: 0px;
}

.b-1px {
  bottom: 1px;
}

 .b-2px {
  bottom: 2px;
}

 .b-3px {
  bottom: 3px;
}

 .b-4px {
  bottom: 4px;
}

 .b-5px {
  bottom: 5px;
}

 .b-6px {
  bottom: 6px;
}

 .b-7px {
  bottom: 7px;
}

 .b-8px {
  bottom: 8px;
}

 .b-9px {
  bottom: 9px;
}

 .b-10px {
  bottom: 10px;
}

 .b-20px {
  bottom: 20px;
}

 .b-30px {
  bottom: 30px;
}

 .b-40px {
  bottom: 40px;
}

 .b-50px {
  bottom: 50px;
}

 .b-60px {
  bottom: 60px;
}

 .b-70px {
  bottom: 70px;
}

 .b-80px {
  bottom: 80px;
}

 .b-90px {
  bottom: 90px;
}

 .b-100px {
  bottom: 100px;
}


/*

██████   █████  ██████  ██████  ██ ███    ██  ██████
██   ██ ██   ██ ██   ██ ██   ██ ██ ████   ██ ██
██████  ███████ ██   ██ ██   ██ ██ ██ ██  ██ ██   ███
██      ██   ██ ██   ██ ██   ██ ██ ██  ██ ██ ██    ██
██      ██   ██ ██████  ██████  ██ ██   ████  ██████

*/
/* Sintax and specials padding */
.p-inh {
  padding: inherit;
}

.p-none {
  padding: 0;
}

/* Padding , Padding-Top , Padding-Right , Padding-Bottom , Padding-Left px */
.p-1px {
  padding: 1px;
}

.p-2px {
  padding: 2px;
}

.p-3px {
  padding: 3px;
}

.p-4px {
  padding: 4px;
}

.p-5px {
  padding: 5px;
}

.p-6px {
  padding: 6px;
}

.p-7px {
  padding: 7px;
}

.p-8px {
  padding: 8px;
}

.p-9px {
  padding: 9px;
}

.p-10px {
  padding: 10px;
}

.p-15px {
  padding: 15px;
}
.p-20px {
  padding: 20px;
}

.p-30px {
  padding: 30px;
}

.p-40px {
  padding: 40px;
}

.p-50px {
  padding: 50px;
}

.p-60px {
  padding: 60px;
}

.p-70px {
  padding: 70px;
}

.p-80px {
  padding: 80px;
}

.p-90px {
  padding: 90px;
}

.p-100px {
  padding: 100px;
}

.p-t-1px {
  padding-top: 1px;
}

.p-t-2px {
  padding-top: 2px;
}

.p-t-3px {
  padding-top: 3px;
}

.p-t-4px {
  padding-top: 4px;
}

.p-t-5px {
  padding-top: 5px;
}

.p-t-6px {
  padding-top: 6px;
}

.p-t-7px {
  padding-top: 7px;
}

.p-t-8px {
  padding-top: 8px;
}

.p-t-9px {
  padding-top: 9px;
}

.p-t-10px {
  padding-top: 10px;
}

.p-t-20px {
  padding-top: 20px;
}

.p-t-30px {
  padding-top: 30px;
}

.p-t-40px {
  padding-top: 40px;
}

.p-t-50px {
  padding-top: 50px;
}

.p-t-60px {
  padding-top: 60px;
}

.p-t-70px {
  padding-top: 70px;
}

.p-t-80px {
  padding-top: 80px;
}

.p-t-90px {
  padding-top: 90px;
}

.p-t-100px {
  padding-top: 100px;
}

.p-r-1px {
  padding-right: 1px;
}

.p-r-2px {
  padding-right: 2px;
}

.p-r-3px {
  padding-right: 3px;
}

.p-r-4px {
  padding-right: 4px;
}

.p-r-5px {
  padding-right: 5px;
}

.p-r-6px {
  padding-right: 6px;
}

.p-r-7px {
  padding-right: 7px;
}

.p-r-8px {
  padding-right: 8px;
}

.p-r-9px {
  padding-right: 9px;
}

.p-r-10px {
  padding-right: 10px;
}

.p-r-20px {
  padding-right: 20px;
}

.p-r-30px {
  padding-right: 30px;
}

.p-r-40px {
  padding-right: 40px;
}

.p-r-50px {
  padding-right: 50px;
}

.p-r-60px {
  padding-right: 60px;
}

.p-r-70px {
  padding-right: 70px;
}

.p-r-80px {
  padding-right: 80px;
}

.p-r-90px {
  padding-right: 90px;
}

.p-r-100px {
  padding-right: 100px;
}

.p-b-1px {
  padding-bottom: 1px;
}

.p-b-2px {
  padding-bottom: 2px;
}

.p-b-3px {
  padding-bottom: 3px;
}

.p-b-4px {
  padding-bottom: 4px;
}

.p-b-5px {
  padding-bottom: 5px;
}

.p-b-6px {
  padding-bottom: 6px;
}

.p-b-7px {
  padding-bottom: 7px;
}

.p-b-8px {
  padding-bottom: 8px;
}

.p-b-9px {
  padding-bottom: 9px;
}

.p-b-10px {
  padding-bottom: 10px;
}

.p-b-20px {
  padding-bottom: 20px;
}

.p-b-30px {
  padding-bottom: 30px;
}

.p-b-40px {
  padding-bottom: 40px;
}

.p-b-50px {
  padding-bottom: 50px;
}

.p-b-60px {
  padding-bottom: 60px;
}

.p-b-70px {
  padding-bottom: 70px;
}

.p-b-80px {
  padding-bottom: 80px;
}

.p-b-90px {
  padding-bottom: 90px;
}

.p-b-100px {
  padding-bottom: 100px;
}

.p-l-1px {
  padding-left: 1px;
}

.p-l-2px {
  padding-left: 2px;
}

.p-l-3px {
  padding-left: 3px;
}

.p-l-4px {
  padding-left: 4px;
}

.p-l-5px {
  padding-left: 5px;
}

.p-l-6px {
  padding-left: 6px;
}

.p-l-7px {
  padding-left: 7px;
}

.p-l-8px {
  padding-left: 8px;
}

.p-l-9px {
  padding-left: 9px;
}

.p-l-10px {
  padding-left: 10px;
}

.p-l-20px {
  padding-left: 20px;
}

.p-l-30px {
  padding-left: 30px;
}

.p-l-40px {
  padding-left: 40px;
}

.p-l-50px {
  padding-left: 50px;
}

.p-l-60px {
  padding-left: 60px;
}

.p-l-70px {
  padding-left: 70px;
}

.p-l-80px {
  padding-left: 80px;
}

.p-l-90px {
  padding-left: 90px;
}

.p-l-100px {
  padding-left: 100px;
}

/* NS padding */
.ns-p-1 {
  padding: 5px;
}

.ns-p-t-1 {
  padding-top: 5px;
}

.ns-p-r-1 {
  padding-right: 5px;
}

.ns-p-b-1 {
  padding-bottom: 5px;
}

.ns-p-l-1 {
  padding-left: 5px;
}

.ns-p-2 {
  padding: 10px;
}

.ns-p-t-2 {
  padding-top: 10px;
}

.ns-p-r-2 {
  padding-right: 10px;
}

.ns-p-b-2 {
  padding-bottom: 10px;
}

.ns-p-l-2 {
  padding-left: 10px;
}

.ns-p-3 {
  padding: 15px;
}

.ns-p-t-3 {
  padding-top: 15px;
}

.ns-p-r-3 {
  padding-right: 15px;
}

.ns-p-b-3 {
  padding-bottom: 15px;
}

.ns-p-l-3 {
  padding-left: 15px;
}

/* Responsive */
@media only screen and (max-height: 500px) {

 

  .ns-p-1 {
    padding: 3px;
  }
  .ns-p-t-1 {
    padding-top: 3px;
  }
  .ns-p-r-1 {
    padding-right: 3px;
  }
  .ns-p-b-1 {
    padding-bottom: 3px;
  }
  .ns-p-l-1 {
    padding-left: 3px;
  }
  .ns-p-2 {
    padding: 5px;
  }
  .ns-p-t-2 {
    padding-top: 5px;
  }
  .ns-p-r-2 {
    padding-right: 5px;
  }
  .ns-p-b-2 {
    padding-bottom: 5px;
  }
  .ns-p-l-2 {
    padding-left: 5px;
  }
  .ns-p-3 {
    padding: 8px;
  }
  .ns-p-t-3 {
    padding-top: 8px;
  }
  .ns-p-r-3 {
    padding-right: 8px;
  }
  .ns-p-b-3 {
    padding-bottom: 8px;
  }
  .ns-p-l-3 {
    padding-left: 8px;
  }
}

/* SQUARE */
.square-16x16 {
  height: 1.000em;
  width: 1.000em;
}

.square-24x24 {
  height: 1.500em;
  width: 1.500em;
}

.square-32x32 {
  height: 2.000em;
  width: 2.000em;
}

.square-64x64 {
  height: 4.000em;
  width: 4.000em;
}

.square-96x96 {
  height: 6.000em;
  width: 6.000em;
}

.square-128x128 {
  height: 8.000em;
  width: 8.000em;
}

.square-256x256 {
  height: 16.000em;
  width: 16.000em;
}

.square-500x500 {
  height: 31.250em;
  width: 31.250em;
}
.animated-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: none;
  animation: animrlv1 2s ease-in-out infinite;
}

@keyframes animrlv1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
/* Circle */
.circle {
  -webkit-border-radius: 50696px;
  -moz-border-radius: 50696px;
  -ms-border-radius: 50696px;
  -o-border-radius: 50696px;
  border-radius: 50696px;
  border-color: inherit;
  border-style: inherit;
}

/*

 ██████  ██    ██ ███████ ██████  ███████ ██       ██████  ██     ██
██    ██ ██    ██ ██      ██   ██ ██      ██      ██    ██ ██     ██
██    ██ ██    ██ █████   ██████  █████   ██      ██    ██ ██  █  ██
██    ██  ██  ██  ██      ██   ██ ██      ██      ██    ██ ██ ███ ██
 ██████    ████   ███████ ██   ██ ██      ███████  ██████   ███ ███

*/
/* Sintax and specials overflow */

.of-inherit {
  overflow: inherit;
}
.of-i {
  overflow: inherit;
}
.of-auto {
  overflow: auto;
}

.of-a {
  overflow: auto;
}
.of-scroll {
  overflow: scroll;
}
.of-s {
  overflow: scroll;
}
.of-hidden {
  overflow: hidden;
}
.of-h {
  overflow: hidden;
}

.of-n {
  overflow: none;
}
.of-none {
  overflow: none !important;
}

.of-visible {
  overflow: visible;
}
.of-v {
  overflow: visible;
}

/* =================-------| OVERFLOW Y |-------================= */
.of-y-inherit {
  overflow-y: inherit;
}

.of-y-auto {
  overflow-y: auto;
}

.of-y-scroll {
  overflow-y: scroll;
}

.of-y-hidden {
  overflow-y: hidden;
}
.of-y-h {
  overflow-y: hidden;
}
.of-y-n {
  overflow-y: none ;
}
.of-y-none {
  overflow-y: none;
}

/* Show overflow y scroll on hover */
.of-y-hover {
  overflow-y: hidden;
}

.of-y-hover:hover {
  overflow-y: scroll;
  cursor: pointer;
}

/*  Show overflow y auto on hover */
.of-y-auto-hover {
  overflow-y: hidden;
}

.of-y-auto-hover:hover {
  overflow-y: scroll;
}

/* =================-------| OVERFLOW X |-------================= */
.of-x-inherit {
  overflow-x: inherit;
}

.of-x-auto {
  overflow-x: auto;
}

.of-x-scroll {
  overflow-x: scroll;
}

.of-x-hidden {
  overflow-x: hidden;
}

.of-x-none {
  overflow-x: none;
}

/* Show overflow x scroll on hover */
.of-x-hover {
  overflow-x: hidden;
}

.of-x-hover:hover {
  overflow-x: scroll;
  cursor: pointer;
}

/*  Show overflow x auto on hover */
.of-x-auto-hover {
  overflow-x: hidden;
}

.of-x-auto-hover:hover {
  overflow-x: scroll;
}

/* =================-------| OVERFLOW TEXT |-------================= */
.t-of-e {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.t-of-c {
  text-overflow: clip;
  white-space: nowrap;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  /*
  ▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
  ██ ▄▄▄ ██ ███ ██ ▄▄▄██ ▄▄▀██ ▄▄▄██ █████ ▄▄▄ ██ ███ ██
  ██ ███ ███ █ ███ ▄▄▄██ ▀▀▄██ ▄▄███ █████ ███ ██ █ █ ██
  ██ ▀▀▀ ███▄▀▄███ ▀▀▀██ ██ ██ █████ ▀▀ ██ ▀▀▀ ██▄▀▄▀▄██
  ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  */
  /* Sintax and specials overflow */
  .m-of-inherit {
    overflow: inherit;
  }
  .m-of-auto {
    overflow: auto;
  }
  .m-of-scroll {
    overflow: scroll;
  }
  .m-of-hidden {
    overflow: hidden;
  }
  .m-of-none {
    overflow: none;
  }
  /* =================-------| OVERFLOW Y |-------================= */
  .m-of-y-inherit {
    overflow-y: inherit;
  }
  .m-of-y-auto {
    overflow-y: auto;
  }
  .m-of-y-scroll {
    overflow-y: scroll;
  }
  .m-of-y-hidden {
    overflow-y: hidden;
  }
  .m-of-y-none {
    overflow-y: none;
  }
  /* Show overflow y scroll on hover */
  .m-of-y-hover {
    overflow-y: hidden;
  }
  .m-of-y-hover:hover {
    overflow-y: scroll;
    cursor: pointer;
  }
  /*  Show overflow y auto on hover */
  .m-of-y-auto-hover {
    overflow-y: hidden;
  }
  .m-of-y-auto-hover:hover {
    overflow-y: scroll;
  }
  /* =================-------| OVERFLOW X |-------================= */
  .m-of-x-inherit {
    overflow-x: inherit;
  }
  .m-of-x-auto {
    overflow-x: auto;
  }
  .m-of-x-scroll {
    overflow-x: scroll;
  }
  .m-of-x-hidden {
    overflow-x: hidden;
  }
  .m-of-x-none {
    overflow-x: none;
  }
  /* Show overflow x scroll on hover */
  .m-of-x-hover {
    overflow-x: hidden;
  }
  .m-of-x-hover:hover {
    overflow-x: scroll;
    cursor: pointer;
  }
  /*  Show overflow x auto on hover */
  .m-of-x-auto-hover {
    overflow-x: hidden;
  }
  .m-of-x-auto-hover:hover {
    overflow-x: scroll;
  }
}

/*

██████   ██████  ███████ ██ ████████ ██  ██████  ███    ██
██   ██ ██    ██ ██      ██    ██    ██ ██    ██ ████   ██
██████  ██    ██ ███████ ██    ██    ██ ██    ██ ██ ██  ██
██      ██    ██      ██ ██    ██    ██ ██    ██ ██  ██ ██
██       ██████  ███████ ██    ██    ██  ██████  ██   ████

*/


.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.pf {
  position: fixed;
}

.ps {
  position: static;
}

.pst {
  position: -webkit-sticky;
  position: sticky;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  /*
  ▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
  ██ ▄▄ ██ ▄▄▄ ██ ▄▄▄ █▄ ▄█▄▄ ▄▄█▄ ▄██ ▄▄▄ ██ ▀██ ██
  ██ ▀▀ ██ ███ ██▄▄▄▀▀██ ████ ████ ███ ███ ██ █ █ ██
  ██ █████ ▀▀▀ ██ ▀▀▀ █▀ ▀███ ███▀ ▀██ ▀▀▀ ██ ██▄ ██
  ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  */

  .m-pr {
    position: relative;
  }
  .m-pa {
    position: absolute;
  }
  .m-pf {
    position: fixed;
  }
  .m-ps {
    position: static;
  }
  .m-pst {
    position: -webkit-sticky;
    position: sticky;
  }
}

/*

███████      ██ ███    ██ ██████  ███████ ██   ██
   ███       ██ ████   ██ ██   ██ ██       ██ ██
  ███  █████ ██ ██ ██  ██ ██   ██ █████     ███
 ███         ██ ██  ██ ██ ██   ██ ██       ██ ██
███████      ██ ██   ████ ██████  ███████ ██   ██

*/
.z-i-a {
  z-index: auto;
}

.z-i--5 {
  z-index: -5;
}

.z-i--4 {
  z-index: -4;
}

.z-i--3 {
  z-index: -3;
}

.z-i--2 {
  z-index: -2;
}

.z-i--1 {
  z-index: -1;
}

.z-i-0 {
  z-index: 0;
}

.z-i-1 {
  z-index: 1;
}

.z-i-2 {
  z-index: 2;
}

.z-i-3 {
  z-index: 3;
}

.z-i-4 {
  z-index: 4;
}

.z-i-5 {
  z-index: 5;
}

.z-i-99 {
  z-index: 99;
}

/*

██████  ██ ███████ ██████  ██       █████  ██    ██
██   ██ ██ ██      ██   ██ ██      ██   ██  ██  ██
██   ██ ██ ███████ ██████  ██      ███████   ████
██   ██ ██      ██ ██      ██      ██   ██    ██
██████  ██ ███████ ██      ███████ ██   ██    ██

*/
/* Sintax and specials display */
.d-inh {
  display: inherit;
}

.d-n {
  display: none ;
}

.d-h {
  display: hidden;
}
.d-i-flex{
  display: inline-flex ;
}
.d-v-h:hover {
  display: visible;
}

/* =================-------| DISPLAY BLOCK |-------================= */
.d-b {
  display: block;
}

.d-i-b {
  display: inline-block;
}

/* =================-------| DISPLAY GRID |-------================= */
.d-g {
  display: -ms-grid;
  display: grid;
}

/* =================-------| DISPLAY FLEX |-------================= */
.d-f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.d-i-f {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  clear: both;
}

/* =================-------| DISPLAY TABLE |-------================= */
.d-t {
  display: table;
}

.d-i-t {
  display: inline-table;
}

.d-t-c {
  display: table-cell;
}

.d-t-col {
  display: table-column;
}

/* Responsive */
@media only screen and (max-width: 768px) {
 
  /*
  ▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
  ██ ▄▄▀█▄ ▄██ ▄▄▄ ██ ▄▄ ██ ████ ▄▄▀██ ███ ██
  ██ ██ ██ ███▄▄▄▀▀██ ▀▀ ██ ████ ▀▀ ██▄▀▀▀▄██
  ██ ▀▀ █▀ ▀██ ▀▀▀ ██ █████ ▀▀ █ ██ ████ ████
  ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  */
  /* Sintax and specials mobile display */
  .m-d-important {
    display: inherit;
  }
  .m-d-inh {
    display: inherit;
  }
  .m-d-n {
    display: none !important;
  }
  /* =================-------| DISPLAY BLOCK |-------================= */
  .m-d-b {
    display: block !important;
  }
  .m-d-i-b {
    display: inline-block;
  }
  /* =================-------| DISPLAY GRID |-------================= */
  .m-d-g {
    display: -ms-grid;
    display: grid;
  }
  /* =================-------| DISPLAY FLEX |-------================= */
  .m-d-f {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .m-d-i-f {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  /* =================-------| FLEX - WRAP |-------================= */
.m-f-w-w {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.m-f-w-n-w {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.m-f-w-w-r {
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}

.m-f-w-inh {
  -ms-flex-wrap: inherit;
      flex-wrap: inherit;
}

/* =================-------| FLEX - DIRECTION |-------================= */
.m-f-d-c {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.m-f-d-c-r {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.m-f-d-r {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.m-f-d-r-r {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.m-f-d-inh {
  -webkit-box-orient: inherit;
  -webkit-box-direction: inherit;
      -ms-flex-direction: inherit;
          flex-direction: inherit;
}

.m-f-d-ini {
  -webkit-box-orient: initial;
  -webkit-box-direction: initial;
      -ms-flex-direction: initial;
          flex-direction: initial;
}
  /* =================-------| DISPLAY TABLE |-------================= */
  .m-d-t {
    display: table;
  }
  .m-d-i-t {
    display: inline-table;
  }
  .m-d-t-c {
    display: table-cell;
  }
  .m-d-t-col {
    display: table-column;
  }
}

/*

 ██████  ██████   █████   ██████ ██ ████████ ██    ██
██    ██ ██   ██ ██   ██ ██      ██    ██     ██  ██
██    ██ ██████  ███████ ██      ██    ██      ████
██    ██ ██      ██   ██ ██      ██    ██       ██
 ██████  ██      ██   ██  ██████ ██    ██       ██

*/
/* Sintax and specials opacity */
.o-inh {
  padding: inherit;
}

.o-none {
  opacity: 0;
}

/* =================-------| OPACITY % |-------================= */
.o-0 {
  opacity: 0%;
}

.o-5 {
  opacity: 5%;
}

.o-10 {
  opacity: 10%;
}

.o-15 {
  opacity: 15%;
}

.o-20 {
  opacity: 20%;
}

.o-25 {
  opacity: 25%;
}

.o-30 {
  opacity: 30%;
}

.o-35 {
  opacity: 35%;
}

.o-40 {
  opacity: 40%;
}

.o-45 {
  opacity: 45%;
}

.o-50 {
  opacity: 50% ;
}

.o-55 {
  opacity: 55%;
}

.o-60 {
  opacity: 60%;
}

.o-65 {
  opacity: 65%;
}

.o-70 {
  opacity: 70%;
}

.o-75 {
  opacity: 75%;
}

.o-80 {
  opacity: 80%;
}

.o-85 {
  opacity: 85%;
}

.o-90 {
  opacity: 90%;
}

.o-95 {
  opacity: 95%;
}

.o-100 {
  opacity: 100%;
}

/*

███████  ██████  ███    ██ ████████
██      ██    ██ ████   ██    ██
█████   ██    ██ ██ ██  ██    ██
██      ██    ██ ██  ██ ██    ██
██       ██████  ██   ████    ██

*/
/* Sintax and specials font */
.f-s-inh {
  font-size: inherit;
}

.f-w-inh {
  font-weight: inherit;
}

.f-f-inh {
  font-family: inherit;
}

/* =================-------| FONT STYLE |-------================= */
.f-s-n {
  font-style: normal;
}

.f-s-i {
  font-style: italic;
}

.f-s-o {
  font-style: oblique;
}

.f-s-ini {
  font-style: initial;
}

.f-s-inh {
  font-style: inherit;
}

/* =================-------| FONT WEIGHT |-------================= */
.f-w-l {
  font-weight: 300 ;
}
.f-w-n {
  font-weight: 500 ;
}

.f-w-m {
  font-weight: 600 ;
}

.f-w-bo {
  font-weight: 700 ;
}

.f-w-bl {
  font-weight: 900 ;
}
/* IMPORTANT */
.f-w-l-i {
  font-weight: 300 ;
}
.f-w-n-i {
  font-weight: 500 ;
}

.f-w-m-i {
  font-weight: 600 ;
}

.f-w-bo-i {
  font-weight: 700 ;
}

.f-w-bl-i {
  font-weight: 900 ;
}

/* =================-------| FONT FAMILY |-------================= */
.f-f-Gilroy {
  font-family: 'Gilroy', cursive;
}

.f-f-permanent-marker {
  font-family: 'Permanent Marker', cursive;
}

.f-f-faster-one {
  font-family: 'Faster One', cursive;
}

.f-f-kaushan-script {
  font-family: 'Kaushan Script', cursive;
}

.f-f-pacifico {
  font-family: 'Pacifico', cursive;
}

.f-f-orbitron {
  font-family: 'Orbitron';
}

.f-f-gochi-hand {
  font-family: 'Gochi Hand', cursive;
}

.f-f-poppins {
  font-family: 'Poppins';
}

.f-f-1 {
  font-family: 'Gilroy', cursive;
}
.f-f-2 {
  font-family: 'Poppins', cursive;
}
.f-f-3 {
  font-family: 'Faster One', cursive;
}

.f-f-4 {
  font-family: 'Kaushan Script', cursive;
}

.f-f-5 {
  font-family: 'Pacifico', cursive;
}

.f-f-6 {
  font-family: 'Orbitron';
}

.f-f-7 {
  font-family: 'Gochi Hand', cursive;
}

.f-f-8 {
  font-family: 'Permanent Marker' cursive;
}

/* =================-------| FONT SIZE PX , EM |-------================= */
.f-s-5px {
  font-size: 5px;
}

.f-s-6px {
  font-size: 6px;
}

.f-s-7px {
  font-size: 7px;
}

.f-s-8px {
  font-size: 8px;
}

.f-s-9px {
  font-size: 9px;
}

.f-s-10px {
  font-size: 10px;
}

.f-s-11px {
  font-size: 11px;
}

.f-s-12px {
  font-size: 12px;
}

.f-s-13px {
  font-size: 13px;
}

.f-s-14px {
  font-size: 14px;
}

.f-s-15px {
  font-size: 15px;
}

.f-s-16px {
  font-size: 16px;
}

.f-s-17px {
  font-size: 17px;
}

.f-s-18px {
  font-size: 18px;
}

.f-s-19px {
  font-size: 19px;
}

.f-s-20px {
  font-size: 20px;
}

.f-s-21px {
  font-size: 21px;
}

.f-s-22px {
  font-size: 22px;
}

.f-s-23px {
  font-size: 23px;
}

.f-s-24px {
  font-size: 24px;
}

.f-s-25px {
  font-size: 25px;
}

.f-s-30px {
  font-size: 30px;
}

.f-s-35px {
  font-size: 35px;
}

.f-s-40px {
  font-size: 40px;
}

.f-s-45px {
  font-size: 45px;
}

.f-s-50px {
  font-size: 50px;
}

.f-s-0-5em {
  font-size: 0.5em;
}

.f-s-0-6em {
  font-size: 0.6em;
}

.f-s-0-7em {
  font-size: 0.7em;
}

.f-s-0-8em {
  font-size: 0.8em;
}

.f-s-0-9em {
  font-size: 0.9em;
}

.f-s-1em {
  font-size: 1em;
}

.f-s-1-1em {
  font-size: 1.1em;
}

.f-s-1-2em {
  font-size: 1.2em;
}

.f-s-1-3em {
  font-size: 1.3em;
}

.f-s-1-3em {
  font-size: 1.4em;
}

.f-s-1-5em {
  font-size: 1.5em;
}

.f-s-1-6em {
  font-size: 1.6em;
}

.f-s-1-7em {
  font-size: 1.7em;
}

.f-s-1-8em {
  font-size: 1.8em;
}

.f-s-1-9em {
  font-size: 1.9em;
}

.f-s-2em {
  font-size: 2em;
}

.f-s-2-5em {
  font-size: 2.5em;
}

.f-s-3em {
  font-size: 3em;
}

.f-s-3-5em {
  font-size: 3.5em;
}

.f-s-4em {
  font-size: 4em;
}

.f-s-4-5em {
  font-size: 4.5em;
}

.f-s-5em {
  font-size: 5em;
}

.f-s-5-5em {
  font-size: 5.5em;
}

.f-s-6em {
  font-size: 6em;
}

/* =================-------| NODE FONT SIZE |-------================= */
.ns-f-s-title {
  font-size: 25px;
  line-height: 30px;
}
  .ns-f-s-subtitle {
  font-size: 20em;
  line-height: 25px;
}
  .ns-f-s-text {
  font-size: 15px;
  line-height: 15px;
}
  .ns-f-s-nano {
  font-size: 8px;
  line-height: 10px;
}
  .ns-f-s-small {
  font-size: 10px;
  line-height: 10px;
}
  .ns-f-s-normal {
  font-size: 15px;
  line-height: 20px;
}
  .ns-f-s-medium {
  font-size: 25px;
  line-height: 30px;
}
  .ns-f-s-big {
  font-size: 70px;
  line-height: 75px;
}
  .ns-f-s-giant {
  font-size: 110px;
  line-height: 115px;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .m-ns-f-s-title {
  
    font-size: 25px;
    line-height: 30px;
  }
  .m-ns-f-s-subtitle {
   
    font-size: 20em;
    line-height: 25px;
  }
  .m-ns-f-s-text {
   
    font-size: 15px;
    line-height: 20px;
  }
  .m-ns-f-s-nano {
   
    font-size: 10px;
    line-height: 15px;
  }
  .m-ns-f-s-small {
   
    font-size: 8px;
    line-height: 10px;
  }
  .m-ns-f-s-normal {
  
    font-size: 15px;
    line-height: 20px;
  }
  .m-ns-f-s-medium {
 
    font-size: 25px;
    line-height: 30px;
  }
  .m-ns-f-s-big {
   
    font-size: 50px;
    line-height: 50px;
  }
  .m-ns-f-s-giant {
   
    font-size: 110px;
    line-height: 115px;
  }
}

/*

██      ██ ███    ██ ███████     ██   ██
██      ██ ████   ██ ██          ██   ██
██      ██ ██ ██  ██ █████ █████ ███████
██      ██ ██  ██ ██ ██          ██   ██
███████ ██ ██   ████ ███████     ██   ██

*/
/* =================-------| LINE HEIGHT PX , EM |-------================= */
.l-h-1px {
  line-height: 1px;
}

.l-h-2px {
  line-height: 2px;
}

.l-h-3px {
  line-height: 3px;
}

.l-h-4px {
  line-height: 4px;
}

.l-h-5px {
  line-height: 5px;
}
.l-h-6px {
  line-height: 6px;
}
.l-h-7px {
  line-height: 7px;
}
.l-h-8px {
  line-height: 8px;
}
.l-h-9px {
  line-height: 9px;
}
.l-h-10px {
  line-height: 10px;
}
.l-h-11px {
  line-height: 11px;
}
.l-h-12px {
  line-height: 12px;
}
.l-h-13px {
  line-height: 13px;
}
.l-h-14px {
  line-height: 14px;
}
.l-h-15px {
  line-height: 15px;
}

.l-h-20px {
  line-height: 20px;
}

.l-h-25px {
  line-height: 25px;
}

.l-h-30px {
  line-height: 30px;
}

.l-h-35px {
  line-height: 35px;
}

.l-h-40px {
  line-height: 40px;
}

.l-h-45px {
  line-height: 45px;
}

.l-h-50px {
  line-height: 50px;
}

.l-h-55px {
  line-height: 55px;
}

.l-h-60px {
  line-height: 60px;
}

.l-h-65px {
  line-height: 65px;
}

.l-h-70px {
  line-height: 70px;
}

.l-h-75px {
  line-height: 75px;
}

.l-h-80px {
  line-height: 80px;
}

.l-h-85px {
  line-height: 85px;
}

.l-h-90px {
  line-height: 90px;
}

.l-h-95px {
  line-height: 95px;
}

.l-h-100px {
  line-height: 100px;
}

.l-h-0-1em {
  line-height: 0.1em;
}

.l-h-0-2em {
  line-height: 0.2em;
}

.l-h-0-3em {
  line-height: 0.3em;
}

.l-h-0-4em {
  line-height: 0.4em;
}

.l-h-0-5em {
  line-height: 0.5em;
}

.l-h-0-6em {
  line-height: 0.6em;
}

.l-h-0-7em {
  line-height: 0.7em;
}

.l-h-0-8em {
  line-height: 0.8em;
}

.l-h-0-9em {
  line-height: 0.9em;
}

.l-h-1em {
  line-height: 1em;
}

.l-h-2em {
  line-height: 2em;
}

.l-h-3em {
  line-height: 3em;
}

.l-h-4em {
  line-height: 4em;
}

.l-h-5em {
  line-height: 5em;
}

.l-h-6em {
  line-height: 6em;
}

.l-h-7em {
  line-height: 7em;
}

.l-h-8em {
  line-height: 8em;
}

.l-h-9em {
  line-height: 9em;
}

.l-h-10em {
  line-height: 10em;
}

/*

 ██████ ██    ██ ██████  ███████  ██████  ██████
██      ██    ██ ██   ██ ██      ██    ██ ██   ██
██      ██    ██ ██████  ███████ ██    ██ ██████
██      ██    ██ ██   ██      ██ ██    ██ ██   ██
 ██████  ██████  ██   ██ ███████  ██████  ██   ██

*/
.c-a {
  cursor: auto;
}

.c-p {
  cursor: pointer;
}

.c-w {
  cursor: wait;
}

.c-ch {
  cursor: crosshair;
}

.c-g {
  cursor: -webkit-grab;
  cursor: grab;
}

.c-gb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.c-h {
  cursor: help;
}

.c-z-i {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.c-z-o {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

/*

 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██

*/
/* Sintax and specials color */
.c-none {
  color: none;
}

.c-inh {
  color: inherit;
}

/* =================-------| PURE COLOR |-------================= */
.c-white {
  color: white;
}

.c-yellow {
  color: yellow;
}

.c-blue {
  color: blue;
}

.c-skyblue {
  color: skyblue;
}

.c-red {
  color: red;
}

.c-orange {
  color: orange;
}

.c-green {
  color: green;
}

.c-fuchsia {
  color: fuchsia;
}

/* =================-------| NS GLOBAL COLOR |-------================= */
.ns-c-white {
  color: #fefefe;
}

.ns-c-black {
  color: #121212;
}

.ns-c-yellow {
  color: #fccb18;
}

.ns-c-blue {
  color: #005dcf;
}

.ns-c-skyblue {
  color: #0096ff;
}

.ns-c-red {
  color: #ed293e;
}

.ns-c-pink {
  color: #fd528e;
}

.ns-c-orange {
  color: #ff9500;;
}

.ns-c-dark-orange {
  color: #f43a09;
}

.ns-c-light-pink {
  color: #fbe3e8;
}

.ns-c-light-green {
  color: #00e645;
}

.ns-c-fuchsia {
  color: #ff0096;
}

.ns-c-violet {
  color: #8232f2;
}

.ns-c-gold {
  color: #faa03f;
}

.ns-c-olive {
  color: #ffb766;
}

.ns-c-pink-1 {
  color: #ff3676;
}

.ns-c-green {
  color: #27ae60;
}
.ns-c-darkgreen {
 color: #00994d;
}
.ns-c-gray-1 {
  color: #FFFFFF;
}

.ns-c-gray-2 {
  color: #eeeeee;
}

.ns-c-gray-3 {
  color: #dedede;
}

.ns-c-gray-4 {
  color: #c2c2c2;
}

.ns-c-gray-5 {
  color: #7e7e7e;
}

.ns-c-gray-6 {
  color: #666666;
}

.ns-c-gray-7 {
  color: #4a4a4a;
}

.ns-c-gray-8 {
  color: #202020;
}

.ns-c-gray-9 {
  color: #181818;
}

.ns-c-gray-10 {
  color: #121212;
}

/* =================-------| NS COLOR |-------================= */
.ns-c-1 {
  color: #7e7e7e;
}

.ns-c-e-c {
  color: white;
}

.ns-c-e {
  background: linear-gradient(135deg, #ffc666 10%, #efc275 50%, #cf9e4b 70%, #f8d18e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ns-c-e-animated {

  background-image: -webkit-linear-gradient(315deg, #a8864a 23%, #fcc15b 36%, #fae3bc 45%, #ffecc8 50%, #ffc666 65%, #423620 100%);
  
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  animation: tech-text-animation 5s linear infinite;
}

@keyframes tech-text-animation {
  0% {
    background-position: 0% center;
  }
  50% {
    background-position: 100% center;
  }
  100% {
    background-position: 0% center;
  }
}

/*color: #e1a54b;*/
/*

██████   █████   ██████ ██   ██  ██████  ██████   ██████  ██    ██ ███    ██ ██████
██   ██ ██   ██ ██      ██  ██  ██       ██   ██ ██    ██ ██    ██ ████   ██ ██   ██
██████  ███████ ██      █████   ██   ███ ██████  ██    ██ ██    ██ ██ ██  ██ ██   ██
██   ██ ██   ██ ██      ██  ██  ██    ██ ██   ██ ██    ██ ██    ██ ██  ██ ██ ██   ██
██████  ██   ██  ██████ ██   ██  ██████  ██   ██  ██████   ██████  ██   ████ ██████

*/

.ns-bg-tone-1{
  filter: brightness(140%);
}
/* Gradients */


.bg-g-1{
  background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,91,255,0.9976584383753502) 49%, rgba(0,0,0,0) 100%);
}

.bg-g-2{
background: rgb(0,0,0);
background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 100%);
}

/* Sintax and specials background */
.bg-inh {
  background: inherit;
}

.bg-none {
  background: none;
}

/* =================-------| BACKGROUND SIZE  |-------================= */
.bg-s-a {
  background-size: auto;
}

.bg-s-cov {
  background-size: cover;
}

.bg-s-con {
  background-size: contain;
}

.bg-s-ini {
  background-size: initial;
}

.bg-s-inh {
  background-size: inherit;
}

/* =================-------| BACKGROUND POSITION |-------================= */
.bg-p-c {
  background-position: center;
  background-origin: unset;
}

.bg-p-b {
  background-position: bottom;
}

.bg-p-l {
  background-position: left;
}

.bg-p-r {
  background-position: right;
}

.bg-p-t {
  background-position: top;
}

/* =================-------| BACKGROUND PURE COLOR |-------================= */
.bg-black {
  background-color: black;
}
.bg-white {
  background-color: white;
}

.bg-yellow {
  background-color: yellow;
}

.bg-blue {
  background-color: blue;
}

.bg-skyblue {
  background-color: skyblue;
}

.bg-red {
  background-color: red;
}

.bg-orange {
  background-color: orange;
}

.bg-green {
  background-color: green;
}

.bg-fuchsia {
  background-color: fuchsia;
}

/* =================-------| BACKGROUND GLOBAL COLOR  |-------================= */
.ns-bg-white {
  background-color: #fefefe;
}

.ns-bg-black {
  background-color: #121212;
}

.ns-bg-yellow {
  background-color: #fccb18;
}
.ns-bg-darkblue {
  background-color: #00377a;
}
.ns-bg-blue {
  background-color: #005dcf;
}

.ns-bg-skyblue {
  background-color: #0096ff;
}

.ns-bg-red {
  background-color: #ed293e;
}

.ns-bg-pink {
  background-color: #fd528e;
}

.ns-bg-orange {
  background-color: #ff9500;
}

.ns-bg-dark-orange {
  background-color: #f43a09;
}

.ns-bg-light-pink {
  background-color: #fbe3e8;
}

.ns-bg-light-green {
  background-color: #00e645;
}

.ns-bg-fuchsia {
  background-color: #ff0096;
}

.ns-bg-violet {
  background-color: #8232f2;
}

.ns-bg-gold {
  background-color: #faa03f;
}

.ns-bg-olive {
  background-color: #ffb766;
}

.ns-bg-pink-1 {
  background-color: #ff3676;
}

.ns-bg-green {
  background-color: #02e473;
}
.ns-bg-darkgreen {
background-color: #2ca03f;
  
}
.ns-bg-gray-1 {
  background-color: #FFFFFF;
}

.ns-bg-gray-2 {
  background-color: #eeeeee;
}

.ns-bg-gray-3 {
  background-color: #dedede;
}

.ns-bg-gray-4 {
  background-color: #c2c2c2;
}

.ns-bg-gray-5 {
  background-color: #7e7e7e;
}

.ns-bg-gray-6 {
  background-color: #666666;
}

.ns-bg-gray-7 {
  background-color: #4a4a4a;
}

.ns-bg-gray-8 {
  background-color: #202020;
}

.ns-bg-gray-9 {
  background-color: #181818;
}

.ns-bg-gray-10 {
  background-color: #121212;
}

/* =================-------| NS BACKGROUND COLOR  |-------================= */
.ns-bg-1 {
  background-color: #fefefe; /*#fefefe*/
}

.ns-bg-2 {
  background-color: #dedede; /*#dedede*/
}

.ns-bg-3 {
  background-color: #c9c9c9; /*#c9c9c9*/
}

.ns-bg-e {

  background: linear-gradient(135deg, #ffc666 10%, #ad8540 50%, #f8d18e 100%);
  
}
.ns-bg-e-hover:hover {

  background: linear-gradient(135deg, #ffc666 10%, #9f7b3d 50%, #f8d18e 100%);
  color: white;
}
.ns-bg-e-animated {

  background-image: -webkit-linear-gradient(315deg, #a8864a 23%, #fcc15b 36%, #fae3bc 45%, #ffecc8 50%, #ffc666 65%, #423620 100%);
  background-size: 200% auto;
  animation: tech-text-animation 5s linear infinite;
}
.ns-bg-e-r{
  background: rgb(0,0,0);
background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,198,102,1) 100%);
}

@keyframes tech-bg-animation {
  0% {
    background-position: 0% center;
  }
  50% {
    background-position: 100% center;
  }
  100% {
    background-position: 0% center;
  }
}
/* =================-------| BACKGROUND BLACK NODE  |-------================= */
.bg-black-node-o-10 {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-node-o-20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-black-node-o-30 {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-black-node-o-40 {
  background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-node-o-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-black-node-o-60 {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-node-o-70 {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-black-node-o-80 {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-black-node-o-90 {
  background-color: rgba(0, 0, 0, 0.9);
}

/* =================-------| BACKGROUND WHITE NODE  |-------================= */
.bg-white-node-o-10 {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-node-o-20 {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-node-o-30 {
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-white-node-o-40 {
  background-color: rgba(255, 255, 255, 0.4);
}

.bg-white-node-o-50 {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-node-o-60 {
  background-color: rgba(255, 255, 255, 0.6);
}

.bg-white-node-o-70 {
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-white-node-o-80 {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-white-node-o-90 {
  background-color: rgba(255, 255, 255, 0.9);
}

/* =================-------| BACKGROUND SOCIAL |-------================= */
.bg-facebook {
  background: #3b5998;
}

.bg-twitter {
  background: #00ACEE;
}

.bg-instagram {
  background: #DD2A7B;
}

.bg-gitlab {
  background: #fc6d26;
}

.bg-patreon {
  background: #FF424D;
}

.bg-youtube {
  background: #c4302b;
}

.bg-github {
  background: #24292e;
}

.bg-linkedin {
  background: #283e4a;
}

/* =================-------| PURE FILL |-------================= */
.fill-white {
  fill: white;
}

.fill-yellow {
  fill: yellow;
}

.fill-blue {
  fill: blue;
}

.fill-skyblue {
  fill: skyblue;
}

.fill-red {
  fill: red;
}

.fill-orange {
  fill: orange;
}

.fill-green {
  fill: green;
}

.fill-fuchsia {
  fill: fuchsia;
}

/* =================-------| NS GLOBAL FILL |-------================= */
.ns-fill-white {
  fill: #fefefe;
}

.ns-fill-black {
  fill: #121212;
}

.ns-fill-yellow {
  fill: #fccb18;
}

.ns-fill-blue {
  fill: #005dcf;
}

.ns-fill-skyblue {
  fill: #0096ff;
}

.ns-fill-red {
  fill: #ed293e;
}

.ns-fill-pink {
  fill: #fd528e;
}

.ns-fill-orange {
  fill: #ff9500;;
}

.ns-fill-dark-orange {
  fill: #f43a09;
}

.ns-fill-light-pink {
  fill: #fbe3e8;
}

.ns-fill-light-green {
  fill: #00e645;
}

.ns-fill-fuchsia {
  fill: #ff0096;
}

.ns-fill-violet {
  fill: #8232f2;
}

.ns-fill-gold {
  fill: #faa03f;
}

.ns-fill-olive {
  fill: #ffb766;
}

.ns-fill-pink-1 {
  fill: #ff3676;
}

.ns-fill-green {
  fill: #02e473;
}

.ns-fill-gray-1 {
  fill: #FFFFFF;
}

.ns-fill-gray-2 {
  fill: #eeeeee;
}

.ns-fill-gray-3 {
  fill: #dedede;
}

.ns-fill-gray-4 {
  fill: #c2c2c2;
}

.ns-fill-gray-5 {
  fill: #7e7e7e;
}

.ns-fill-gray-6 {
  fill: #666666;
}

.ns-fill-gray-7 {
  fill: #4a4a4a;
}

.ns-fill-gray-8 {
  fill: #202020;
}

.ns-fill-gray-9 {
  fill: #181818;
}

.ns-fill-gray-10 {
  fill: #121212;
}

/*

██████   ██████  ██████  ██████  ███████ ██████
██   ██ ██    ██ ██   ██ ██   ██ ██      ██   ██
██████  ██    ██ ██████  ██   ██ █████   ██████
██   ██ ██    ██ ██   ██ ██   ██ ██      ██   ██
██████   ██████  ██   ██ ██████  ███████ ██   ██

*/
/* =================-------| BORDER |-------================= */
.br-none {
  border: none;
}

.br-c-none {
  border-color: none;
}

.br-t-none {
  border-top: none;
}

.br-t-c-none {
  border-top-color: none;
}

.br-r-none {
  border-right: none;
}

.br-r-c-none {
  border-right-color: none;
}

.br-b-none {
  border-bottom: none;
}

.br-b-c-none {
  border-bottom-color: none;
}

.br-l-none {
  border-left: none;
}

.br-l-c-none {
  border-left-color: none;
}

/* =================-------| BORDER - HALF |-------================= */
.br-left {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.br-right {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.br-top {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.br-bottom {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* =================-------| BORDER STYLE |-------================= */
.br-s-solid {
  border-style: solid;
}

.br-t-s-solid {
  border-top-style: solid;
}

.br-r-s-solid {
  border-right-style: solid;
}

.br-b-s-solid {
  border-bottom-style: solid;
}

.br-l-s-solid {
  border-left-style: solid;
}

.br-s-dashed {
  border-style: dashed;
}

.br-t-s-dashed {
  border-top-style: dashed;
}

.br-r-s-dashed {
  border-right-style: dashed;
}

.br-b-s-dashed {
  border-bottom-style: dashed;
}

.br-l-s-dashed {
  border-left-style: dashed;
}

.br-s-dotted {
  border-style: dotted;
}

.br-t-s-dotted {
  border-top-style: dotted;
}

.br-r-s-dotted {
  border-right-style: dotted;
}

.br-b-s-dotted {
  border-bottom-style: dotted;
}

.br-l-s-dotted {
  border-left-style: dotted;
}

.br-s-double {
  border-style: double;
}

.br-t-s-double {
  border-top-style: double;
}

.br-r-s-double {
  border-right-style: double;
}

.br-b-s-double {
  border-bottom-style: double;
}

.br-l-s-double {
  border-left-style: double;
}

.br-s-groove {
  border-style: groove;
}

.br-t-s-groove {
  border-top-style: groove;
}

.br-r-s-groove {
  border-right-style: groove;
}

.br-b-s-groove {
  border-bottom-style: groove;
}

.br-l-s-groove {
  border-left-style: groove;
}

.br-s-inset {
  border-style: inset;
}

.br-t-s-inset {
  border-top-style: inset;
}

.br-r-s-inset {
  border-right-style: inset;
}

.br-b-s-inset {
  border-bottom-style: inset;
}

.br-l-s-inset {
  border-left-style: inset;
}

.br-s-outset {
  border-style: outset;
}

.br-t-s-outset {
  border-top-style: outset;
}

.br-r-s-outset {
  border-right-style: outset;
}

.br-b-s-outset {
  border-bottom-style: outset;
}

.br-l-s-outset {
  border-left-style: outset;
}

.br-s-none {
  border-style: none;
}

.br-t-s-none {
  border-top-style: none;
}

.br-r-s-none {
  border-right-style: none;
}

.br-b-s-none {
  border-bottom-style: none;
}

.br-l-s-none {
  border-left-style: none;
}

.br-s-hidden {
  border-style: hidden;
}

.br-t-s-hidden {
  border-top-style: hidden;
}

.br-r-s-hidden {
  border-right-style: hidden;
}

.br-b-s-hidden {
  border-bottom-style: hidden;
}

.br-l-s-hidden {
  border-left-style: hidden;
}

.br-s-mix {
  border-style: dotted dashed solid double;
}

.br-t-s-mix {
  border-top-style: dotted dashed solid double;
}

.br-r-s-mix {
  border-right-style: dotted dashed solid double;
}

.br-b-s-mix {
  border-bottom-style: dotted dashed solid double;
}

.br-l-s-mix {
  border-left-style: dotted dashed solid double;
}

/* =================-------| BORDER COLORS PURE |-------================= */
.br-c-white {
  border-color: white;
}

.br-t-c-white {
  border-top-color: white;
}

.br-r-c-white {
  border-right-color: white;
}

.br-b-c-white {
  border-bottom-color: white;
}

.br-l-c-white {
  border-left-color: white;
}

.br-c-yellow {
  border-color: yellow;
}

.br-t-c-yellow {
  border-top-color: yellow;
}

.br-r-c-yellow {
  border-right-color: yellow;
}

.br-b-c-yellow {
  border-bottom-color: yellow;
}

.br-l-c-yellow {
  border-left-color: yellow;
}

.br-c-blue {
  border-color: blue;
}

.br-t-c-blue {
  border-top-color: blue;
}

.br-r-c-blue {
  border-right-color: blue;
}

.br-b-c-blue {
  border-bottom-color: blue;
}

.br-l-c-blue {
  border-left-color: blue;
}

.br-c-skyblue {
  border-color: skyblue;
}

.br-t-c-skyblue {
  border-top-color: skyblue;
}

.br-r-c-skyblue {
  border-right-color: skyblue;
}

.br-b-c-skyblue {
  border-bottom-color: skyblue;
}

.br-l-c-skyblue {
  border-left-color: skyblue;
}

.br-c-red {
  border-color: red;
}

.br-t-c-red {
  border-top-color: red;
}

.br-r-c-red {
  border-right-color: red;
}

.br-b-c-red {
  border-bottom-color: red;
}

.br-l-c-red {
  border-left-color: red;
}

.br-c-orange {
  border-color: orange;
}

.br-t-c-orange {
  border-top-color: orange;
}

.br-r-c-orange {
  border-right-color: orange;
}

.br-b-c-orange {
  border-bottom-color: orange;
}

.br-l-c-orange {
  border-left-color: orange;
}

.br-c-green {
  border-color: green;
}

.br-t-c-green {
  border-top-color: green;
}

.br-r-c-green {
  border-right-color: green;
}

.br-b-c-green {
  border-bottom-color: green;
}

.br-l-c-green {
  border-left-color: green;
}

.br-c-fuchsia {
  border-color: fuchsia;
}

.br-t-c-fuchsia {
  border-top-color: fuchsia;
}

.br-r-c-fuchsia {
  border-right-color: fuchsia;
}

.br-b-c-fuchsia {
  border-bottom-color: fuchsia;
}

.br-l-c-fuchsia {
  border-left-color: fuchsia;
}

/* =================-------| BORDER COLORS NODE |-------================= */
/* emphasis */
.ns-br-c-e {
  border-color: var(--current-color-emphasis);
}

.ns-br-t-c-e {
  border-top-color: var(--current-color-emphasis);
}

.ns-br-r-c-e {
  border-right-color: var(--current-color-emphasis);
}

.ns-br-b-c-e {
  border-bottom-color: var(--current-color-emphasis);
}

.ns-br-l-c-e {
  border-left-color: var(--current-color-emphasis);
}







/*------------------------------ */
.ns-br-c-white {
  border-color: #fefefe;
}

.ns-br-t-c-white {
  border-top-color: #fefefe;
}

.ns-br-r-c-white {
  border-right-color: #fefefe;
}

.ns-br-b-c-white {
  border-bottom-color: #fefefe;
}

.ns-br-l-c-white {
  border-left-color: #fefefe;
}

.ns-br-c-black {
  border-color: #121212;
}

.ns-br-t-c-black {
  border-top-color: #121212;
}

.ns-br-r-c-black {
  border-right-color: #121212;
}

.ns-br-b-c-black {
  border-bottom-color: #121212;
}

.ns-br-l-c-black {
  border-left-color: #121212;
}

.ns-br-c-yellow {
  border-color: #fccb18;
}

.ns-br-t-c-yellow {
  border-top-color: #fccb18;
}

.ns-br-r-c-yellow {
  border-right-color: #fccb18;
}

.ns-br-b-c-yellow {
  border-bottom-color: #fccb18;
}

.ns-br-l-c-yellow {
  border-left-color: #fccb18;
}

.ns-br-c-blue {
  border-color: #005dcf;
}

.ns-br-t-c-blue {
  border-top-color: #005dcf;
}

.ns-br-r-c-blue {
  border-right-color: #005dcf;
}

.ns-br-b-c-blue {
  border-bottom-color: #005dcf;
}

.ns-br-l-c-blue {
  border-left-color: #005dcf;
}

.ns-br-c-skyblue {
  border-color: #0096ff;
}

.ns-br-t-c-skyblue {
  border-top-color: #0096ff;
}

.ns-br-r-c-skyblue {
  border-right-color: #0096ff;
}

.ns-br-b-c-skyblue {
  border-bottom-color: #0096ff;
}

.ns-br-l-c-skyblue {
  border-left-color: #0096ff;
}

.ns-br-c-red {
  border-color: #ed293e;
}

.ns-br-t-c-red {
  border-top-color: #ed293e;
}

.ns-br-r-c-red {
  border-right-color: #ed293e;
}

.ns-br-b-c-red {
  border-bottom-color: #ed293e;
}

.ns-br-l-c-red {
  border-left-color: #ed293e;
}

.ns-br-c-pink {
  border-color: #fd528e;
}

.ns-br-t-c-pink {
  border-top-color: #fd528e;
}

.ns-br-r-c-pink {
  border-right-color: #fd528e;
}

.ns-br-b-c-pink {
  border-bottom-color: #fd528e;
}

.ns-br-l-c-pink {
  border-left-color: #fd528e;
}

.ns-br-c-orange {
  border-color: #ff9500;;
}

.ns-br-t-c-orange {
  border-top-color: #ff9500;;
}

.ns-br-r-c-orange {
  border-right-color: #ff9500;;
}

.ns-br-b-c-orange {
  border-bottom-color: #ff9500;;
}

.ns-br-l-c-orange {
  border-left-color: #ff9500;;
}

.ns-br-c-dark-orange {
  border-color: #f43a09;
}

.ns-br-t-c-dark-orange {
  border-top-color: #f43a09;
}

.ns-br-r-c-dark-orange {
  border-right-color: #f43a09;
}

.ns-br-b-c-dark-orange {
  border-bottom-color: #f43a09;
}

.ns-br-l-c-dark-orange {
  border-left-color: #f43a09;
}

.ns-br-c-light-pink {
  border-color: #fbe3e8;
}

.ns-br-t-c-light-pink {
  border-top-color: #fbe3e8;
}

.ns-br-r-c-light-pink {
  border-right-color: #fbe3e8;
}

.ns-br-b-c-light-pink {
  border-bottom-color: #fbe3e8;
}

.ns-br-l-c-light-pink {
  border-left-color: #fbe3e8;
}

.ns-br-c-light-green {
  border-color: #00e645;
}

.ns-br-t-c-light-green {
  border-top-color: #00e645;
}

.ns-br-r-c-light-green {
  border-right-color: #00e645;
}

.ns-br-b-c-light-green {
  border-bottom-color: #00e645;
}

.ns-br-l-c-light-green {
  border-left-color: #00e645;
}

.ns-br-c-fuchsia {
  border-color: #ff0096;
}

.ns-br-t-c-fuchsia {
  border-top-color: #ff0096;
}

.ns-br-r-c-fuchsia {
  border-right-color: #ff0096;
}

.ns-br-b-c-fuchsia {
  border-bottom-color: #ff0096;
}

.ns-br-l-c-fuchsia {
  border-left-color: #ff0096;
}

.ns-br-c-violet {
  border-color: #8232f2;
}

.ns-br-t-c-violet {
  border-top-color: #8232f2;
}

.ns-br-r-c-violet {
  border-right-color: #8232f2;
}

.ns-br-b-c-violet {
  border-bottom-color: #8232f2;
}

.ns-br-l-c-violet {
  border-left-color: #8232f2;
}

.ns-br-c-gold {
  border-color: #faa03f;
}

.ns-br-t-c-gold {
  border-top-color: #faa03f;
}

.ns-br-r-c-gold {
  border-right-color: #faa03f;
}

.ns-br-b-c-gold {
  border-bottom-color: #faa03f;
}

.ns-br-l-c-gold {
  border-left-color: #faa03f;
}

.ns-br-c-olive {
  border-color: #ffb766;
}

.ns-br-t-c-olive {
  border-top-color: #ffb766;
}

.ns-br-r-c-olive {
  border-right-color: #ffb766;
}

.ns-br-b-c-olive {
  border-bottom-color: #ffb766;
}

.ns-br-l-c-olive {
  border-left-color: #ffb766;
}

.ns-br-c-pink-1 {
  border-color: #ff3676;
}

.ns-br-t-c-pink-1 {
  border-top-color: #ff3676;
}

.ns-br-r-c-pink-1 {
  border-right-color: #ff3676;
}

.ns-br-b-c-pink-1 {
  border-bottom-color: #ff3676;
}

.ns-br-l-c-pink-1 {
  border-left-color: #ff3676;
}

.ns-br-c-green {
  border-color: #02e473;
}

.ns-br-t-c-green {
  border-top-color: #02e473;
}

.ns-br-r-c-green {
  border-right-color: #02e473;
}

.ns-br-b-c-green {
  border-bottom-color: #02e473;
}

.ns-br-l-c-green {
  border-left-color: #02e473;
}

.ns-br-c-gray-1 {
  border-color: #FFFFFF;
}

.ns-br-t-c-gray-1 {
  border-top-color: #FFFFFF;
}

.ns-br-r-c-gray-1 {
  border-right-color: #FFFFFF;
}

.ns-br-b-c-gray-1 {
  border-bottom-color: #FFFFFF;
}

.ns-br-l-c-gray-1 {
  border-left-color: #FFFFFF;
}

.ns-br-c-gray-2 {
  border-color: #eeeeee;
}

.ns-br-t-c-gray-2 {
  border-top-color: #eeeeee;
}

.ns-br-r-c-gray-2 {
  border-right-color: #eeeeee;
}

.ns-br-b-c-gray-2 {
  border-bottom-color: #eeeeee;
}

.ns-br-l-c-gray-2 {
  border-left-color: #eeeeee;
}

.ns-br-c-gray-3 {
  border-color: #dedede;
}

.ns-br-t-c-gray-3 {
  border-top-color: #dedede;
}

.ns-br-r-c-gray-3 {
  border-right-color: #dedede;
}

.ns-br-b-c-gray-3 {
  border-bottom-color: #dedede;
}

.ns-br-l-c-gray-3 {
  border-left-color: #dedede;
}

.ns-br-c-gray-4 {
  border-color: #c2c2c2;
}

.ns-br-t-c-gray-4 {
  border-top-color: #c2c2c2;
}

.ns-br-r-c-gray-4 {
  border-right-color: #c2c2c2;
}

.ns-br-b-c-gray-4 {
  border-bottom-color: #c2c2c2;
}

.ns-br-l-c-gray-4 {
  border-left-color: #c2c2c2;
}

.ns-br-c-gray-5 {
  border-color: #7e7e7e;
}

.ns-br-t-c-gray-5 {
  border-top-color: #7e7e7e;
}

.ns-br-r-c-gray-5 {
  border-right-color: #7e7e7e;
}

.ns-br-b-c-gray-5 {
  border-bottom-color: #7e7e7e;
}

.ns-br-l-c-gray-5 {
  border-left-color: #7e7e7e;
}

.ns-br-c-gray-6 {
  border-color: #666666;
}

.ns-br-t-c-gray-6 {
  border-top-color: #666666;
}

.ns-br-r-c-gray-6 {
  border-right-color: #666666;
}

.ns-br-b-c-gray-6 {
  border-bottom-color: #666666;
}

.ns-br-l-c-gray-6 {
  border-left-color: #666666;
}

.ns-br-c-gray-7 {
  border-color: #4a4a4a;
}

.ns-br-t-c-gray-7 {
  border-top-color: #4a4a4a;
}

.ns-br-r-c-gray-7 {
  border-right-color: #4a4a4a;
}

.ns-br-b-c-gray-7 {
  border-bottom-color: #4a4a4a;
}

.ns-br-l-c-gray-7 {
  border-left-color: #4a4a4a;
}

.ns-br-c-gray-8 {
  border-color: #202020;
}

.ns-br-t-c-gray-8 {
  border-top-color: #202020;
}

.ns-br-r-c-gray-8 {
  border-right-color: #202020;
}

.ns-br-b-c-gray-8 {
  border-bottom-color: #202020;
}

.ns-br-l-c-gray-8 {
  border-left-color: #202020;
}

.ns-br-c-gray-9 {
  border-color: #181818;
}

.ns-br-t-c-gray-9 {
  border-top-color: #181818;
}

.ns-br-r-c-gray-9 {
  border-right-color: #181818;
}

.ns-br-b-c-gray-9 {
  border-bottom-color: #181818;
}

.ns-br-l-c-gray-9 {
  border-left-color: #181818;
}

.ns-br-c-gray-10 {
  border-color: #121212;
}

.ns-br-t-c-gray-10 {
  border-top-color: #121212;
}

.ns-br-r-c-gray-10 {
  border-right-color: #121212;
}

.ns-br-b-c-gray-10 {
  border-bottom-color: #121212;
}

.ns-br-l-c-gray-10 {
  border-left-color: #121212;
}

/* =================-------| BORDER WIDTH |-------================= */
.br-w-0px {
  border-width: 0px;
}

.br-w-1px {
  border-width: 1px;
}

.br-w-2px {
  border-width: 2px;
}

.br-w-3px {
  border-width: 3px;
}

.br-w-4px {
  border-width: 4px;
}

.br-w-5px {
  border-width: 5px;
}

.br-w-10px {
  border-width: 10px;
}

.br-w-15px {
  border-width: 15px;
}

.br-w-20px {
  border-width: 20px;
}

.br-w-25px {
  border-width: 25px;
}

.br-w-30px {
  border-width: 30px;
}

.br-w-35px {
  border-width: 35px;
}

.br-w-40px {
  border-width: 40px;
}

.br-w-45px {
  border-width: 45px;
}

.br-w-50px {
  border-width: 50px;
}

.br-t-w-0px {
  border-top-width: 0px;
}

.br-t-w-1px {
  border-top-width: 1px;
}

.br-t-w-2px {
  border-top-width: 2px;
}

.br-t-w-3px {
  border-top-width: 3px;
}

.br-t-w-4px {
  border-top-width: 4px;
}

.br-t-w-5px {
  border-top-width: 5px;
}

.br-t-w-10px {
  border-top-width: 10px;
}

.br-t-w-15px {
  border-top-width: 15px;
}

.br-t-w-20px {
  border-top-width: 20px;
}

.br-t-w-25px {
  border-top-width: 25px;
}

.br-t-w-30px {
  border-top-width: 30px;
}

.br-t-w-35px {
  border-top-width: 35px;
}

.br-t-w-40px {
  border-top-width: 40px;
}

.br-t-w-45px {
  border-top-width: 45px;
}

.br-t-w-50px {
  border-top-width: 50px;
}

.br-r-w-0px {
  border-right-width: 0px;
}

.br-r-w-1px {
  border-right-width: 1px;
}

.br-r-w-2px {
  border-right-width: 2px;
}

.br-r-w-3px {
  border-right-width: 3px;
}

.br-r-w-4px {
  border-right-width: 4px;
}

.br-r-w-5px {
  border-right-width: 5px;
}

.br-r-w-10px {
  border-right-width: 10px;
}

.br-r-w-15px {
  border-right-width: 15px;
}

.br-r-w-20px {
  border-right-width: 20px;
}

.br-r-w-25px {
  border-right-width: 25px;
}

.br-r-w-30px {
  border-right-width: 30px;
}

.br-r-w-35px {
  border-right-width: 35px;
}

.br-r-w-40px {
  border-right-width: 40px;
}

.br-r-w-45px {
  border-right-width: 45px;
}

.br-r-w-50px {
  border-right-width: 50px;
}

.br-b-w-0px {
  border-bottom-width: 0px;
}

.br-b-w-1px {
  border-bottom-width: 1px;
}

.br-b-w-2px {
  border-bottom-width: 2px;
}

.br-b-w-3px {
  border-bottom-width: 3px;
}

.br-b-w-4px {
  border-bottom-width: 4px;
}

.br-b-w-5px {
  border-bottom-width: 5px;
}

.br-b-w-10px {
  border-bottom-width: 10px;
}

.br-b-w-15px {
  border-bottom-width: 15px;
}

.br-b-w-20px {
  border-bottom-width: 20px;
}

.br-b-w-25px {
  border-bottom-width: 25px;
}

.br-b-w-30px {
  border-bottom-width: 30px;
}

.br-b-w-35px {
  border-bottom-width: 35px;
}

.br-b-w-40px {
  border-bottom-width: 40px;
}

.br-b-w-45px {
  border-bottom-width: 45px;
}

.br-b-w-50px {
  border-bottom-width: 50px;
}

.br-l-w-0px {
  border-left-width: 0px;
}

.br-l-w-1px {
  border-left-width: 1px;
}

.br-l-w-2px {
  border-left-width: 2px;
}

.br-l-w-3px {
  border-left-width: 3px;
}

.br-l-w-4px {
  border-left-width: 4px;
}

.br-l-w-5px {
  border-left-width: 5px;
}

.br-l-w-10px {
  border-left-width: 10px;
}

.br-l-w-15px {
  border-left-width: 15px;
}

.br-l-w-20px {
  border-left-width: 20px;
}

.br-l-w-25px {
  border-left-width: 25px;
}

.br-l-w-30px {
  border-left-width: 30px;
}

.br-l-w-35px {
  border-left-width: 35px;
}

.br-l-w-40px {
  border-left-width: 40px;
}

.br-l-w-45px {
  border-left-width: 45px;
}

.br-l-w-50px {
  border-left-width: 50px;
}

/* =================-------| BORDER RADIUS |-------================= */
.br-r-0px {
  border-radius: 0px;
}

.br-r-1px {
  border-radius: 1px;
}

.br-r-2px {
  border-radius: 2px;
}

.br-r-3px {
  border-radius: 3px;
}

.br-r-4px {
  border-radius: 4px;
}

.br-r-5px {
  border-radius: 5px;
}

.br-r-10px {
  border-radius: 10px;
}

.br-r-15px {
  border-radius: 15px;
}

.br-r-20px {
  border-radius: 20px;
}

.br-r-25px {
  border-radius: 25px;
}

.br-r-30px {
  border-radius: 30px;
}

.br-r-35px {
  border-radius: 35px;
}

.br-r-40px {
  border-radius: 40px;
}

.br-r-45px {
  border-radius: 45px;
}

.br-r-50px {
  border-radius: 50px;
}

.br-r-t-l-0px {
  border-top-left-radius: 0px;
}

.br-r-t-l-1px {
  border-top-left-radius: 1px;
}

.br-r-t-l-2px {
  border-top-left-radius: 2px;
}

.br-r-t-l-3px {
  border-top-left-radius: 3px;
}

.br-r-t-l-4px {
  border-top-left-radius: 4px;
}

.br-r-t-l-5px {
  border-top-left-radius: 5px;
}

.br-r-t-l-10px {
  border-top-left-radius: 10px;
}

.br-r-t-l-15px {
  border-top-left-radius: 15px;
}

.br-r-t-l-20px {
  border-top-left-radius: 20px;
}

.br-r-t-l-25px {
  border-top-left-radius: 25px;
}

.br-r-t-l-30px {
  border-top-left-radius: 30px;
}

.br-r-t-l-35px {
  border-top-left-radius: 35px;
}

.br-r-t-l-40px {
  border-top-left-radius: 40px;
}

.br-r-t-l-45px {
  border-top-left-radius: 45px;
}

.br-r-t-l-50px {
  border-top-left-radius: 50px;
}

.br-r-t-r-0px {
  border-top-right-radius: 0px;
}

.br-r-t-r-1px {
  border-top-right-radius: 1px;
}

.br-r-t-r-2px {
  border-top-right-radius: 2px;
}

.br-r-t-r-3px {
  border-top-right-radius: 3px;
}

.br-r-t-r-4px {
  border-top-right-radius: 4px;
}

.br-r-t-r-5px {
  border-top-right-radius: 5px;
}

.br-r-t-r-10px {
  border-top-right-radius: 10px;
}

.br-r-t-r-15px {
  border-top-right-radius: 15px;
}

.br-r-t-r-20px {
  border-top-right-radius: 20px;
}

.br-r-t-r-25px {
  border-top-right-radius: 25px;
}

.br-r-t-r-30px {
  border-top-right-radius: 30px;
}

.br-r-t-r-35px {
  border-top-right-radius: 35px;
}

.br-r-t-r-40px {
  border-top-right-radius: 40px;
}

.br-r-t-r-45px {
  border-top-right-radius: 45px;
}

.br-r-t-r-50px {
  border-top-right-radius: 50px;
}

.br-r-b-l-0px {
  border-bottom-left-radius: 0px;
}

.br-r-b-l-1px {
  border-bottom-left-radius: 1px;
}

.br-r-b-l-2px {
  border-bottom-left-radius: 2px;
}

.br-r-b-l-3px {
  border-bottom-left-radius: 3px;
}

.br-r-b-l-4px {
  border-bottom-left-radius: 4px;
}

.br-r-b-l-5px {
  border-bottom-left-radius: 5px;
}

.br-r-b-l-10px {
  border-bottom-left-radius: 10px;
}

.br-r-b-l-15px {
  border-bottom-left-radius: 15px;
}

.br-r-b-l-20px {
  border-bottom-left-radius: 20px;
}

.br-r-b-l-25px {
  border-bottom-left-radius: 25px;
}

.br-r-b-l-30px {
  border-bottom-left-radius: 30px;
}

.br-r-b-l-35px {
  border-bottom-left-radius: 35px;
}

.br-r-b-l-40px {
  border-bottom-left-radius: 40px;
}

.br-r-b-l-45px {
  border-bottom-left-radius: 45px;
}

.br-r-b-l-50px {
  border-bottom-left-radius: 50px;
}

.br-r-b-r-0px {
  border-bottom-right-radius: 0px;
}

.br-r-b-r-1px {
  border-bottom-right-radius: 1px;
}

.br-r-b-r-2px {
  border-bottom-right-radius: 2px;
}

.br-r-b-r-3px {
  border-bottom-right-radius: 3px;
}

.br-r-b-r-4px {
  border-bottom-right-radius: 4px;
}

.br-r-b-r-5px {
  border-bottom-right-radius: 5px;
}

.br-r-b-r-10px {
  border-bottom-right-radius: 10px;
}

.br-r-b-r-15px {
  border-bottom-right-radius: 15px;
}

.br-r-b-r-20px {
  border-bottom-right-radius: 20px;
}

.br-r-b-r-25px {
  border-bottom-right-radius: 25px;
}

.br-r-b-r-30px {
  border-bottom-right-radius: 30px;
}

.br-r-b-r-35px {
  border-bottom-right-radius: 35px;
}

.br-r-b-r-40px {
  border-bottom-right-radius: 40px;
}

.br-r-b-r-45px {
  border-bottom-right-radius: 45px;
}

.br-r-b-r-50px {
  border-bottom-right-radius: 50px;
}

/* =================-------| NODE BORDER RADIUS |-------================= */
.ns-br-r-1 {
  border-radius: 5px;
}

.ns-br-r-t-l-1 {
  border-top-left-radius: 5px;
}

.ns-br-r-t-r-1 {
  border-top-right-radius: 5px;
}

.ns-br-r-b-l-1 {
  border-bottom-left-radius: 5px;
}

.ns-br-r-b-r-1 {
  border-bottom-right-radius: 5px;
}

.ns-br-r-2 {
  border-radius: 10px;
}

.ns-br-r-t-l-2 {
  border-top-left-radius: 10px;
}

.ns-br-r-t-r-2 {
  border-top-right-radius: 10px;
}

.ns-br-r-b-l-2 {
  border-bottom-left-radius: 10px;
}

.ns-br-r-b-r-2 {
  border-bottom-right-radius: 10px;
}

.ns-br-r-3 {
  border-radius: 15px;
}

.ns-br-r-t-l-3 {
  border-top-left-radius: 15px;
}

.ns-br-r-t-r-3 {
  border-top-right-radius: 15px;
}

.ns-br-r-b-l-3 {
  border-bottom-left-radius: 15px;
}

.ns-br-r-b-r-3 {
  border-bottom-right-radius: 15px;
}

.ns-br-r-4 {
  border-radius: 20px;
}

.ns-br-r-t-l-4 {
  border-top-left-radius: 20px;
}

.ns-br-r-t-r-4 {
  border-top-right-radius: 20px;
}

.ns-br-r-b-l-4 {
  border-bottom-left-radius: 20px;
}

.ns-br-r-b-r-4 {
  border-bottom-right-radius: 20px;
}

.ns-br-r-5 {
  border-radius: 50%;
}

.ns-br-r-t-l-5 {
  border-top-left-radius: 50%;
}

.ns-br-r-t-r-5 {
  border-top-right-radius: 50%;
}

.ns-br-r-b-l-5 {
  border-bottom-left-radius: 50%;
}

.ns-br-r-b-r-5 {
  border-bottom-right-radius: 50%;
}

/*

██████   ██████  ██   ██
██   ██ ██    ██  ██ ██
██████  ██    ██   ███
██   ██ ██    ██  ██ ██
██████   ██████  ██   ██

*/
/* =================-------| BOX - SIZING |-------================= */
.b-s-b-b {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.b-s-c-b {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

/* =================-------| BOX - SHADOW |-------================= */
.b-s-i {
  -webkit-box-shadow: inset;
          box-shadow: inset;
}

.b-s-n {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.b-s-1 {
  -webkit-box-shadow: -11px 11px 2px -4px rgba(48, 48, 48, 0.18);
  box-shadow: -11px 11px 2px -4px rgba(48, 48, 48, 0.18);
}

.b-s-2 {
  -webkit-box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.33);
  box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.33);
}

.b-s-1-h:hover {
  -webkit-box-shadow: -11px 11px 2px -4px rgba(48, 48, 48, 0.18);
  box-shadow: -11px 11px 2px -4px rgba(48, 48, 48, 0.18);
}

/* =================-------| NODE BOX SHADOW |-------================= */
.ns-b-s {
  -webkit-box-shadow: 0 0.6px 1.8px -17px rgba(0, 0, 0, 0.317), 0 2px 6px -17px rgba(0, 0, 0, 0.456), 0 9px 27px -17px rgba(0, 0, 0, 0.75);
          box-shadow: 0 0.6px 1.8px -17px rgba(0, 0, 0, 0.317), 0 2px 6px -17px rgba(0, 0, 0, 0.456), 0 9px 27px -17px rgba(0, 0, 0, 0.75);
}

.shadow-1 {
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
}
